/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import useApi from "./useApi";
import { toCamelCase } from "@/utils/dataUtils";
import { DocumentStatus } from "@/types";
interface ApiResponse {
  data: any;
  status: number;
}

interface Result {
  success: boolean;
  data?: any;
  message?: string;
  error?: any;
  status?: number;
}

/**
 * @description Hook for handling vector operations
 * @returns {object} - The vector operations
 */
const useVector = () => {
  const { postRequest, handleError } = useApi();

  /**
   * @description Uploads documents to VDB
   * @param {string} project_id - The id of the project to upload documents to
   * @param {array} document_ids - The ids of the documents to upload
   * @param {boolean} skip_flag - Whether to skip
   * @returns Result object with success status and data or error message.
   */
  const uploadToVDB = async (
    project_id: string,
    document_ids: string[],
    skip_flag: boolean,
    rerank: boolean,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest("post_upload_documents_to_vdb", {
        project_id: project_id,
        documents_ids: document_ids,
        skip_flag: skip_flag,
        rerank: rerank,
      });

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB");
    }
  };

  const uploadToVDBOnly = async (document_ids: string[]): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_upload_documents_to_vdb_only",
        {
          documents_ids: document_ids,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB only");
    }
  };

  const processReferences = async (
    project_id: string,
    document_ids: string[],
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest("post_process_references", {
        project_id: project_id,
        documents_ids: document_ids,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error processing references");
    }
  };

  const rerankReferences = async (
    project_id: string,
    documents_ids?: string[],
    is_portfolio?: boolean,
  ): Promise<Result> => {
    try {
      const payload: {
        project_id: string;
        documents_ids?: string[];
        is_portfolio?: boolean;
      } = {
        project_id: project_id,
      };
      if (documents_ids) {
        payload.documents_ids = documents_ids;
      }
      if (is_portfolio) {
        payload.is_portfolio = is_portfolio;
      }
      const response: ApiResponse = await postRequest(
        "post_rerank_invalidities",
        payload,
      );

      return { success: true, status: response.status };
    } catch (error) {
      return handleError(error, "Error reranking references");
    }
  };

  /**
   * @description Uploads documents to VDB search chat
   * @param {array} document_ids - The ids of the documents to upload
   * @returns Result object with success status and data or error message.
   */
  const uploadToVDBSearchChat = async (document_ids: string[]): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_upload_documents_to_vdb_search_chat",
        {
          documents_ids: document_ids,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB search chat");
    }
  };

  /**
   * @description Retrieves the invalidity for a claim
   * @param {string} projectId - The id of the project to retrieve the invalidity for
   * @param {string} claim_number - The claim number to retrieve the invalidity for
   * @param {string} referenceId - The reference id to retrieve the invalidity for
   * @param {string} claim_language - The language of the element
   * @param {boolean} is_feature - Whether the element is a feature
   * @param {array[string]} positive_examples- The positive invalidities to retrieve
   * @param {array[string]} negative_examples - The negative invalidities to retrieve
   * @returns Result object with success status and data or error message.
   */
  const retrieveInvalidityForClaim = async (
    projectId: string,
    claim_number: string,
    referenceId: string,
    claim_language: string,
    is_feature: boolean,
    positive_examples: string[],
    negative_examples: string[],
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_retrieve_invalidity_for_claim",
        {
          project_id: projectId,
          claim_number: claim_number,
          reference_id: referenceId,
          claim_language: claim_language,
          is_feature: is_feature,
          positive: positive_examples,
          negative: negative_examples,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error retrieving invalidity for claim");
    }
  };

  /**
   * @description Retrieves the invalidity for a claim
   * @param {string} projectId - The id of the project to retrieve the invalidity for
   * @param {string} claim_number - The claim number to retrieve the invalidity for
   * @param {string} referenceId - The reference id to retrieve the invalidity for
   * @param {string} claim_language - The language of the element
   * @param {boolean} is_feature - Whether the element is a feature
   * @returns Result object with success status and data or error message.
   */
  const retrieveInvalidityForClaimForAllReferences = async (
    projectId: string,
    claim_number: string,
    claim_language: string,
    is_feature: boolean,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_retrieve_invalidity_for_claim_all_references",
        {
          project_id: projectId,
          claim_number: claim_number,
          claim_language: claim_language,
          is_feature: is_feature,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error retrieving invalidity for claim");
    }
  };

  /**
   * @description Performs a semantic search for documents
   * @param {string} projectId - The id of the project to perform the search for
   * @param {string} searchQuery - The query to search for
   * @param {array} documentsIds - The ids of the documents to search in
   * @param {string} searchMode - The mode of the search (semantic or keyword)
   * @returns Result object with success status and data or error message.
   */
  const semanticSearchDocuments = async (
    projectId: string,
    searchQuery: string,
    documentsIds: string[],
    searchMode: string,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_semantic_search_documents",
        {
          project_id: projectId,
          text: searchQuery,
          documents_ids: documentsIds,
          results: 25,
          search_mode: searchMode,
        },
      );
      return {
        success: true,
        data: toCamelCase(response.data, true),
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching semantic search results");
    }
  };

  return {
    uploadToVDB,
    semanticSearchDocuments,
    retrieveInvalidityForClaim,
    uploadToVDBSearchChat,
    retrieveInvalidityForClaimForAllReferences,
    rerankReferences,
    uploadToVDBOnly,
    processReferences,
  };
};

export default useVector;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useDataTable, useProject } from "@/hooks";
import { MultiDocumentSelect } from "@/components";
import { ElementType, ChartColor } from "@/types";
import ElementSelect from "@/features/project/charts/components/ElementSelect";
import ColorSelect from "@/features/project/charts/components/ColorSelect";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { nanoid } from "nanoid";

interface ExportChartsModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Exports charts to a Microsoft Word or Excel file
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ExportChartsModal: React.FC<ExportChartsModalProps> = ({ open, handleClose }) => {
  const { getElementChartExport } = useDataTable();
  const { updateProjectDetails } = useProject();

  // Global state from store
  const { currentProjectId, currentProject, updateCurrentProject } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } =
    useAppStateStore();

  // Local state
  const [wordChecked, setWordChecked] = useState(false);
  const [excelChecked, setExcelChecked] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [includeSummaryCharts, setIncludeSummaryCharts] = useState(false);
  const [includeClaims, setIncludeClaims] = useState(false);
  const [includeFeatures, setIncludeFeatures] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [introductionLanguage, setIntroductionLanguage] = useState("");
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [referenceIds, setReferenceIds] = useState([]);

  const handleClaimSelect = (selectedClaims: string[]) => {
    setSelectedClaims(selectedClaims);
  };

  const handleFeatureSelect = (selectedFeatures: string[]) => {
    setSelectedFeatures(selectedFeatures);
  };

  useEffect(() => {
    setWordChecked(false);
    setExcelChecked(false);
    setIntroductionLanguage(currentProject.introductionLanguage || "");
    setIncludeSummaryCharts(false);
    setIncludeClaims(false);
    setIncludeFeatures(false);
    setAlertVisible(false);
    setAlertMessage("");
  }, [open]);

  /**
   * @description Exports charts to a Microsoft Word file
   * @param {boolean} includeFeatures - whether to include feature charts
   * @param {boolean} includeClaims - whether to include claim charts
   * @param {boolean} includeSummary - whether to include summary charts
   */
  const exportProject = async (
    finalColors: string[],
    finalFeatures: string[],
    finalClaims: string[],
    exportType: string,
  ) => {
    const response = await getElementChartExport(
      currentProjectId,
      referenceIds,
      includeFeatures,
      includeClaims,
      includeSummaryCharts,
      introductionLanguage,
      finalColors,
      finalFeatures,
      finalClaims,
      exportType,
    );
    if (!response.success) {
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
    if (!response.data) {
      if (process.env.NODE_ENV !== "production") {
        console.error("No download URL found");
      }
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
  };

  // Handles the download button click and prepares payload
  const handleDownloadClick = async () => {
    // Validate user input on required selections
    const fileFormatSelected = wordChecked || excelChecked;
    const chartOptionsSelected =
      includeSummaryCharts || includeClaims || includeFeatures;
    if (!fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertVisible(true);
      return;
    } else if (!fileFormatSelected && chartOptionsSelected) {
      setAlertMessage("Please select at least one file format.");
      setAlertVisible(true);
      return;
    }
    if (fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one chart option.");
      setAlertVisible(true);
      return;
    }
    setAlertVisible(false);
    const key = nanoid();
    addLoadingMessage(`Downloading charts for ${currentProject.name}...`, key);
    handleClose();

    const finalColors =
      selectedColors.length === 0 || selectedColors.length === 3 ? [] : selectedColors;
    selectedColors.length === 0 || selectedColors.length === 3 ? [] : selectedColors;

    let finalFeatures = [];
    let finalClaims = [];

    if (
      includeFeatures &&
      selectedFeatures.length > 0 &&
      selectedFeatures.length !== currentProject.features.length
    ) {
      finalFeatures = selectedFeatures;
    }
    if (
      includeClaims &&
      selectedClaims.length > 0 &&
      selectedClaims.length !== currentProject.claims.length
    ) {
      finalClaims = selectedClaims;
    }

    await updateProjectDetails(currentProjectId, {
      introduction_language: introductionLanguage,
    });
    updateCurrentProject({
      ...currentProject,
      introductionLanguage: introductionLanguage,
    });

    let exportType = "";

    if (wordChecked && excelChecked) {
      exportType = "both";
    } else if (wordChecked) {
      exportType = "word";
    } else if (excelChecked) {
      exportType = "excel";
    }

    await exportProject(finalColors, finalFeatures, finalClaims, exportType);
    removeLoadingMessage(key);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Download charts</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-2  max-h-[80vh] overflow-y-auto">
          <div className="space-y-2">
            <h4 className="font-medium pb-1">Chart Options</h4>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="summary"
                  checked={includeSummaryCharts}
                  onCheckedChange={(checked) =>
                    setIncludeSummaryCharts(checked as boolean)
                  }
                  label="Summary Chart"
                />
              </div>
              {currentProject.claims.length > 0 && (
                <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="claims"
                    checked={includeClaims}
                    onCheckedChange={(checked) => setIncludeClaims(checked as boolean)}
                    label="Claim Charts"
                  />
                </div>
              )}
              {currentProject.features.length > 0 && (
                <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="features"
                    checked={includeFeatures}
                    onCheckedChange={(checked) =>
                      setIncludeFeatures(checked as boolean)
                    }
                    label="Feature Charts"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            <div className="max-w-[500px]">
              <MultiDocumentSelect handleChange={setReferenceIds} general={true} />
            </div>
            <ColorSelect
              colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
              selectedColors={selectedColors}
              setSelectedColors={setSelectedColors}
            />
            {includeClaims && currentProject.claims.length > 0 && (
              <ElementSelect
                onElementSelect={handleClaimSelect}
                elementType={ElementType.CLAIM}
              />
            )}
            {includeFeatures && currentProject.features.length > 0 && (
              <ElementSelect
                onElementSelect={handleFeatureSelect}
                elementType={ElementType.FEATURE}
              />
            )}
          </div>

          <div className="space-y-2">
            <h4 className="font-medium pb-1">File format</h4>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="word"
                  checked={wordChecked}
                  onCheckedChange={(checked) => setWordChecked(checked as boolean)}
                  label="Microsoft Word (.docx)"
                />
              </div>
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="excel"
                  checked={excelChecked}
                  onCheckedChange={(checked) => setExcelChecked(checked as boolean)}
                  label="Microsoft Excel (.xlsx)"
                />
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="introduction">Introduction Language</Label>
            <AutosizeTextarea
              id="introduction"
              value={introductionLanguage}
              onChange={(e) => setIntroductionLanguage(e.target.value)}
              maxHeight={300}
            />
          </div>
        </div>

        {alertVisible && (
          <Alert variant="destructive">
            <AlertDescription>{alertMessage}</AlertDescription>
          </Alert>
        )}

        <DialogFooter>
          <div className="flex flex-row justify-between gap-3">
            <div className="flex items-center gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleDownloadClick}
                disabled={
                  (!wordChecked && !excelChecked) ||
                  (!includeSummaryCharts && !includeClaims && !includeFeatures)
                }
              >
                Download
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExportChartsModal;

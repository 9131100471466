import React from "react";
import styled from "styled-components";

interface EvaluationData {
  Claim: { [key: string]: string };
  "Claim Language": { [key: string]: string };
  "Model References": { [key: string]: number[] };
  "PDF References": { [key: string]: number[] };
  "Missing References": { [key: string]: number[] };
  "Incorrect References": { [key: string]: number[] };
  Accuracy: { [key: string]: number };
  Precision: { [key: string]: number };
  Recall: { [key: string]: number };
}

interface EvaluationResultsProps {
  data: EvaluationData | null;
  onClose: () => void;
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  float: right;
`;

const EvaluationResults: React.FC<EvaluationResultsProps> = ({ data, onClose }) => {
  if (!data) return null;

  const keys = Object.keys(data.Claim);

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <Th>Claim</Th>
            <Th>Claim Language</Th>
            <Th>Model References</Th>
            <Th>PDF References</Th>
            <Th>Missing References</Th>
            <Th>Incorrect References</Th>
            <Th>Accuracy</Th>
            <Th>Precision</Th>
            <Th>Recall</Th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => (
            <tr key={key}>
              <Td>{data.Claim[key]}</Td>
              <Td>{data["Claim Language"][key]}</Td>
              <Td>{data["Model References"][key].join(", ")}</Td>
              <Td>{data["PDF References"][key].join(", ")}</Td>
              <Td>{data["Missing References"][key].join(", ")}</Td>
              <Td>{data["Incorrect References"][key].join(", ")}</Td>
              <Td>{data.Accuracy[key].toFixed(2)}</Td>
              <Td>{data.Precision[key].toFixed(2)}</Td>
              <Td>{data.Recall[key].toFixed(2)}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CloseButton onClick={onClose} style={{ float: "left" }}>
        Close
      </CloseButton>
    </div>
  );
};

export default EvaluationResults;

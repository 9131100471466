/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Avatar } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";
import { Message } from "@/types";
import ReactMarkdown from "react-markdown";
import CopyToClipboardIconButton from "@/components/buttons/CopyToClipboardIconButton";

interface UserChatMessageProps {
  message: Message;
  index: number;
  isAIResponding: boolean;
  isMostRecent: boolean;
}

/**
 * Component for user chat message.
 *
 * @param {UserChatMessageProps} props - The props for the component.
 */
export default function UserChatMessage({
  message,
  index,
  isAIResponding,
  isMostRecent,
}: UserChatMessageProps) {
  const renderMarkdownWithIcon = (message: Message) => (
    <>
      <ReactMarkdown>{message.text}</ReactMarkdown>
      {message.loading && <Skeleton className="h-5 w-5 rounded-full" />}
    </>
  );

  const isUser = message.sender === "user";

  return (
    <div
      key={index}
      className={`mt-4 flex ${isUser ? "justify-end" : "justify-start"}`}
    >
      <div
        className={`flex items-start gap-4 max-w-[80%] ${
          isUser ? "flex-row-reverse" : ""
        }`}
      >
        {!isUser && (
          <Avatar className="h-8 w-8 bg-blue-600 flex items-center justify-center">
            <span className="text-xl font-semibold text-white">&</span>
          </Avatar>
        )}
        <div className={`flex-1 ${isUser ? "bg-accent rounded-full py-2 px-3" : ""}`}>
          {message.loading ? (
            <Skeleton className="h-5 w-5 rounded-full" />
          ) : (
            <div className="prose max-w-none break-words">
              {renderMarkdownWithIcon(message)}
            </div>
          )}
          {!isUser && ((!isAIResponding && isMostRecent) || !isMostRecent) && (
            <CopyToClipboardIconButton text={message.text} index={index} />
          )}
        </div>
      </div>
    </div>
  );
}

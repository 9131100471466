/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore } from "@/store";
import { splitId } from "@/utils/projectUtils";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";

interface MultiDocumentSelectProps {
  handleChange: (selected: string[]) => void;
  general?: boolean;
  disabled?: boolean;
}

const MultiDocumentSelect: React.FC<MultiDocumentSelectProps> = ({
  handleChange,
  general,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const currentProject = useProjectStore((state) => state.currentProject);
  const subjectId = currentProject.subjectId;
  const referenceIds = currentProject.referenceIds || [];
  const documentsToNumbers = currentProject.documentsToNumbers || {};
  const documentsToNicknames = currentProject.documentsToNicknames || {};

  const projectIdArray = general ? referenceIds : [subjectId, ...referenceIds];

  const areAllReferencesSelected = () => {
    const selectedReferences = selectedDocumentIds.filter((id) => id !== subjectId);
    return selectedReferences.length === referenceIds.length;
  };

  useEffect(() => {
    setSelectedDocumentIds(projectIdArray);
  }, [currentProject, general]);

  useEffect(() => {
    handleChange(selectedDocumentIds);
  }, [selectedDocumentIds, handleChange]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDocumentToggle = (id: string) => {
    setSelectedDocumentIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const handleSelectAll = () => {
    setSelectedDocumentIds(
      selectedDocumentIds.length === projectIdArray.length ? [] : projectIdArray,
    );
  };

  const getDisplayText = (id: string) => {
    const number = documentsToNumbers[id] ? splitId(documentsToNumbers[id]) : "";
    const nickname = documentsToNicknames[id] ? ` - ${documentsToNicknames[id]}` : "";
    return `${number}${nickname}`;
  };

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between w-full"
          disabled={disabled}
        >
          <div className="flex items-center space-x-1 truncate">
            {selectedDocumentIds.length === projectIdArray.length ? (
              <span>{general ? "All references" : "All project documents"}</span>
            ) : areAllReferencesSelected() ? (
              <span>All project references</span>
            ) : selectedDocumentIds.length > 0 ? (
              <>
                {selectedDocumentIds.length > 2 && (
                  <span className="text-secondary-foreground">
                    +{selectedDocumentIds.length - 2} more
                  </span>
                )}
              </>
            ) : (
              <span>Select Documents</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 min-w-[350px]" align="start">
        <ScrollArea className="h-[300px]">
          <div className="p-1">
            <Button
              variant="ghost"
              className="w-full justify-start"
              onClick={handleSelectAll}
            >
              <div className="flex items-center">
                <CheckIcon
                  className={`mr-2 h-4 w-4 ${
                    selectedDocumentIds.length === projectIdArray.length
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                />
                <span>Select All</span>
              </div>
            </Button>
            {projectIdArray.map((id) => (
              <Button
                key={id}
                variant="ghost"
                className="w-full justify-start"
                onClick={() => handleDocumentToggle(id)}
              >
                <div className="flex items-center">
                  <CheckIcon
                    className={`mr-2 h-4 w-4 ${
                      selectedDocumentIds.includes(id) ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  <span>{getDisplayText(id)}</span>
                  {id === subjectId && (
                    <span className="ml-2 text-xs border text-secondary-foreground px-1 rounded">
                      Subject
                    </span>
                  )}
                </div>
              </Button>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

export default MultiDocumentSelect;

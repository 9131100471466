/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useProjectStore, useProcessStore } from "../store";
import { useVector, useProject, useViz } from ".";
import { ParentType, StatusType, ProcessType } from "@/types";
import { nanoid } from "nanoid";
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProcessReferences = () => {
  const { currentProjectId, currentParent, currentPortfolioId } = useProjectStore();
  const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
    useAppStateStore();
  const { addProcess, removeProcess } = useProcessStore();
  const { uploadToVDB, uploadToVDBOnly, rerankReferences, processReferences } =
    useVector();
  const { addReferencesToProject, fetchProjectData, addPDFToProject } = useProject();
  const { getPortfolioMetadata, addToUserFiles, addReferencesToPortfolio } = useViz();

  const processType = ProcessType.ADD_REFERENCE;

  /**
   * Chunks an array into smaller arrays, helper function for processReferences
   * @param {string[]} array - The array to chunk
   * @param {number} chunkSize - The size of the chunk to process
   * @returns An array of chunks
   */
  async function chunkArray(array: string[], chunkSize: number): Promise<string[][]> {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const addAndProcessReferences = async (
    projectId: string,
    projectName: string,
    refNums: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    portfolioId?: string,
  ) => {
    // Limit the number of references to process
    refNums = refNums.slice(0, 100);
    const key = nanoid();

    if (displayLoadingMessages) {
      addProcess({
        id: key,
        type: processType,
        projectId: projectId,
      });
      addLoadingGroup(projectId, projectName, processType);
      refNums.forEach((number) => {
        addLoadingGroupItem(projectId, processType, number, StatusType.PROCESSING);
      });
    }

    const chunkSize = 10;
    const chunks = await chunkArray(refNums, chunkSize);

    let successfulNumbersToReturn: string[] = [];
    let unsuccessfulNumbersToReturn: string[] = [];

    await processReferencesInProjects(
      projectId,
      projectName,
      chunks,
      isCheckboxChecked,
      displayLoadingMessages,
      successfulNumbersToReturn,
      unsuccessfulNumbersToReturn,
    );

    // Update project or portfolio data as needed
    await refetchDataOnCompletion(projectId, portfolioId);

    if (displayLoadingMessages) {
      removeProcess(key);
    }

    return {
      data: {
        successfulNumbers: successfulNumbersToReturn,
        unsuccessfulNumbers: unsuccessfulNumbersToReturn,
      },
    };
  };

  // Function to process references in other projects
  const processReferencesInProjects = async (
    projectId: string,
    projectName: string,
    chunks: string[][],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean,
    successfulNumbersToReturn: string[],
    unsuccessfulNumbersToReturn: string[],
  ) => {
    const chunkPromises = chunks.map((chunk) =>
      processChunk(
        projectId,
        projectName,
        chunk,
        isCheckboxChecked,
        displayLoadingMessages,
        successfulNumbersToReturn,
        unsuccessfulNumbersToReturn,
        false, // isProcessingPortfolio
      ).catch((error) => {
        console.error("An error occurred during processing the chunk:", error);
      }),
    );

    await Promise.all(chunkPromises);
  };

  // Generalized processChunk function
  const processChunk = async (
    targetId: string,
    targetName: string,
    chunk: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean,
    successfulNumbersToReturn: string[],
    unsuccessfulNumbersToReturn: string[],
    isProcessingPortfolio: boolean,
  ) => {
    const response = await addReferencesToProject(
      targetId,
      chunk,
      false, // isCitation
      isProcessingPortfolio, // isPortfolioCreation
      isProcessingPortfolio, // isCurrentParentPortfolio
      isProcessingPortfolio ? targetId : undefined, // portfolioId
    );

    const idToNumberMap = new Map<string, string>(
      response.data.map((doc: { reference_id: string; reference_number: string }) => [
        doc.reference_id,
        doc.reference_number,
      ]),
    );

    const successfulNumbers = new Set(
      response.data.map((doc: any) => doc.reference_number),
    );

    successfulNumbersToReturn.push(...(Array.from(successfulNumbers) as string[]));

    const unsuccessfulNumbers = chunk.filter(
      (number) => !successfulNumbers.has(number),
    );
    unsuccessfulNumbersToReturn.push(...unsuccessfulNumbers);

    if (displayLoadingMessages) {
      unsuccessfulNumbers.forEach((number: string) => {
        updateLoadingGroupItem(
          targetId,
          processType,
          number,
          StatusType.ERROR,
          "Invalid reference number",
        );
      });
    }

    const referenceIds = response.data.map(
      (ref: { reference_id: string }) => ref.reference_id,
    );

    const skipInvalidity = !isCheckboxChecked;
    const uploadResponse = await uploadToVDB(
      targetId,
      referenceIds,
      skipInvalidity,
      true,
    );

    successfulNumbersToReturn.push(
      ...uploadResponse.data.successful_ids.map((id: string) => idToNumberMap.get(id)!),
    );
    unsuccessfulNumbersToReturn.push(
      ...uploadResponse.data.failed_ids.map((id: string) => idToNumberMap.get(id)!),
    );

    if (displayLoadingMessages) {
      uploadResponse.data.successful_ids.forEach((id: string) => {
        updateLoadingGroupItem(
          targetId,
          processType,
          idToNumberMap.get(id)!,
          StatusType.SUCCESS,
        );
      });
      uploadResponse.data.failed_ids.forEach((id: string) => {
        updateLoadingGroupItem(
          targetId,
          processType,
          idToNumberMap.get(id)!,
          StatusType.ERROR,
          "Failed to process reference",
        );
      });
    }
  };

  // Handle adding files to project
  const addAndProcessFilesForProject = async (
    projectId: string,
    projectName: string,
    files: File[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    portfolioId?: string,
  ) => {
    let unsuccessfulFilesToReturn: {
      name: string;
      error: string;
      status: "error" | "warning";
    }[] = [];

    const key = nanoid();

    if (displayLoadingMessages) {
      addProcess({
        id: key,
        type: processType,
        projectId: projectId,
        portfolioId: portfolioId,
      });
      addLoadingGroup(projectId, projectName, processType);
      files.forEach((file) => {
        addLoadingGroupItem(projectId, processType, file.name, StatusType.UPLOADING);
      });
    }

    const filePromises = files.map((file) => ({
      file,
      promise: addPDFToProject(projectId, file, false),
    }));

    const idToNameMap: Record<string, string> = {};
    const results = await Promise.all(filePromises.map(({ promise }) => promise));

    filePromises.forEach(({ file }, index) => {
      const result = results[index];

      if (
        result.success &&
        result.data &&
        result.data.length > 0 &&
        result.data[0].status !== "warning"
      ) {
        // Update status to processing
        const referenceData = result.data[0];
        idToNameMap[referenceData.reference_id] = file.name;

        updateLoadingGroupItem(
          projectId,
          processType,
          file.name,
          StatusType.PROCESSING,
        );
      } else if (
        result.data &&
        result.data.length > 0 &&
        result.data[0].status === "warning"
      ) {
        const message = "Reference already exists in the project";
        updateLoadingGroupItem(
          projectId,
          processType,
          file.name,
          StatusType.WARNING,
          message,
        );

        unsuccessfulFilesToReturn.push({
          name: file.name,
          error: message,
          status: "warning",
        });
      } else {
        const message = "Error uploading file";
        updateLoadingGroupItem(
          projectId,
          processType,
          file.name,
          StatusType.ERROR,
          message,
        );

        unsuccessfulFilesToReturn.push({
          name: file.name,
          error: message,
          status: "error",
        });
      }
    });

    const validResults = results.filter(
      (result) =>
        result.success &&
        result.data &&
        result.data.length > 0 &&
        result.data[0].status !== "warning",
    );
    if (validResults.length === 0) {
      if (displayLoadingMessages) {
        removeProcess(key);
      }
      return {
        data: {
          unsuccessfulFiles: unsuccessfulFilesToReturn,
        },
      };
    }

    const filesReferenceIds: string[] = validResults.map(
      (result) => result.data[0].reference_id,
    );

    const skipInvalidity = isCheckboxChecked ? false : true;
    const uploadResponse = await uploadToVDB(
      projectId,
      filesReferenceIds,
      skipInvalidity,
      true,
    );

    if (uploadResponse && uploadResponse.data) {
      const successfulIds = uploadResponse.data.successful_ids || [];
      const failedIds = uploadResponse.data.failed_ids || [];
      unsuccessfulFilesToReturn = [
        ...unsuccessfulFilesToReturn,
        ...failedIds.map((id) => ({
          name: idToNameMap[id],
          error: "Error processing file",
        })),
      ];
      unsuccessfulFilesToReturn = [
        ...unsuccessfulFilesToReturn,
        ...failedIds.map((id) => ({
          name: idToNameMap[id],
          error: "Error processing file",
          status: "error" as "error" | "warning",
        })),
      ];

      await addToUserFiles(successfulIds);

      if (displayLoadingMessages) {
        successfulIds.forEach((id) => {
          updateLoadingGroupItem(
            projectId,
            processType,
            idToNameMap[id],
            StatusType.SUCCESS,
          );
        });
        failedIds.forEach((id) => {
          updateLoadingGroupItem(
            projectId,
            processType,
            idToNameMap[id],
            StatusType.ERROR,
            "Error processing file",
          );
        });
      }
    } else {
      console.error("Upload response is undefined or missing data");
      // Handle the case where uploadResponse or its data is undefined
      unsuccessfulFilesToReturn = [
        ...unsuccessfulFilesToReturn,
        ...filesReferenceIds.map((id) => ({
          name: idToNameMap[id],
          error: "Error processing file",
          status: "error" as "error" | "warning",
        })),
      ];
    }

    await refetchDataOnCompletion(projectId, portfolioId);
    if (displayLoadingMessages) {
      removeProcess(key);
    }
    return {
      data: {
        unsuccessfulFiles: unsuccessfulFilesToReturn,
      },
    };
  };

  const addAndProcessFilesForPortfolio = async (
    projectIds: string[],
    files: File[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    portfolioId?: string,
  ) => {
    let unsuccessfulFilesToReturn: {
      name: string;
      error: string;
      status: "error" | "warning";
    }[] = [];

    const key = nanoid();

    const response = await addReferencesToPortfolio(projectIds, files);
    const results = response.data;

    const idToNameMap: Record<string, string> = {};

    let validResults: any[] = [];
    files.forEach((file, index) => {
      const result = results[index];

      if (result.status === "success") {
        const referenceData = result;
        idToNameMap[referenceData.reference_id] = file.name;
        validResults.push(result);
      } else if (result.status === "warning") {
        const message = "Reference already exists in the project";

        unsuccessfulFilesToReturn.push({
          name: file.name,
          error: message,
          status: "warning",
        });
      } else {
        const message = "Error uploading file";

        unsuccessfulFilesToReturn.push({
          name: file.name,
          error: message,
          status: "error",
        });
      }
    });

    if (validResults.length === 0) {
      if (displayLoadingMessages) {
        removeProcess(key);
      }
      return {
        data: {
          unsuccessfulFiles: unsuccessfulFilesToReturn,
          successfulFiles: [],
        },
      };
    }

    const filesReferenceIds: string[] = validResults.map(
      (result) => result.reference_id,
    );

    // const skipInvalidity = isCheckboxChecked ? false : true;

    const uploadResponse = await uploadToVDBOnly(filesReferenceIds);

    const failedUploadIds = uploadResponse.data.failed_ids || [];
    const successfulUploadIds = uploadResponse.data.successful_ids || [];

    unsuccessfulFilesToReturn = [
      ...unsuccessfulFilesToReturn,
      ...failedUploadIds.map((id) => ({
        name: idToNameMap[id],
        error: "Error uploading file",
      })),
    ];

    const processPromises = projectIds.map((projectId) =>
      processReferences(projectId, successfulUploadIds),
    );

    const processResults = await Promise.all(processPromises);

    let successfulIds: string[] = [];
    let failedIds: string[] = [];
    for (const result of processResults) {
      if (result && result.data) {
        successfulIds = successfulIds.concat(result.data.successful_ids || []);
        failedIds = failedIds.concat(result.data.failed_ids || []);
      }
    }

    const failedProcessFilesToReturn = [
      ...unsuccessfulFilesToReturn,
      ...failedIds.map((id) => ({
        name: idToNameMap[id],
        error: "Error processing file",
        status: "error" as "error" | "warning",
      })),
    ];

    const successfulProcessFilesToReturn = successfulIds.map((id) => ({
      name: idToNameMap[id],
      status: "success",
    }));

    await addToUserFiles(successfulIds);

    await refetchDataOnCompletion(undefined, portfolioId);
    if (displayLoadingMessages) {
      removeProcess(key);
    }
    return {
      data: {
        unsuccessfulFiles: failedProcessFilesToReturn,
        successfulFiles: successfulProcessFilesToReturn,
      },
    };
  };

  const refetchDataOnCompletion = async (projectId: string, portfolioId: string) => {
    if (currentParent === ParentType.PROJECT && projectId === currentProjectId) {
      await fetchProjectData(currentProjectId);
    }

    if (currentParent === ParentType.PORTFOLIO && portfolioId === currentPortfolioId) {
      await getPortfolioMetadata(currentPortfolioId);
    }
  };

  return {
    addAndProcessReferences,
    addAndProcessFilesForProject,
    addAndProcessFilesForPortfolio,
  };
};

export default useProcessReferences;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import {
  SourceNumberButton,
  HighlightedText,
  PatentViewer,
  ReferenceImage,
  CopyToClipboardIconButton,
} from "@/components";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { SearchResult } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { cn } from "@/lib/utils";

interface SearchResultsTableProps {
  searchResults: SearchResult[];
  sentSearchQuery: string;
  general: boolean;
  searchChatProjectId: string;
  height?: string;
}

/**
 * @description Search results table component
 */
export default function SearchResultsTable({
  searchResults,
  sentSearchQuery,
  general,
  searchChatProjectId,
  height,
}: SearchResultsTableProps) {
  const { getFullDocument } = useViz();

  // Global state from store
  const { currentProjectId } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );

  // Local state setup
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [patent, setPatent] = useState<any>(null);
  const [citationText, setCitationText] = useState<string | null>(null);

  const handlePreviewClick = async (refId: string, text: string) => {
    const response = await getFullDocument(refId);

    if (response.success) {
      setPatent(response.data);
      if (text.startsWith('"') && text.endsWith('"')) {
        const strippedText = text.substring(1, text.length - 1);
        setCitationText(strippedText);
      } else {
        setCitationText(text);
      }
      setShowPreview(true);
    } else {
      addErrorMessage("Error fetching reference details.");
    }
  };

  const handleClosePreview = () => {
    setPatent(null);
    setCitationText(null);
    setShowPreview(false);
  };

  return (
    <>
      {searchResults.length > 0 ? (
        <ScrollArea
          className={cn("flex-grow border rounded-md mx-2 overflow-x-auto", height)}
        >
          <Table className="px-4">
            <TableHeader>
              <TableRow>
                <TableHead>Document</TableHead>
                <TableHead>Citation</TableHead>
                <TableHead>Text</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {searchResults.map((result, index) => {
                return (
                  <TableRow key={index}>
                    <TableCellCondensed>
                      {isLikelyPatentNumber(
                        documentsToNumbers?.[result.referenceId],
                      ) ? (
                        <SourceNumberButton
                          sourceNumber={documentsToNumbers?.[result.referenceId] ?? ""}
                          prefix={result.prefix || ""}
                        />
                      ) : (
                        <p>{documentsToNumbers?.[result.referenceId]}</p>
                      )}
                    </TableCellCondensed>
                    <TableCellCondensed className="whitespace-nowrap">
                      {result.paragraph}
                    </TableCellCondensed>
                    <TableCellCondensed>
                      <div className="flex items-center">
                        <div
                          className="w-4 flex-shrink-0 mr-3 self-stretch min-h-[45px] rounded"
                          style={{
                            backgroundColor: result.color || "lightgray",
                          }}
                        />
                        <div>
                          <div className="mb-2.5">
                            <HighlightedText
                              text={result.text}
                              highlight={sentSearchQuery}
                              highlightColor="#e1e9ff"
                            />
                          </div>
                          {result.figureUrls?.map((imageUrl) => (
                            <div key={imageUrl} className="mt-2">
                              <ReferenceImage
                                imageUrl={imageUrl}
                                imageName={imageUrl}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </TableCellCondensed>
                    <TableCellCondensed>
                      <div className="flex items-center space-x-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <CopyToClipboardIconButton
                                index={index}
                                text={result.text}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Copy to Clipboard</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() =>
                                  handlePreviewClick(result.referenceId, result.text)
                                }
                              >
                                <EyeOpenIcon className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>View in Document</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCellCondensed>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ScrollArea>
      ) : (
        <div className="text-center p-8">
          <h3 className="text-lg font-semibold mb-2">No results found.</h3>
          <p>
            Expand your search to include more sources or try a different search query.
          </p>
        </div>
      )}
      <PatentViewer
        open={showPreview}
        handleClose={handleClosePreview}
        patent={patent}
        citationText={citationText || ""}
      />
    </>
  );
}

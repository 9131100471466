/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { H4 } from "@/components/ui/typography";
import { useAdminStore } from "@/store";
import { formatCurrency } from "@/utils/dataUtils";

interface DetailsCardProps {
  fields: { key: string; label: string }[];
  title: string;
  details: { [key: string]: string | number };
}

// Detail card structure
const DetailsCard: React.FC<DetailsCardProps> = ({ fields, title, details }) => {
  return (
    <Card className="w-full">
      <CardHeader>
        <H4>{title}</H4>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-2">
          {fields.map(
            ({ key, label }) =>
              details[key] !== null &&
              details[key] !== "null" && (
                <div key={key} className="flex flex-col items-start">
                  <dt className="text-sm font-medium text-muted-foreground">{label}</dt>
                  <dd className="text-lg font-semibold">{details[key]}</dd>
                </div>
              ),
          )}
        </div>
      </CardContent>
    </Card>
  );
};

// Plan details card
export const PlanDetailsCard: React.FC = () => {
  const { plan } = useAdminStore();
  const [formattedPlan, setFormattedPlan] = useState(null);
  const planFields = [
    { key: "monthlyLicensePrice", label: "Monthly License" },
    { key: "yearlyLicensePrice", label: "Yearly License" },
    { key: "monthlySeatPrice", label: "Monthly Seat" },
    { key: "yearlySeatPrice", label: "Yearly Seat" },
    { key: "invProjectPrice", label: "Invalidity Project" },
    { key: "appProjectPrice", label: "Application Project" },
    { key: "pfaProjectPrice", label: "Portfolio" },
    { key: "documentPrice", label: "Document" },
    { key: "documentsExportedPrice", label: "Exported Document" },
    { key: "documentsAddedPrice", label: "Processed Document" },
  ];

  useEffect(() => {
    if (plan) {
      const formatted: { [key: string]: string | number } = Object.fromEntries(
        Object.entries(plan).map(([key, value]) =>
          typeof value === "number"
            ? [key, formatCurrency(value)]
            : [key, String(value)],
        ),
      );
      setFormattedPlan(formatted);
    } else {
      setFormattedPlan(null);
    }
  }, [plan]);

  if (!formattedPlan) {
    return null; // or return a loading indicator or placeholder
  }

  return (
    <DetailsCard fields={planFields} title="Plan Details" details={formattedPlan} />
  );
};

// Overview details card
export const OverviewDetailsCard: React.FC = () => {
  const { overview } = useAdminStore();
  const fields = [
    { key: "totalUsers", label: "Users" },
    { key: "totalProjects", label: "Projects" },
    { key: "totalPortfolios", label: "Portfolios" },
    { key: "totalDocumentsAdded", label: "Documents Processed" },
    { key: "totalDocumentsExported", label: "Documents Exported" },
    { key: "totalBalance", label: "Current Balance" },
  ];

  const formattedStatistics = {
    ...overview,
    totalBalance: formatCurrency(overview.totalBalance),
  };

  return <DetailsCard fields={fields} title="Overview" details={formattedStatistics} />;
};

// Current billing details card
export const CurrentBillingDetailsCard: React.FC = () => {
  const { currentBillingCycle } = useAdminStore();
  const fields = [
    { key: "startDate", label: "From" },
    { key: "endDate", label: "To" },
    { key: "balance", label: "Balance" },
  ];

  const formattedDetails: { [key: string]: string | number } = currentBillingCycle
    ? Object.fromEntries(
        Object.entries(currentBillingCycle).map(([key, value]) =>
          typeof value === "number"
            ? [key, formatCurrency(value)]
            : [key, String(value)],
        ),
      )
    : {};

  return (
    <DetailsCard
      fields={fields}
      title="Current Billing Cycle"
      details={formattedDetails}
    />
  );
};

// Overview card
export const SuperAdminOverviewCard: React.FC = () => {
  const { superAdminOverview } = useAdminStore();
  const fields = [
    { key: "totalOrganizations", label: "Organizations" },
    { key: "totalUsers", label: "Users" },
    { key: "totalProjects", label: "Projects" },
    { key: "totalDocumentsAdded", label: "Documents Added" },
    { key: "totalDocumentsExported", label: "Documents Exported" },
    { key: "totalBalance", label: "Balance" },
    { key: "totalRevenue", label: "Revenue" },
  ];

  const formattedStatistics = {
    ...superAdminOverview,
    totalBalance: formatCurrency(superAdminOverview.totalBalance),
    totalRevenue: formatCurrency(superAdminOverview.totalRevenue),
  };

  return <DetailsCard fields={fields} title="Overview" details={formattedStatistics} />;
};

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { Button } from "@/components/ui/button";
import { ProjectType } from "@/types";

interface ProjectTypeButtonProps {
  onClick: () => void;
  type: ProjectType;
}

/**
 * @description Project type button
 * @param {function} onClick - Function to handle the click of the button.
 * @param {ProjectType} type - The type of the project.
 */
const ProjectTypeButton: React.FC<ProjectTypeButtonProps> = ({ onClick, type }) => {
  const getTypeDetails = (type: ProjectType) => {
    switch (type) {
      case ProjectType.INV:
        return {
          text: "Invalidity Project",
          alt: "invalidity illustration",
          color: `bg-[var(--chart-blue)]`,
          border: `border-[var(--chart-blue)]`,
        };
      case ProjectType.PFA:
        return {
          text: "Portfolio Analysis",
          alt: "portfolio illustration",
          color: `bg-[var(--chart-yellow)]`,
          border: `border-[var(--chart-yellow)]`,
        };
      case ProjectType.APP:
        return {
          text: "Patent Application",
          alt: "application illustration",
          color: `bg-[var(--chart-green)]`,
          border: `border-[var(--chart-green)]`,
        };
    }
  };

  const { text, alt, color, border } = getTypeDetails(type);

  return (
    <Button
      variant="outline"
      className={`justify-start gap-4 px-4 py-3 h-full `}
      onClick={onClick}
      aria-label={`New ${text}`}
    >
      {text}
    </Button>
  );
};

export default ProjectTypeButton;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useProjectStore, useAppStateStore } from "@/store";
import { useProcessReferences, usePortfolio, useCreateProject } from "@/hooks";
import { AlertTriangle } from "lucide-react";
import { UploadedFileDisplay, DragAndDropFileArea, Loader } from "@/components";
import { ParentType, Patent } from "@/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import DocumentsOnCreate from "@/features/project/create/components/DocumentsOnCreate";

interface UploadFilesModalProps {
  open: boolean;
  handleClose: () => void;
  inContext?: boolean;
  handleContextAddClick?: (files: File[]) => void;
  isPortfolio?: boolean;
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  open,
  handleClose,
  inContext,
  handleContextAddClick,
  isPortfolio = false,
}) => {
  const { addAndProcessReferences, addAndProcessFilesForProject } =
    useProcessReferences();
  const { uploadFilesToPortfolio, addReferencesToPortfolio } = usePortfolio();
  const { getSubjectDetailsOnCreate } = useCreateProject();
  const location = useLocation();
  const inExplore = location.pathname.includes("explore");

  const [files, setFiles] = useState<File[]>([]);
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const [potentialPatentNumbers, setPotentialPatentNumbers] = useState<
    { number: string; file: File }[]
  >([]);
  const [firstSubmitClicked, setFirstSubmitClicked] = useState(false);
  const [patentDetails, setPatentDetails] = useState<Patent[]>([]);
  const [isLoadingPatentDetails, setIsLoadingPatentDetails] = useState(false);

  const { currentProjectId, currentProject, currentPortfolioId, currentPortfolio } =
    useProjectStore();
  const { addWarningMessage } = useAppStateStore();

  const handleCloseClick = (setFilesToEmpty: boolean = true) => {
    if (setFilesToEmpty) {
      setFiles([]);
    }
    setPotentialPatentNumbers([]);
    setFileTypeError(null);
    setFirstSubmitClicked(false);
    setPatentDetails([]);
    handleClose();
  };

  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newValidFiles: File[] = [];
      let errorMessage = "";

      for (let selectedFile of selectedFiles) {
        const isFileTypeValid =
          selectedFile.type === "application/pdf" || selectedFile.type === "text/plain";

        if (isFileTypeValid) {
          newValidFiles.push(selectedFile);
        } else {
          errorMessage += `${selectedFile.name}: File type is not supported. Please upload a PDF or text file. `;
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newValidFiles]);
      setFileTypeError(errorMessage || null);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleAddClick = async () => {
    if (!firstSubmitClicked && !inContext) {
      handleFirstSubmitClick();
    } else {
      handleCloseClick();
      if ((files && files.length > 0) || potentialPatentNumbers.length > 0) {
        if (inContext) {
          handleContextAddClick(files);
        } else if (isPortfolio) {
          await handlePortfolioAddFiles();
        } else {
          await handleProjectAddFiles();
        }
      }
    }
  };

  const handleProjectAddFiles = async () => {
    const existingFileNames = currentProject.references.map((ref) => ref.name);
    const newFiles = files.filter((file) => !existingFileNames.includes(file.name));

    if (newFiles.length < files.length) {
      addWarningMessage(
        `The following file(s) already exist in the project: ${files
          .filter((file) => existingFileNames.includes(file.name))
          .map((file) => file.name)
          .join(", ")}`,
      );
    }

    const operations = [];

    if (potentialPatentNumbers.length > 0) {
      operations.push(
        addAndProcessReferences(
          currentProjectId,
          currentProject.name,
          potentialPatentNumbers.map((patent) => patent.number),
          isCheckboxChecked,
          true,
          undefined,
        ),
      );
    }

    if (newFiles.length > 0) {
      operations.push(
        addAndProcessFilesForProject(
          currentProjectId,
          currentProject.name,
          newFiles,
          isCheckboxChecked,
          true,
          ParentType.PROJECT,
        ),
      );
    }

    await Promise.all(operations);
  };

  const handlePortfolioAddFiles = async () => {
    const portfolioId = currentPortfolioId;
    const portfolioName = currentPortfolio.name;

    const operations = [];
    const patentNumbers = potentialPatentNumbers.map((patent) => patent.number);

    if (patentNumbers.length > 0) {
      operations.push(addReferencesToPortfolio(portfolioId, patentNumbers));
    }

    operations.push(uploadFilesToPortfolio(files, portfolioId, portfolioName));

    await Promise.all(operations);
  };

  const handleFirstSubmitClick = async () => {
    setFirstSubmitClicked(true);
    const newPotentialPatentNumbers: { number: string; file: File }[] = [];

    for (let selectedFile of files) {
      const cleanedFileName = selectedFile.name
        .replace(/\.[^/.]+$/, "")
        .replace(/\s*\([^)]*\)\s*$/, "");
      const isPatentNumber = isLikelyPatentNumber(cleanedFileName);
      if (isPatentNumber) {
        newPotentialPatentNumbers.push({
          number: cleanedFileName,
          file: selectedFile,
        });
      }
    }

    // Update state with new values
    setPotentialPatentNumbers(newPotentialPatentNumbers);

    // Filter out files that exist in both arrays
    const filteredFiles = files.filter(
      (file) => !newPotentialPatentNumbers.map((patent) => patent.file).includes(file),
    );

    if (newPotentialPatentNumbers.length === 0) {
      handleCloseClick(false);
      if (isPortfolio) {
        await handlePortfolioAddFiles();
      } else {
        await handleProjectAddFiles();
      }
    } else {
      setFiles(filteredFiles);
      await checkForPublishedPatents(
        newPotentialPatentNumbers.map((patent) => patent.number),
      );
    }
  };

  const handleProcessAsPDF = (number: string) => {
    const index = potentialPatentNumbers.findIndex(
      (patent) => patent.number === number,
    );
    if (index !== -1) {
      const movedFile = potentialPatentNumbers[index].file;
      setPotentialPatentNumbers((prevNumbers) =>
        prevNumbers.filter((patent) => patent.number !== number),
      );
      setPatentDetails((prevDetails) =>
        prevDetails.filter((detail) => detail.number !== number),
      );
      setFiles((prevFiles) => [...prevFiles, movedFile]);
    }
  };

  const checkForPublishedPatents = async (patentNumbers: string[]) => {
    setIsLoadingPatentDetails(true);
    const uniquePatentNumbers = Array.from(new Set(patentNumbers));

    const response = await getSubjectDetailsOnCreate(uniquePatentNumbers, false);

    if (response.error) {
      console.error("response.error", response.error);
    } else {
      setPatentDetails(response.data);
    }
    setIsLoadingPatentDetails(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleCloseClick}>
      <DialogContent
        className="w-[50vw] min-w-[300px] max-w-[800px]"
        aria-describedby="Upload files"
      >
        <DialogHeader>
          <DialogTitle className="flex items-center">
            {isPortfolio ? (
              "Add references to all subjects"
            ) : potentialPatentNumbers.length > 0 ? (
              <>
                <AlertTriangle className="h-5 w-5 mr-2 text-yellow-500" />
                Published patents or applications detected
              </>
            ) : (
              "Upload files"
            )}
          </DialogTitle>
        </DialogHeader>
        {potentialPatentNumbers.length === 0 && !firstSubmitClicked && (
          <div className="flex-grow flex flex-col space-y-4 overflow-hidden">
            <DragAndDropFileArea
              handleFiles={handleFileChange}
              supportedFileTypes={[".pdf", ".txt"]}
            />
          </div>
        )}

        {potentialPatentNumbers.length > 0 && (
          <div>
            <p className="text-sm text-gray-500 mb-2">
              We detected published patents or patent applications in your files. We
              will use information from our patent database (recommended), unless you
              choose to process as PDFs.
            </p>
            {isLoadingPatentDetails && (
              <Loader message="Checking for published patents..." />
            )}
          </div>
        )}

        {patentDetails.length > 0 && (
          <DocumentsOnCreate
            details={patentDetails}
            inUploadFilesModal={true}
            handleProcessAsPDF={handleProcessAsPDF}
            height={files.length > 0 ? "max-h-[275px]" : "max-h-[500px]"}
          />
        )}

        {files.length > 0 && (
          <div className="mt-4">
            <ScrollArea className="flex-grow max-h-[200px]">
              <div className="flex flex-wrap gap-2">
                {files
                  .slice()
                  .reverse()
                  .map((file, index) => (
                    <UploadedFileDisplay
                      file={file}
                      key={files.length - 1 - index}
                      onRemove={() => handleRemoveFile(files.length - 1 - index)}
                      fullWidth={false} // Set to false to prevent full width
                    />
                  ))}
              </div>
            </ScrollArea>
          </div>
        )}

        {fileTypeError && (
          <Alert variant="destructive">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>{fileTypeError}</AlertDescription>
          </Alert>
        )}

        <DialogFooter className="mt-4">
          <div className="flex items-center justify-between space-x-2 w-full">
            <div className="flex items-center space-x-2">
              {!inContext && !inExplore && !isPortfolio && (
                <CheckboxWithText
                  id="complete-charts"
                  checked={isCheckboxChecked}
                  onCheckedChange={(checked) =>
                    setIsCheckboxChecked(checked as boolean)
                  }
                  label="Complete claim and feature chart(s)"
                />
              )}
            </div>
            <Button
              onClick={handleAddClick}
              disabled={files.length === 0 && potentialPatentNumbers.length === 0}
            >
              Add references
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFilesModal;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect, useCallback } from "react";
import { useViz } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { splitId } from "@/utils/projectUtils";
import {
  Table,
  TableBody,
  DataTableHead,
  DataTableRowOutline,
  TableHeader,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PatentViewer } from "@/components";
import InvalidityTableRow from "./InvalidityTableRow";

interface InvalidityTableProps {
  fetchReferenceChartData: () => void;
}

/**
 * @description Data table
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 */
const InvalidityTable: React.FC<InvalidityTableProps> = ({
  fetchReferenceChartData,
}) => {
  const { getFullDocument } = useViz();

  const { currentProjectId, currentProject, chartData, selectedElementType } =
    useProjectStore();
  const addErrorMessage = useAppStateStore((state) => state.addErrorMessage);
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );

  const [editBooleanArray, setEditBooleanArray] = useState<boolean[]>(
    chartData.map(() => false),
  );
  const [claimEditIndex, setClaimEditIndex] = useState<number | null>(null);
  const [claimIsAdding, setClaimIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [addingCitationKey, setAddingCitationKey] = useState<string>("");
  const [showPatentViewer, setShowPatentViewer] = useState<boolean>(false);
  const [patentViewerPatent, setPatentViewerPatent] = useState<any>(null);

  const referenceIdList =
    chartData.length > 0
      ? Object.keys(chartData[0]).filter(
          (key) =>
            key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
        )
      : [];

  const updateEditBooleanArray = useCallback((index: number, value: boolean) => {
    setEditBooleanArray((currentStates) => {
      let newArray = [...currentStates];
      newArray[index] = value;
      return newArray;
    });
  }, []);

  const handleShowPatentViewer = async (refId: string) => {
    const response = await getFullDocument(refId);
    if (response.success) {
      setPatentViewerPatent(response.data);
      setShowPatentViewer(true);
    } else {
      addErrorMessage(
        `Error getting patent ${refId} from project ${currentProject.name} `,
      );
      handleClosePreview();
    }
  };

  const handleClosePreview = () => {
    setPatentViewerPatent(null);
    setShowPatentViewer(false);
  };

  useEffect(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setClaimIsAdding(false);
    setIsEditing(false);
  }, [selectedElementType]);

  const getColumnWidth = () => {
    // Divide the 80% width among the reference ID columns
    return referenceIdList.length > 0 ? `${75 / referenceIdList.length}%` : "auto";
  };

  const referenceColumnWidth = getColumnWidth();

  return (
    <div>
      {showPatentViewer && (
        <PatentViewer
          open={showPatentViewer}
          handleClose={handleClosePreview}
          patent={patentViewerPatent}
          citationText={""}
        />
      )}
      <Table>
        <TableHeader>
          <DataTableRowOutline>
            <DataTableHead />
            <DataTableHead className="font-bold">Element</DataTableHead>
            {referenceIdList.map((refId) => (
              <DataTableHead
                key={`${refId}-header`}
                style={{ width: referenceColumnWidth }}
              >
                <div className="flex justify-between items-center">
                  <span className="font-bold">
                    Invalidity for{" "}
                    {documentsToNicknames?.[refId]
                      ? `${documentsToNicknames?.[refId]}`
                      : splitId(documentsToNumbers?.[refId] ?? "")}
                  </span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleShowPatentViewer(refId)}
                        >
                          <EyeOpenIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View full document</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </DataTableHead>
            ))}
          </DataTableRowOutline>
        </TableHeader>
        <TableBody>
          {chartData.map((row, index) => (
            <InvalidityTableRow
              key={index}
              row={row}
              rowIndex={index}
              addingCitationKey={addingCitationKey}
              setAddingCitationKey={setAddingCitationKey}
              setClaimEditIndex={setClaimEditIndex}
              setEditBooleanArray={setEditBooleanArray}
              claimEditIndex={claimEditIndex}
              setClaimIsAdding={setClaimIsAdding}
              setIsEditing={setIsEditing}
              referenceColumnWidth={referenceColumnWidth}
              fetchReferenceChartData={fetchReferenceChartData}
              updateEditBooleanArray={updateEditBooleanArray}
              editBooleanArray={editBooleanArray}
              claimIsAdding={claimIsAdding}
              isEditing={isEditing}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvalidityTable;

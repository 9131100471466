import { create } from "zustand";
import { Patent } from "@/types";

interface CreateProjectStore {
  subjectNumbers: string[];
  subjectDetails: Patent[];
  spinnerText: string;
  projectError: boolean;
  isProjectCreationInProgress: boolean;
  features: { [key: string]: string }[];
  claims: { [key: string]: string }[];
  abstract: string;
  subjectMode: string;
  clientNumber: string;
  projectName: string;
  files: File[];
  disclosure_files: File[];
  disclosureMode: string;
  updateSubjectDetails: (details: Patent[]) => void;
  updateSpinnerText: (text: string) => void;
  updateProjectError: (error: boolean) => void;
  updateIsProjectCreationInProgress: (inProgress: boolean) => void;
  updateSubjectNumbers: (numbers: string[]) => void;
  updateSubjectMode: (mode: string) => void;
  updateFeatures: (features: { [key: string]: string }[]) => void;
  updateClaims: (claims: { [key: string]: string }[]) => void;
  updateClientNumber: (number: string) => void;
  updateProjectName: (name: string) => void;
  resetCreateProjectStore: () => void;
  updateFiles: (files: File[]) => void;
  addFile: (file: File) => void;
  updateDisclosure: (disclosure: string) => void;
  updateDisclosureMode: (mode: string) => void;
  updateAbstract: (abstract: string) => void;
  updateDisclosureFiles: (files: File[]) => void;
}

const initialState: Omit<
  CreateProjectStore,
  | "updateSubjectNumbers"
  | "updateSubjectDetails"
  | "updateSpinnerText"
  | "updateProjectError"
  | "updateIsProjectCreationInProgress"
  | "updateAbstract"
  | "updateSubjectMode"
  | "updateFeatures"
  | "updateClaims"
  | "updateClientNumber"
  | "updateProjectName"
  | "resetCreateProjectStore"
  | "updateFiles"
  | "addFile"
  | "updateDisclosureFiles"
  | "updateDisclosureMode"
  | "updateAbstract"
> = {
  subjectNumbers: [],
  subjectDetails: [],
  spinnerText: "",
  projectError: false,
  isProjectCreationInProgress: false,
  features: [{ "1": "" }],
  claims: [{ "1": "" }],
  abstract: "",
  subjectMode: "",
  clientNumber: "",
  projectName: "",
  files: [],
  disclosure_files: [],
  disclosureMode: "",
  updateDisclosure: function (disclosure: string): void {
    throw new Error("Function not implemented.");
  },
};

const useCreateProjectStore = create<CreateProjectStore>((set) => ({
  ...initialState,

  resetCreateProjectStore: () => set(() => initialState),

  updateSubjectDetails: (details) => set(() => ({ subjectDetails: details })),

  updateSpinnerText: (text) => set(() => ({ spinnerText: text })),

  updateProjectError: (error) => set(() => ({ projectError: error })),

  updateIsProjectCreationInProgress: (inProgress) =>
    set(() => ({ isProjectCreationInProgress: inProgress })),

  updateSubjectNumbers: (numbers) => set(() => ({ subjectNumbers: numbers })),

  updateAbstract: (abstract) => set(() => ({ abstract: abstract })),

  updateSubjectMode: (mode) => set(() => ({ subjectMode: mode })),

  updateFeatures: (features) => set(() => ({ features: features })),

  updateClaims: (claims) => set(() => ({ claims: claims })),

  updateClientNumber: (number) => set(() => ({ clientNumber: number })),

  updateProjectName: (name) => set(() => ({ projectName: name })),

  updateFiles: (files) => set(() => ({ files: files })),

  addFile: (file) => set((state) => ({ files: [...state.files, file] })),

  updateDisclosureFiles: (files) => set(() => ({ disclosure_files: files })),

  updateDisclosureMode: (mode) => set(() => ({ disclosureMode: mode })),
}));

export default useCreateProjectStore;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useRef } from "react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { TrashIcon, RefreshCcwIcon } from "lucide-react";
import { useState } from "react";
import { TableTags } from "./TableTags";
import { TableColumn } from "./TableColumn";
import { TableSort } from "./TableSort";
import { TableFilter } from "./TableFilter";
import { cn } from "@/lib/utils";
import { Spinner } from "../ui/spinner";

export function TableActionBar({
  table,
  selectedRowsCount,
  enableRowDelete,
  onDeleteSelected,
  children,
  multiSorting,
  setMultiSorting,
  filters,
  handleTagUpdate,
  tags,
  showTag,
  showColumns,
  showReprocess,
  title,
  isLoading,
  onReprocessSelected,
}) {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState<string[]>(tags);

  useEffect(() => {
    setTagOptions(tags);
  }, [tags]);

  return (
    <div className="flex items-center justify-between p-2 w-full">
      <div className={cn("flex items-center", title ? "w-full" : "space-x-1 gap-1")}>
        {title && (
          <p className="text-base font-medium p-1 h-auto whitespace-nowrap pl-2">
            {title}
          </p>
        )}
        <div className={cn("flex space-x-1 gap-1", title && "ml-auto")}>
          {showColumns && <TableColumn table={table} />}

          <TableSort
            table={table}
            multiSorting={multiSorting}
            setMultiSorting={setMultiSorting}
          />

          {Object.keys(filters).length > 0 && (
            <TableFilter table={table} filters={filters} />
          )}

          {((selectedRowsCount > 0 && enableRowDelete) || isLoading) && (
            <div className="flex items-center rounded-md bg-accent border border-input h-9">
              <div className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium h-9 px-4 py-2 text-pr">
                <span>{selectedRowsCount} selected</span>
              </div>
              <TooltipProvider>
                <>
                  {showReprocess && (
                    <Tooltip>
                      <TooltipTrigger>
                        <div
                          className="h-9 w-9 flex items-center justify-center hover:bg-blue-500 hover:text-white transition-colors cursor-pointer border-l border-r border-input dark:border-white"
                          onClick={onReprocessSelected}
                        >
                          <RefreshCcwIcon className="h-4 w-4" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>Resolve Statuses</TooltipContent>
                    </Tooltip>
                  )}

                  {showTag && (
                    <TableTags
                      tagOptions={tagOptions}
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      onClose={handleTagUpdate}
                      onApply={handleTagUpdate}
                    />
                  )}
                  <Tooltip>
                    <TooltipTrigger>
                      {isLoading ? (
                        <div className="h-9 w-9 flex items-center justify-center bg-red-500 text-white transition-colors cursor-pointer border-l border-input rounded-r-md dark:border-white">
                          <Spinner className="h-4 w-4 text-white" />
                        </div>
                      ) : (
                        <div
                          className="h-9 w-9 flex items-center justify-center hover:bg-red-500 hover:text-white transition-colors cursor-pointer border-l border-input rounded-r-md dark:border-white"
                          onClick={onDeleteSelected}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </div>
                      )}
                    </TooltipTrigger>
                    <TooltipContent>Delete</TooltipContent>
                  </Tooltip>
                </>
              </TooltipProvider>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-1 gap-1 pl-1">{children}</div>
    </div>
  );
}

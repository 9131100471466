/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import he from "he";
import { useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { MoreVertical } from "lucide-react";
import { Reference } from "@/types";
import { PatentViewer } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTable } from "@/components/table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "@/components/ui/badge";

interface FilesTableProps {
  references: Reference[];
  onDelete?: (references: Reference[]) => void;
  onDeleteSelected?: () => void;
  onRowSelection?: (references: Reference[]) => void;
  height?: string;
  children?: React.ReactNode;
}

/**
 * @description Table for displaying user uploaded files
 */
const FilesTable: React.FC<FilesTableProps> = ({
  references,
  onDelete,
  onDeleteSelected,
  onRowSelection,
  height,
  children,
}) => {
  const { getFullDocument } = useViz();
  const { addErrorMessage } = useAppStateStore();

  const [patentDetails, setPatentDetails] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const handleRowSelection = (selectedRows: Reference[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRows);
    }
  };

  const handleRemoveFromProject = (references: Reference[]) => {
    if (references && onDelete) {
      onDelete(references);
    }
  };

  const handleViewDetails = async (reference: Reference) => {
    const response = await getFullDocument(reference.id);
    if (!response.success) {
      addErrorMessage(
        response.message || "An error occurred while fetching the document details.",
      );
    } else {
      setPatentDetails(response.data);
    }
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setPatentDetails(null);
    setShowPreview(false);
  };

  // Prepare columns for the table
  const columns: ColumnDef<Reference, any>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => he.decode(row.original.name || "-"),
    },
    {
      accessorKey: "uploadedAt",
      header: "Uploaded At",
      enableSorting: true,
      cell: ({ row }) => {
        const reference = row.original;
        return reference.uploadedAt
          ? new Date(reference.uploadedAt).toLocaleString()
          : "—";
      },
    },
    {
      accessorKey: "projects",
      header: "Project",
      enableSorting: true,
      cell: ({ row }) => {
        return (
          row.original.projects &&
          Object.values(row.original.projects).length > 0 && (
            <>
              {Object.values(row.original.projects || {}).map(
                (project: { name: string }) => (
                  <Badge variant="outline" className="mr-2">
                    {project.name}
                  </Badge>
                ),
              )}
            </>
          )
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const reference = row.original;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => handleViewDetails(reference)}>
                View File
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleRemoveFromProject([reference])}>
                Remove
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={references}
        onRowSelection={handleRowSelection}
        height={height}
        showActions={true}
        onDeleteSelected={onDeleteSelected}
        enableRowDelete={true}
        title="My Files"
      >
        {children}
      </DataTable>
      {showPreview && (
        <PatentViewer
          open={showPreview}
          handleClose={handleClosePreview}
          patent={patentDetails}
          citationText={""}
        />
      )}
    </>
  );
};

export default FilesTable;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import DragAndDropFileArea from "@/components/files/DragAndDropFileArea";
import UploadedFileDisplay from "@/components/files/UploadedFileDisplay";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
} from "@/components/ui/alert-dialog";
import { Spinner } from "@/components/ui/spinner";
import SearchResultsTable from "@/features/explore/search/components/SearchResultsTable";
import { Paragraph } from "@/components/ui/typography";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
interface GenerateContextModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  modalType: "url" | "document" | "input" | null;
  url: string;
  setUrl: (url: string) => void;
  files: File[];
  handleFileChange: (selectedFiles: File[]) => void;
  handleRemoveFile: (index: number) => void;
  inputContext: string;
  setInputContext: (context: string) => void;
  handleUrlSubmit: () => void;
  handleFileUpload: () => void;
  handleInputSubmit: () => void;
  isValidUrl: (url: string) => boolean;
}

/**
 * @description Generate context modal
 */
export const GenerateContextModal: React.FC<GenerateContextModalProps> = ({
  isOpen,
  onOpenChange,
  modalType,
  url,
  setUrl,
  files,
  handleFileChange,
  handleRemoveFile,
  inputContext,
  setInputContext,
  handleUrlSubmit,
  handleFileUpload,
  handleInputSubmit,
  isValidUrl,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>
            {modalType === "url"
              ? "Generate context from URL"
              : modalType === "document"
                ? "Generate context from file"
                : "Generate context from input"}
          </DialogTitle>
          <VisuallyHidden asChild>
            <DialogDescription>
              {modalType === "url"
                ? "Generate context from URL"
                : modalType === "document"
                  ? "Generate context from file"
                  : "Generate context from input"}
            </DialogDescription>
          </VisuallyHidden>
        </DialogHeader>
        {modalType === "url" && (
          <div className="space-y-4">
            <Input
              type="text"
              placeholder="Enter URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        )}
        {modalType === "document" && (
          <div className="space-y-4">
            {files.length === 0 && (
              <DragAndDropFileArea
                handleFiles={handleFileChange}
                supportedFileTypes={[".pdf", ".txt"]}
              />
            )}
            {files.map((file, index) => (
              <UploadedFileDisplay
                key={index}
                file={file}
                onRemove={() => handleRemoveFile(index)}
                fullWidth={true}
              />
            ))}
          </div>
        )}
        {modalType === "input" && (
          <div className="flex-grow overflow-y-auto ">
            <AutosizeTextarea
              value={inputContext}
              onChange={(e) => setInputContext(e.target.value)}
              maxHeight={500}
            />
          </div>
        )}
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={
              modalType === "url"
                ? handleUrlSubmit
                : modalType === "document"
                  ? handleFileUpload
                  : handleInputSubmit
            }
            disabled={
              modalType === "url"
                ? !isValidUrl(url)
                : modalType === "document"
                  ? files.length === 0
                  : modalType === "input"
                    ? inputContext.length === 0
                    : false
            }
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface BulkAddModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  bulkAddType: "facts" | "terms";
  bulkAddContent: string;
  setBulkAddContent: (content: string) => void;
  onConfirm: () => void;
}

/**
 * @description Bulk add modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {string} bulkAddType - The type of bulk add to perform.
 * @param {string} bulkAddContent - The content to add.
 * @param {function} setBulkAddContent - Function to set the content to add.
 * @param {function} onConfirm - Function to confirm the modal.
 */
export const BulkAddModal: React.FC<BulkAddModalProps> = ({
  isOpen,
  onOpenChange,
  bulkAddType,
  bulkAddContent,
  setBulkAddContent,
  onConfirm,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>
            Add {bulkAddType === "facts" ? "key facts" : "terms and definitions"}
          </DialogTitle>
          <VisuallyHidden asChild>
            <DialogDescription>
              Bulk add {bulkAddType === "facts" ? "key facts" : "terms and definitions"}
            </DialogDescription>
          </VisuallyHidden>
        </DialogHeader>
        <div className="flex-grow overflow-y-auto">
          <AutosizeTextarea
            value={bulkAddContent}
            onChange={(e) => setBulkAddContent(e.target.value)}
            placeholder={
              bulkAddType === "facts"
                ? "Enter one fact per line"
                : "Enter in the format 'Term: Definition', one per line"
            }
            className="min-h-[200px] max-h-[calc(80vh-200px)]"
          />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Add</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface ClearConfirmationModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
}

/**
 * @description Clear confirmation modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {function} onConfirm - Function to confirm the modal.
 */
export const ClearConfirmationModal: React.FC<ClearConfirmationModalProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
}) => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Clear all context?</AlertDialogTitle>
          <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Clear All</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

interface SearchResultsModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  searchResults: any[];
  sentSearchQuery: string;
  showSpinner: boolean;
  currentProjectId: string;
}

/**
 * @description Search results modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {array} searchResults - The search results.
 * @param {string} sentSearchQuery - The search query sent.
 * @param {boolean} showSpinner - Whether to show the spinner.
 * @param {string} currentProjectId - The current project ID.
 */
export const SearchResultsModal: React.FC<SearchResultsModalProps> = ({
  isOpen,
  onOpenChange,
  searchResults,
  sentSearchQuery,
  showSpinner,
  currentProjectId,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-6xl">
        <DialogHeader>
          <DialogTitle>{sentSearchQuery}</DialogTitle>
          <VisuallyHidden asChild>
            <DialogDescription>
              Semantic search results for "{sentSearchQuery}"
            </DialogDescription>
          </VisuallyHidden>
        </DialogHeader>
        {showSpinner ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : searchResults && searchResults.length > 0 ? (
          <SearchResultsTable
            searchResults={searchResults}
            sentSearchQuery={sentSearchQuery}
            general={false}
            searchChatProjectId={currentProjectId}
            height="h-[75vh]"
          />
        ) : (
          <Paragraph>No search results found.</Paragraph>
        )}
      </DialogContent>
    </Dialog>
  );
};

/*
 * Copyright AndAI, Inc. 2024. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { splitId } from "@/utils/projectUtils";
import { useProjectStore, useAppStateStore } from "@/store";
import { SummaryChartData } from "@/types";
import { Badge } from "@/components/ui/badge";
import { Loader } from "@/components";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useViz } from "@/hooks";
import { Button } from "@/components/ui/button";
import { PaintBucket } from "lucide-react";
interface SummaryTableProps {
  handleSelect: (isExpanded: boolean, claimNumber: string | null, key: string) => void;
  isExpanded: boolean;
  onFillStart: () => void;
  onFillEnd: () => void;
}

/**
 * @description Summary table
 * @param {function} handleSelect - Function to handle the selection of a claim.
 * @param {boolean} isExpanded - Whether the table is expanded.
 */
const SummaryTable: React.FC<SummaryTableProps> = ({
  handleSelect,
  isExpanded,
  onFillStart,
  onFillEnd,
}) => {
  const { getRemainingParagraphs } = useViz();

  // Global state from store
  const {
    currentProjectId,
    selectedElementType,
    selectedReferences,
    summaryChartData,
    summaryChartHeaders,
    summaryChartRowNames,
    updateSummaryChartRowNames,
    summaryChartRowHeightCollapsed,
  } = useProjectStore();
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );
  const { addErrorMessage } = useAppStateStore();

  // Local state setup
  const [isLoading, setIsLoading] = useState(true);
  const [remainingParagraphs, setRemainingParagraphs] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    if (summaryChartData.length > 0 && summaryChartHeaders.length > 0) {
      setIsLoading(false);
      updateSummaryChartRowNames(
        summaryChartData.map((row: SummaryChartData) => row.claim_number),
      );
    }
  }, [summaryChartData, summaryChartHeaders]);

  const memoizedSummaryChartData = useMemo(() => {
    {
      /* Render each row */
    }
    return summaryChartData.map((row, rowIndex) => (
      <div key={rowIndex} className={`contents m-1 ${isExpanded ? "mb-2" : ""}`}>
        {isExpanded && (
          <div className="sticky left-0 z-50 w-[75px] bg-background align-right truncate">
            {summaryChartRowNames[rowIndex]}
          </div>
        )}
        {summaryChartHeaders.map((key, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            className="px-0.75 bg-background"
            style={{
              minWidth: "75px",
              flex: "1",
              margin: "0 2px",
            }}
          >
            {/* Render each cell */}
            <div
              className={`w-full h-full flex items-center justify-center cursor-pointer ${
                isExpanded ? "rounded m-1" : ""
              } transition-colors duration-200`}
              style={{
                backgroundColor: row[key] || "lightgray",
                height: isExpanded ? "25px" : `${summaryChartRowHeightCollapsed}px`,
              }}
              onClick={() => handleSelect(isExpanded, row.claim_number, key)}
            />
          </div>
        ))}
      </div>
    ));
  }, [
    summaryChartData,
    isExpanded,
    summaryChartRowHeightCollapsed,
    summaryChartRowNames,
    summaryChartHeaders,
    handleSelect,
  ]);

  const handleFill = useCallback(
    async (key: string) => {
      // console.log(summaryChartHeaders);

      try {
        onFillStart();
        const response = await getRemainingParagraphs(currentProjectId, key);
        if (response.success) {
          setRemainingParagraphs(response.data);
        } else {
          console.error("Failed to fetch remaining paragraphs");
        }
      } catch (error) {
        console.error("Error fetching remaining paragraphs:", error);
        addErrorMessage("Failed to fetch remaining paragraphs");
      } finally {
        onFillEnd();
      }
    },
    [currentProjectId, getRemainingParagraphs, addErrorMessage, onFillStart, onFillEnd],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col relative pt-2">
      <div
        className={cn(
          "overflow-x-auto w-[calc(100vw-130px)]",
          isExpanded ? "h-[50vh] overflow-y-auto" : "h-[220px] overflow-y-hidden",
        )}
      >
        <div>
          <div
            className="grid gap-x-2 bg-background w-full"
            style={{
              gridTemplateColumns: isExpanded
                ? `75px repeat(${summaryChartHeaders.length}, minmax(75px, 1fr))`
                : `repeat(${summaryChartHeaders.length}, minmax(75px, 1fr))`,
            }}
          >
            {isExpanded && (
              <div className="sticky bg-background left-0 top-0 z-50 w-[75px]" />
            )}

            {summaryChartHeaders.map((key, idx) => (
              <div
                key={idx}
                className="sticky top-0 z-10 px-0.75"
                style={{
                  minWidth: "75px",
                  flex: "1",
                  margin: "0 4px",
                }}
              >
                <div className="flex flex-col items-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="w-full focus:outline-none focus:ring-0">
                        <div
                          className={cn(
                            "w-full overflow-hidden bg-background",
                            isExpanded ? "m-1" : "m-0.75",
                            "cursor-pointer rounded-md mb-2 px-2 py-1 font-medium flex items-center justify-between",
                            selectedReferences?.some((detail) => detail.id === key) &&
                              "border-blue-500 border-2",
                          )}
                          onClick={() => handleSelect(false, null, key)}
                        >
                          <span className="text-xs truncate mr-2 flex-grow">
                            {documentsToNicknames?.[key] ||
                              (documentsToNumbers?.[key] &&
                                splitId(documentsToNumbers[key])) ||
                              ""}
                          </span>
                          <div className="flex items-center justify-end">
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFill(key);
                                    }}
                                    size="icon"
                                    className="h-7 w-7 flex items-center justify-center md:hidden"
                                  >
                                    <PaintBucket className="w-3 h-3" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Experimental: Pull IPR (WIP)</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFill(key);
                                    }}
                                    size="sm"
                                    className="h-7 hidden md:flex items-center justify-center"
                                  >
                                    Pull IPR
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Experimental: Pull IPR (WIP)</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {documentsToNicknames?.[key] ??
                            splitId(documentsToNumbers?.[key] ?? "")}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            ))}

            {memoizedSummaryChartData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect } from "react";
import { useAppStateStore } from "@/store";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle, Info, CheckCircle, AlertTriangle } from "lucide-react";
import { Spinner } from "../ui/spinner";
import LoadingGroupAlert from "./LoadingGroupAlert";
import { ProcessType } from "@/types";
const getTimestamp = () => new Date().getTime();

/**
 * @description Status/loading messages component
 */
const AlertMessages: React.FC = () => {
  // State for the error messages
  const {
    statusMessages,
    removeStatusMessage,
    loadingGroups,
    loadingMessages,
    removeLoadingGroup,
  } = useAppStateStore();

  useEffect(() => {
    // Set up timers for each message
    const timers = statusMessages.map((message) =>
      setTimeout(() => removeStatusMessage(message.key), 5000),
    );

    // Clean up timers on component unmount or when messages change
    return () => timers.forEach(clearTimeout);
  }, [statusMessages, removeStatusMessage]);

  const getIcon = (severity: string) => {
    switch (severity) {
      case "error":
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      case "info":
        return <Info className="h-4 w-4 text-blue-500" />;
      case "success":
        return <CheckCircle className="h-4 w-4 text-blue-500" />;
      case "warning":
        return <AlertTriangle className="h-4 w-4 text-yellow-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50 max-h-[calc(100vh-2rem)] overflow-y-auto">
      <div className="space-y-2">
        {loadingMessages.map((message) => (
          <Alert
            key={message.key}
            variant="default"
            className="flex items-center pr-8 relative"
          >
            <div className="flex items-center justify-between gap-2">
              <Spinner className="mr-2 flex-shrink-0 h-4 w-4" />
              <AlertTitle>{message.message}</AlertTitle>
            </div>
          </Alert>
        ))}

        {loadingGroups.map((loadingGroup) => (
          <LoadingGroupAlert
            key={loadingGroup.key + getTimestamp()}
            loadingGroup={loadingGroup}
            removeLoadingGroup={removeLoadingGroup}
            processType={loadingGroup.processType as ProcessType}
          />
        ))}

        {[...statusMessages.filter((m) => m.severity)].map((message) => (
          <Alert
            key={message.key}
            variant={message.severity === "error" ? "destructive" : "default"}
            className="relative pr-8"
          >
            {getIcon(message.severity)}
            <AlertTitle>
              {message.severity.charAt(0).toUpperCase() + message.severity.slice(1)}
            </AlertTitle>
            <AlertDescription>{message.message}</AlertDescription>
          </Alert>
        ))}
      </div>
    </div>
  );
};

export default AlertMessages;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { useProjectStore } from "@/store";
import { BaseChat } from "@/features/explore/chat/components";
import { ProjectPage } from "@/components";

/**
 * @description Project-level chat page
 */
const ProjectChatPage: React.FC = () => {
  // Global state from store
  const subjectId: string = useProjectStore((state) => state.currentProject.subjectId);
  const referenceIds: string[] = useProjectStore(
    (state) => state.currentProject.referenceIds,
  );
  const currentProject: { id: string; name: string } = useProjectStore(
    (state) => state.currentProject,
  );
  const projectIdArray: string[] = [subjectId, ...referenceIds];

  // Set page title
  const pageName = `${currentProject.name} - Chat` || "Chat";

  return (
    <ProjectPage pageName={pageName}>
      <BaseChat
        generalBool={false}
        projectId={currentProject.id}
        documents={projectIdArray}
        disabledBool={false}
      />
    </ProjectPage>
  );
};

export default ProjectChatPage;

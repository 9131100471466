/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect } from "react";
import { useProjectStore } from "@/store";
import { useElementOperations } from "@/hooks";
import { ElementType } from "@/types";
import { ElementEditor } from "@/features/project/components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface UpdateElementsModalProps {
  open: boolean;
  onClose: () => void;
  itemType: ElementType.FEATURE | ElementType.CLAIM | ElementType.EVALUATE;
}

/**
 * @description Update elements (features/claims) modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} onClose - Function to close the modal.
 * @param {ElementType} itemType - The type of item to update.
 */
function UpdateElementsModal({ open, onClose, itemType }: UpdateElementsModalProps) {
  const { addClaimOrFeatureToProject, editClaimOrFeature, deleteClaimOrFeature } =
    useElementOperations();
  const { currentProjectId } = useProjectStore();

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleAdd = async (id: string, text: string, index: number) => {
    await addClaimOrFeatureToProject(
      currentProjectId,
      id,
      text,
      index,
      itemType === ElementType.FEATURE,
    );
  };

  const handleEdit = async (oldId: string, newId: string, newText: string) => {
    await editClaimOrFeature(
      currentProjectId,
      oldId,
      newId,
      newText,
      itemType === ElementType.FEATURE,
    );
  };

  const handleDelete = async (id: string, index: number) => {
    await deleteClaimOrFeature(
      currentProjectId,
      id,
      itemType === ElementType.FEATURE,
      index,
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col">
        <DialogHeader>
          <DialogTitle>
            Edit {itemType === ElementType.FEATURE ? "features" : "claims"}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-2 overflow-y-auto flex-grow">
          <ElementEditor
            itemType={itemType}
            isUpdateMode={true}
            startInEditMode={false}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
            useCurrentSubject={true}
          />
        </div>
        <div className="flex justify-end">
          <Button onClick={handleClose} aria-label="Done">
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateElementsModal;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { create } from "zustand";
import {
  Organization,
  User,
  Invoice,
  AdminProjectDetails,
  CurrentBillingCycle,
  AdminOverview,
  SuperAdminOverview,
} from "@/types";

interface AdminState {
  organization: Organization;
  users: User[];
  projects: AdminProjectDetails[];
  invoices: Invoice[];
  currentBillingCycle: CurrentBillingCycle;
  overview: AdminOverview;
  plan: Organization;
  superAdminInvoices: Invoice[];
  superAdminOrganizations: Organization[];
  superAdminOverview: SuperAdminOverview;
  superAdminSelectedOrganization: Organization;
}

interface AdminActions {
  updateOrganization: (organization: Organization) => void;
  updateUsers: (users: User[]) => void;
  updateInvoices: (invoices: Invoice[]) => void;
  updateCurrentBillingCycle: (currentBillingCycle: CurrentBillingCycle) => void;
  updateOverview: (overview: AdminOverview) => void;
  updatePlan: (plan: Organization) => void;
  updateProjects: (projects: AdminProjectDetails[]) => void;
  updateSuperAdminInvoices: (invoices: Invoice[]) => void;
  updateSuperAdminOrganizations: (organizations: Organization[]) => void;
  updateSuperAdminOverview: (overview: SuperAdminOverview) => void;
  updateSuperAdminSelectedOrganization: (organization: Organization) => void;
  clearOrgLevelStore: () => void;
  clearStore: () => void;
}

const initialState: AdminState = {
  organization: null,
  users: null,
  projects: null,
  invoices: null,
  currentBillingCycle: null,
  overview: null,
  plan: null,
  superAdminInvoices: null,
  superAdminOrganizations: null,
  superAdminOverview: null,
  superAdminSelectedOrganization: null,
};

const useAdminStore = create<AdminState & AdminActions>((set) => ({
  ...initialState,

  updateOrganization: (organization: Organization) => {
    set({ organization });
  },

  updateUsers: (users: User[]) => {
    set({ users });
  },

  updateInvoices: (invoices: Invoice[]) => {
    set({ invoices });
  },

  updateCurrentBillingCycle: (
    currentBillingCycle: AdminState["currentBillingCycle"],
  ) => {
    set({ currentBillingCycle });
  },

  updateOverview: (overview: AdminState["overview"]) => {
    set({ overview });
  },

  updatePlan: (plan: Organization) => {
    set({ plan });
  },

  updateProjects: (projects: AdminProjectDetails[]) => {
    set({ projects });
  },

  updateSuperAdminInvoices: (invoices: Invoice[]) => {
    set({ superAdminInvoices: invoices });
  },

  updateSuperAdminOrganizations: (organizations: Organization[]) => {
    set({ superAdminOrganizations: organizations });
  },

  updateSuperAdminOverview: (overview: AdminState["superAdminOverview"]) => {
    set({ superAdminOverview: overview });
  },

  updateSuperAdminSelectedOrganization: (organization: Organization) => {
    set({ superAdminSelectedOrganization: organization });
  },

  clearStore: () => {
    set(initialState);
  },

  clearOrgLevelStore: () => {
    set((state) => ({
      ...state,
      users: null,
      projects: null,
      invoices: null,
      currentBillingCycle: null,
      overview: null,
      plan: null,
      superAdminSelectedOrganization: null,
    }));
  },
}));

export default useAdminStore;

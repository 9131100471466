/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "@/store";
import { ProjectPage, UploadFilesModal } from "@/components";
import { H4 } from "@/components/ui/typography";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AddReferencesToPortfolioModal } from "./components";
import DocumentsTable from "../references/components/DocumentsTable";
import { Patent, DocumentStatus, DocumentType } from "@/types";
import { usePortfolio, useProject, useVector, useViz } from "@/hooks";
import { useAppStateStore } from "@/store";
import { nanoid } from "nanoid";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { PlusIcon } from "@radix-ui/react-icons";

const PortfolioReferencesPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    currentPortfolio,
    updateCurrentPortfolio,
    currentPortfolioId,
    updateCurrentProjectId,
  } = useProjectStore();
  const { fetchProjectData } = useProject();
  const {
    updateProjectReferenceNickname,
    reprocessPatents,
    reprocessReferences,
    updateProjectReferenceNote,
  } = useViz();
  const { rerankReferences } = useVector();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
    Patent[]
  >([]);

  const { deleteReferenceFromPortfolio, updateReferencesTagsInPortfolio } =
    usePortfolio();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingMessage,
    removeLoadingMessage,
  } = useAppStateStore();
  const pageName = currentPortfolio.name
    ? `${currentPortfolio.name} - Portfolio - &AI`
    : "Portfolio - &AI";

  const handleTagUpdate = async (selectedRows: Patent[], selectedTags: string[]) => {
    if (selectedTags) {
      const referenceIds = selectedRows.map((row) => row.id);
      const response = await updateReferencesTagsInPortfolio(
        referenceIds,
        selectedTags,
      );
      if (!response.success) {
        addErrorMessage(
          response.message || "Failed to update tags for selected references.",
        );
      } else {
        addSuccessMessage("Tags updated successfully.");
      }
    }
  };

  const handleDeleteSelected = async (selectedReferences: Patent[]) => {
    const referenceIds = selectedReferences.map((reference) => reference.id);
    addLoadingMessage(
      `Removing ${selectedReferences.length} reference(s) from portfolio`,
      "delete-reference-portfolio",
    );
    try {
      await deleteReferenceFromPortfolio(
        referenceIds.map((referenceId) => ({
          referenceId,
          projectId: currentPortfolioId,
          isPortfolio: true,
        })),
      );
      removeLoadingMessage("delete-reference-portfolio");
      addSuccessMessage(
        `Successfully deleted ${selectedReferences.length} reference(s).`,
      );
    } catch (error) {
      removeLoadingMessage("delete-reference-portfolio");
      console.error(`Error deleting reference ${referenceIds}:`, error);
      addErrorMessage(
        "An unexpected error occurred while deleting references. Please try again.",
      );
    }
  };

  const handleSaveName = async (reference: Patent, newName: string) => {
    try {
      const renamePromises = reference.subjects.map(async (projectReference) => {
        const response = await updateProjectReferenceNickname(
          projectReference.projectId,
          reference.id,
          newName,
        );
        if (!response.success) {
          throw new Error(
            response.message || "An error occurred while saving the name.",
          );
        }
      });

      await Promise.all(renamePromises);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) =>
          ref.id === reference.id ? { ...ref, name: newName } : ref,
        ),
      });
    } catch (error) {
      addErrorMessage(error.message || "An error occurred while saving the name.");
    }
  };

  const handleSaveNote = async (reference: Patent, newNote: string) => {
    try {
      const renamePromises = reference.subjects.map(async (projectReference) => {
        const response = await updateProjectReferenceNote(
          projectReference.projectId,
          reference.id,
          newNote,
        );
        if (!response.success) {
          throw new Error(
            response.message || "An error occurred while saving the note.",
          );
        }
      });

      await Promise.all(renamePromises);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) =>
          ref.id === reference.id ? { ...ref, note: newNote } : ref,
        ),
      });
    } catch (error) {
      addErrorMessage(error.message || "An error occurred while saving the name.");
    }
  };

  const handleDeleteReference = async (reference: Patent) => {
    const ids = reference.subjects.map((subject) => ({
      referenceId: reference.id,
      projectId: subject.projectId,
    }));

    addLoadingMessage(
      `Removing reference ${reference.name || reference.referenceId} from portfolio`,
      "delete-reference",
    );

    const response = await deleteReferenceFromPortfolio(
      ids.map((id) => ({
        ...id,
        isPortfolio: true,
      })),
    );
    const failedDeletions = response.data?.failedDeletions || [];
    removeLoadingMessage("delete-reference");
    if (failedDeletions.length > 0) {
      addErrorMessage("Failed to remove reference from portfolio");
    } else {
      addSuccessMessage(
        `Reference ${reference.name || reference.referenceId} removed from portfolio`,
      );
      // Update the references state to remove the deleted reference
      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.filter(
          (ref) => ref.id !== reference.id,
        ),
      });
    }
  };
  const handleRowSelection = (selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  };

  const handleReprocessReferences = async () => {
    setShowProcessReferencesModal(false);
    const referencesToProcess = selectedReferencesToReprocess.filter(
      (reference) =>
        reference.status === DocumentStatus.RECHART ||
        reference.status === DocumentStatus.REPROCESS,
    );

    if (referencesToProcess.length === 0) {
      return;
    }

    const key = nanoid();
    const currentPortfolioName = currentPortfolio.name;

    try {
      addLoadingMessage(
        `Recharting references for project: ${currentPortfolioName}`,
        key,
      );

      const documentIds = referencesToProcess.map((reference) => reference.id);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((reference) => ({
          ...reference,
          status: documentIds.includes(reference.id)
            ? DocumentStatus.PENDING
            : reference.status,
        })),
      });

      const documentsToRerank = referencesToProcess
        .filter((reference) => reference.status === DocumentStatus.RECHART)
        .map((reference) => reference.id);

      const patentsToReprocess = referencesToProcess
        .filter(
          (reference) =>
            reference.status === DocumentStatus.REPROCESS &&
            reference.type === DocumentType.PATENT,
        )
        .map((reference) => reference.id);

      const referencesToReprocess = referencesToProcess
        .filter(
          (reference) =>
            reference.status === DocumentStatus.REPROCESS &&
            reference.type === DocumentType.REFERENCE,
        )
        .map((reference) => reference.id);

      const concurrentOperations = [
        documentsToRerank.length > 0
          ? rerankReferences(currentPortfolioId, documentsToRerank, true)
          : Promise.resolve(),
        patentsToReprocess.length > 0
          ? reprocessPatents(patentsToReprocess).then(() =>
              rerankReferences(currentPortfolioId, patentsToReprocess, true),
            )
          : Promise.resolve(),
        referencesToReprocess.length > 0
          ? reprocessReferences(referencesToReprocess).then(() =>
              rerankReferences(currentPortfolioId, referencesToReprocess, true),
            )
          : Promise.resolve(),
      ];

      await Promise.all(concurrentOperations);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((reference) => ({
          ...reference,
          status: documentIds.includes(reference.id)
            ? DocumentStatus.PROCESSED
            : reference.status,
        })),
      });
      addSuccessMessage(`References recharted for ${currentPortfolioName}`);
    } catch (error) {
      console.error("Error recharting references:", error);
      addErrorMessage(
        `Failed to rechart references for ${currentPortfolioName}. Please try again.`,
      );
    } finally {
      removeLoadingMessage(key);
      setSelectedReferencesToReprocess([]);
    }
  };

  const handleReprocessClick = (references: Patent[]) => {
    setSelectedReferencesToReprocess(references);
    setShowProcessReferencesModal(true);
  };

  const handleSelectProject = async (projectId: string) => {
    try {
      await fetchProjectData(projectId);
      updateCurrentProjectId(projectId);
      navigate(`/portfolio/${currentPortfolioId}/project/${projectId}/subject`);
    } catch (error) {
      console.error("Error selecting project:", error);
      addErrorMessage("Failed to fetch data. Please try again.");
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      {currentPortfolio.references && currentPortfolio.references.length > 0 ? (
        <DocumentsTable
          references={currentPortfolio.references}
          onDeleteRow={handleDeleteReference}
          height="calc(100vh - 100px)"
          onRowSelection={handleRowSelection}
          onDeleteSelected={handleDeleteSelected}
          enableRowDelete={true}
          onReprocessSelected={handleReprocessClick}
          isDeleteSelectedLoading={false}
          onTagUpdate={handleTagUpdate}
          onSaveName={handleSaveName}
          onSaveNote={handleSaveNote}
          isPortfolio={true}
          isReference={true}
          isSearch={false}
          onSelectProject={handleSelectProject}
        >
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="default" onClick={() => setDropdownOpen(true)}>
                Add References
                <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  setShowAddReferencesModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Published Patents/Applications
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setShowUploadModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Other Documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </DocumentsTable>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This portfolio has no references.</H4>

          <div className="flex flex-row gap-2">
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowAddReferencesModal(true)}
            >
              Add Published Patents/Applications
            </Button>
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowUploadModal(true)}
            >
              Upload Other Documents
            </Button>
          </div>
        </div>
      )}

      {/* Modals */}
      <AddReferencesToPortfolioModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
      />
      <UploadFilesModal
        open={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        isPortfolio={true}
      />
      <ProcessReferencesModal
        isOpen={showProcessReferencesModal}
        onOpenChange={setShowProcessReferencesModal}
        onConfirm={handleReprocessReferences}
        documentNames={selectedReferencesToReprocess
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map((reference) => reference.name)}
        actions={selectedReferencesToReprocess.map((reference) => reference.status)}
      />
    </ProjectPage>
  );
};

export default PortfolioReferencesPage;

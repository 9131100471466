/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useLlm, useVector, useViz } from "@/hooks";
import { useProjectStore, useAppStateStore, useProcessStore } from "@/store";
import { ProjectPage, Loader } from "@/components";
import {
  ExpertKnowledge,
  TermsAndDefinitions,
  ImportantFacts,
  PriorArtSearchInstructions,
  PriorArtFilterInstructions,
} from "./components/ContextSections";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { PatentContext, ProjectType, ProcessType, DocumentStatus } from "@/types";
import {
  BulkAddModal,
  ClearConfirmationModal,
  SearchResultsModal,
  GenerateContextModal,
} from "@/features/project/context/components/ContextModals";
import { nanoid } from "nanoid";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { useClearFeedbackDataByID } from "@/hooks/useFeedbackData";

const defaultContextData: PatentContext = {
  expertKnowledge: "",
  termsAndDefinitions: {},
  importantFacts: [],
  priorArtSearchInstructions: "",
  priorArtSearchFilterInstructions: "",
  relevantDocumentIds: [],
};

/**
 * @description Context page
 */
const ContextPage: React.FC = () => {
  // Hooks
  const { uploadFile } = useViz();
  const { rerankReferences, semanticSearchDocuments } = useVector();
  const {
    generatePatentContextFromId,
    generatePatentContextFromText,
    getPatentContext,
    savePatentContext,
  } = useLlm();
  const clearFeedbackDataForProject = useClearFeedbackDataByID();

  // Stores
  const { addProcess, removeProcess, areProcessesPending } = useProcessStore();
  const {
    currentProject,
    currentProjectId,
    currentSubject,
    currentPortfolioId,
    updateCurrentProject,
    updateCurrentPortfolio,
    currentPortfolio,
  } = useProjectStore();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingMessage,
    removeLoadingMessage,
  } = useAppStateStore();

  // Page name
  const pageName = currentProject.name
    ? `${currentProject.name} - Context - &AI`
    : "Context - &AI";

  // State
  const [contextData, setContextData] = useState<PatentContext>(
    currentProject.context ?? defaultContextData,
  );
  const [showReprocessDialog, setShowReprocessDialog] = useState(false);
  const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false);
  const [bulkAddType, setBulkAddType] = useState<"facts" | "terms">("facts");
  const [bulkAddContent, setBulkAddContent] = useState("");
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isGeneratingContext = areProcessesPending({
    types: [ProcessType.GENERATE_CONTEXT],
    projectId: currentProjectId,
  });

  const conflictingChartProcesses = [
    ProcessType.RECHART,
    ProcessType.GENERATE_CONTEXT,
    ProcessType.ADD_REFERENCE,
  ];
  const isRechartDisabled =
    areProcessesPending({
      types: conflictingChartProcesses,
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: conflictingChartProcesses,
      portfolioId: currentPortfolioId,
    });

  // Disable context actions if processes are pending
  const conflictingContextProcesses = [
    ProcessType.GENERATE_CONTEXT,
    ProcessType.RECHART,
  ];
  const isGenerateContextDisabled =
    areProcessesPending({
      types: conflictingContextProcesses,
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: conflictingContextProcesses,
      portfolioId: currentPortfolioId,
    });

  const isGeneratingContextInProgress =
    areProcessesPending({
      types: [ProcessType.GENERATE_CONTEXT],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [ProcessType.GENERATE_CONTEXT],
      portfolioId: currentPortfolioId,
    });

  const searchTerm = async (query: string) => {
    setSentSearchQuery(query);
    setShowSpinner(true);
    setIsSearchModalOpen(true);

    const response = await semanticSearchDocuments(
      currentProjectId,
      query,
      [currentSubject.id],
      "semantic",
    );

    if (response.success) {
      setSearchResults(response.data.sources[0]);
    } else {
      addErrorMessage("Error searching for term.");
    }
    setShowSpinner(false);
  };

  const handleSearchResultsClose = () => {
    setIsSearchModalOpen(false);
    setSearchResults([]);
    setSentSearchQuery("");
    setShowSpinner(false);
  };

  const isContextEmpty = () => {
    return (
      !contextData.expertKnowledge &&
      Object.keys(contextData.termsAndDefinitions)?.length === 0 &&
      contextData.importantFacts?.length === 0 &&
      !contextData.priorArtSearchInstructions &&
      !contextData.priorArtSearchFilterInstructions
    );
  };

  const handleClearConfirmation = () => {
    setShowClearConfirmation(true);
  };

  useEffect(() => {
    const fetchContextData = async () => {
      try {
        const response = await getPatentContext(currentProjectId);
        setContextData(response.data);
      } catch (error) {
        console.error("Error fetching patent context:", error);
        addErrorMessage("Failed to fetch patent context");
      }
    };
    if (!currentProject.context) {
      setIsLoading(true);
      fetchContextData();
      setIsLoading(false);
    }
  }, [currentProject.context]);

  const handleSave = async () => {
    try {
      await savePatentContext(currentProjectId, contextData);
      addSuccessMessage("Patent context saved successfully");
    } catch (error) {
      console.error("Error saving patent context:", error);
      addErrorMessage("Failed to save patent context. Please try again.");
    }
  };

  const handleClear = async () => {
    try {
      setContextData(defaultContextData);
      await savePatentContext(currentProjectId, defaultContextData);
      setIsEditing(false);
      addSuccessMessage("Patent context cleared successfully");
    } catch (error) {
      addErrorMessage("Failed to clear patent context. Please try again.");
    }
  };

  const handleBulkAdd = () => {
    const items = bulkAddContent.split("\n").filter((item) => item.trim() !== "");

    if (bulkAddType === "facts") {
      setContextData({
        ...contextData,
        importantFacts: [...contextData.importantFacts, ...items],
      });
    } else {
      const newTerms = { ...contextData.termsAndDefinitions };
      items.forEach((item) => {
        const [term, definition] = item.split(":");
        if (term && definition) {
          newTerms[term.trim()] = definition.trim();
        }
      });
      setContextData({
        ...contextData,
        termsAndDefinitions: newTerms,
      });
    }

    setBulkAddContent("");
    setIsBulkAddModalOpen(false);
  };

  const [isEditing, setIsEditing] = useState(false);
  // const [isGeneratingContext, setIsGeneratingContext] = useState(false);
  const [url, setUrl] = useState("");
  const [inputContext, setInputContext] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"url" | "document" | "input" | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const setContextDataFromPatentContext = (patentContext: PatentContext) => {
    setContextData({
      expertKnowledge:
        patentContext.expertKnowledge ?? defaultContextData.expertKnowledge,
      termsAndDefinitions:
        patentContext.termsAndDefinitions ?? defaultContextData.termsAndDefinitions,
      importantFacts: patentContext.importantFacts ?? defaultContextData.importantFacts,
      priorArtSearchInstructions:
        patentContext.priorArtSearchInstructions ??
        defaultContextData.priorArtSearchInstructions,
      priorArtSearchFilterInstructions:
        patentContext.priorArtSearchFilterInstructions ??
        defaultContextData.priorArtSearchFilterInstructions,
      relevantDocumentIds:
        patentContext.relevantDocumentIds ?? defaultContextData.relevantDocumentIds,
    });
  };

  const handleReprocessReferences = async () => {
    const key = nanoid();
    const currentProjectName = currentProject.name;

    try {
      addLoadingMessage(`Recharting references for ${currentProjectName}...`, key);
      setShowReprocessDialog(false);
      addProcess({
        id: key,
        type: ProcessType.RECHART,
        projectId: currentProjectId,
        portfolioId: currentPortfolioId,
      });
      await rerankReferences(currentProjectId);
      clearFeedbackDataForProject(currentProjectId);
      addSuccessMessage(`References recharted for ${currentProjectName}`);
    } catch (error) {
      console.error("Error reprocessing references:", error);
      addErrorMessage(
        `Failed to rechart references for ${currentProjectName}. Please try again.`,
      );
    } finally {
      removeProcess(key);
      removeLoadingMessage(key);
    }
  };

  const handleAddContext = async (source: string) => {
    const key = nanoid();

    try {
      let patentContext: PatentContext;
      switch (source) {
        case "subject-patent":
          addProcess({
            id: key,
            type: ProcessType.GENERATE_CONTEXT,
            projectId: currentProjectId,
            portfolioId: currentPortfolioId,
          });
          const patentResponse = await generatePatentContextFromId(
            currentProjectId,
            currentProject.subjectId,
          );
          patentContext = patentResponse.data;
          setContextDataFromPatentContext(patentContext);
          break;
        case "document":
          setModalType("document");
          setIsModalOpen(true);
          break;
        case "url":
          setModalType("url");
          setIsModalOpen(true);
          break;
        case "input":
          setModalType("input");
          setIsModalOpen(true);
          break;
      }
    } catch (error) {
      console.error("Error generating patent context:", error);
      addErrorMessage("Failed to generate patent context. Please try again.");
    } finally {
      removeProcess(key);
    }
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleUrlSubmit = () => {
    if (isValidUrl(url)) {
      setIsModalOpen(false);
      setUrl("");
    } else {
      addErrorMessage("Invalid URL. Please enter a valid URL.");
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
  };

  const handleFileUpload = async () => {
    const key = nanoid();
    addProcess({
      id: key,
      type: ProcessType.GENERATE_CONTEXT,
      projectId: currentProjectId,
      // portfolioId: currentPortfolioId,
    });
    try {
      setIsModalOpen(false);
      // setIsGeneratingContext(true);
      const response = await uploadFile(files[0]);
      const document_ids = response.data.map((file: any) => file.document_id);
      const patentContext = await generatePatentContextFromId(
        currentProjectId,
        document_ids[0],
      );
      setContextData(patentContext.data);
    } catch (error) {
      console.error("Error generating patent context:", error);
      addErrorMessage("Failed to generate patent context. Please try again.");
    } finally {
      setFiles([]);
      removeProcess(key);
      // setIsGeneratingContext(false);
    }
  };

  const handleInputSubmit = async () => {
    const key = nanoid();
    addProcess({
      id: key,
      type: ProcessType.GENERATE_CONTEXT,
      projectId: currentProjectId,
      // portfolioId: currentPortfolioId,
    });
    try {
      setIsModalOpen(false);
      const textResponse = await generatePatentContextFromText(
        currentProjectId,
        inputContext,
      );
      const patentContext: PatentContext = textResponse.data;
      setContextDataFromPatentContext(patentContext);
      setInputContext("");
    } catch (error) {
      console.error("Error generating patent context:", error);
      addErrorMessage("Failed to generate patent context. Please try again.");
    } finally {
      removeProcess(key);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSaveContext = () => {
    if (isEditing) {
      handleSave();
      updateCurrentProject({
        ...currentProject,
        references: currentProject.references.map((ref) => ({
          ...ref,
          status: DocumentStatus.RECHART,
        })),
      });
      if (currentPortfolio) {
        const projectDocumentIds = new Set(currentProject.documentIds);
        updateCurrentPortfolio({
          ...currentPortfolio,
          references: currentPortfolio.references.map((ref) => ({
            ...ref,
            status: projectDocumentIds.has(ref.id)
              ? DocumentStatus.RECHART
              : ref.status,
          })),
        });
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      <div className="flex flex-col h-[calc(100vh-60px)]">
        <div className="flex justify-between items-center p-2 bg-background z-10 border-b">
          <div className="gap-2 flex flex-row">
            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <DropdownMenuTrigger asChild>
                <Button
                  variant={isEditing || isGeneratingContext ? "outline" : "default"}
                  disabled={
                    isGeneratingContext || isEditing || isGenerateContextDisabled
                  }
                  className="h-9"
                  onClick={() => setDropdownOpen(true)}
                >
                  Generate Context
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {currentProject.type !== ProjectType.APP && (
                  <DropdownMenuItem
                    onClick={() => {
                      handleAddContext("subject-patent").catch(console.error);
                      setDropdownOpen(false);
                    }}
                  >
                    From subject patent
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    handleAddContext("document");
                    setDropdownOpen(false);
                  }}
                >
                  From document
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    handleAddContext("input");
                    setDropdownOpen(false);
                  }}
                >
                  Input context
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            {currentProject.references?.length > 0 && (
              <Button
                onClick={() => setShowReprocessDialog(true)}
                variant="outline"
                disabled={isGeneratingContext || isEditing || isRechartDisabled}
                className="h-9"
              >
                Rechart References
              </Button>
            )}
          </div>
          <div className="space-x-2">
            {isEditing && !isContextEmpty() && (
              <Button
                disabled={isGeneratingContext}
                onClick={handleClearConfirmation}
                variant="outline"
                className="h-9"
              >
                Clear
              </Button>
            )}
            <Button
              disabled={isGeneratingContext || isGenerateContextDisabled}
              onClick={handleSaveContext}
              className="h-9"
            >
              {isEditing ? "Save Changes" : "Edit Context"}
            </Button>
          </div>
        </div>
        <ScrollArea className="flex-grow">
          <div className="p-2">
            {isLoading ? (
              <div className="mt-10">
                <Loader />
              </div>
            ) : isGeneratingContext || isGeneratingContextInProgress ? (
              <div className="mt-10">
                <Loader message="Generating context..." />
              </div>
            ) : (
              <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:flex-wrap lg:gap-4">
                <div className="w-full lg:w-[calc(50%-8px)] space-y-3">
                  <ExpertKnowledge
                    value={contextData.expertKnowledge}
                    onChange={(value) =>
                      setContextData({ ...contextData, expertKnowledge: value })
                    }
                    isEditing={isEditing}
                  />
                  <TermsAndDefinitions
                    terms={contextData.termsAndDefinitions}
                    onChange={(terms) =>
                      setContextData({
                        ...contextData,
                        termsAndDefinitions: terms as Record<string, string>,
                      })
                    }
                    isEditing={isEditing}
                    onBulkAdd={() => {
                      setBulkAddType("terms");
                      setIsBulkAddModalOpen(true);
                    }}
                    searchTerm={searchTerm}
                    showSearchButton={currentProject.type !== ProjectType.APP}
                  />
                </div>
                <div className="w-full lg:w-[calc(50%-8px)] space-y-3">
                  <PriorArtSearchInstructions
                    instructions={contextData.priorArtSearchInstructions}
                    onChange={(instructions) =>
                      setContextData({
                        ...contextData,
                        priorArtSearchInstructions: instructions,
                      })
                    }
                    isEditing={isEditing}
                  />
                  <PriorArtFilterInstructions
                    instructions={contextData.priorArtSearchFilterInstructions}
                    onChange={(instructions) =>
                      setContextData({
                        ...contextData,
                        priorArtSearchFilterInstructions: instructions,
                      })
                    }
                    isEditing={isEditing}
                  />
                  <ImportantFacts
                    facts={contextData.importantFacts}
                    onChange={(facts) =>
                      setContextData({ ...contextData, importantFacts: facts })
                    }
                    isEditing={isEditing}
                    onBulkAdd={() => {
                      setBulkAddType("facts");
                      setIsBulkAddModalOpen(true);
                    }}
                    searchTerm={searchTerm}
                    showSearchButton={currentProject.type !== ProjectType.APP}
                  />
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
      </div>

      <GenerateContextModal
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
        modalType={modalType}
        url={url}
        setUrl={setUrl}
        files={files}
        handleFileChange={handleFileChange}
        handleRemoveFile={handleRemoveFile}
        inputContext={inputContext}
        setInputContext={setInputContext}
        handleUrlSubmit={handleUrlSubmit}
        handleFileUpload={handleFileUpload}
        handleInputSubmit={handleInputSubmit}
        isValidUrl={isValidUrl}
      />

      <ProcessReferencesModal
        isOpen={showReprocessDialog}
        onOpenChange={setShowReprocessDialog}
        onConfirm={handleReprocessReferences}
        actions={[DocumentStatus.RECHART]}
      />

      <BulkAddModal
        isOpen={isBulkAddModalOpen}
        onOpenChange={setIsBulkAddModalOpen}
        bulkAddType={bulkAddType}
        bulkAddContent={bulkAddContent}
        setBulkAddContent={setBulkAddContent}
        onConfirm={handleBulkAdd}
      />

      <ClearConfirmationModal
        isOpen={showClearConfirmation}
        onOpenChange={setShowClearConfirmation}
        onConfirm={handleClear}
      />

      <SearchResultsModal
        isOpen={isSearchModalOpen}
        onOpenChange={handleSearchResultsClose}
        searchResults={searchResults}
        sentSearchQuery={sentSearchQuery}
        showSpinner={showSpinner}
        currentProjectId={currentProjectId}
      />
    </ProjectPage>
  );
};

export default ContextPage;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { Patent } from "@/types";
import { Card } from "@/components/ui/card";
import { ReferenceImage } from "../index";
import { CondensedParagraph } from "@/components/ui/typography";
import ImageScrollBox from "@/components/images/ImageScrollBox";
import { Label } from "@/components/ui/label";

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface SubjectAbstractImagesProps {
  details: Patent;
}

const SubjectAbstractImages: React.FC<SubjectAbstractImagesProps> = ({ details }) => {
  const figureUrls = details.figureUrls || [];

  return (
    <div>
      {details.abstract && (
        <div className="mb-4">
          <Label>Abstract</Label>
          <CondensedParagraph>{details.abstract}</CondensedParagraph>
        </div>
      )}

      {figureUrls.length > 0 && (
        <div className="mb-4">
          <Label>Figures ({figureUrls.length})</Label>
          <ImageScrollBox>
            <div className="flex gap-2 p-2">
              {figureUrls.map((imageUrl) => (
                <Card
                  key={imageUrl}
                  className="shrink-0 hover:border-gray-400 inline-block"
                >
                  <ReferenceImage
                    imageUrl={imageUrl}
                    imageName={imageUrl.split("/").pop() || "Image"}
                  />
                </Card>
              ))}
            </div>
          </ImageScrollBox>
        </div>
      )}
    </div>
  );
};

export default SubjectAbstractImages;

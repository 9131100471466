/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useProjectStore } from "@/store";
import { Loader } from "@/components";
import { Button } from "@/components/ui/button";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { parseCommaSeparatedString, isLikelyPatentNumber } from "@/utils/dataUtils";

interface PatentNumbersTextFieldProps {
  isPortfolio: boolean;
  handleAdd: (refNums: string[]) => void;
}

const PatentNumbersTextField: React.FC<PatentNumbersTextFieldProps> = ({
  isPortfolio,
  handleAdd,
}) => {
  // Modal state
  const [patentNumbers, setPatentNumbers] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [invalidPatentNumbers, setInvalidPatentNumbers] = useState<string[]>([]);

  // Global store state
  const { currentPortfolio, currentProject } = useProjectStore();

  // Handle the add to project button click
  const handleAddClick = async () => {
    setIsLoading(true);
    const patentNumbersArray = parseCommaSeparatedString(patentNumbers);

    // Make sure the portfolio doesn't exceed the maximum number of patents
    if (
      isPortfolio &&
      currentPortfolio.projects.length + patentNumbersArray.length > 100
    ) {
      setError(
        "The maximum number of patents or applications that can be in a portfolio is 100.",
      );
      return;
    }

    if (
      !isPortfolio &&
      currentProject.referenceIds.length + patentNumbersArray.length > 100
    ) {
      setError(
        "The maximum number of patents or applications that can be in a project is 100.",
      );
      return;
    }

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    for (const patentNumber of patentNumbersArray) {
      if (!isLikelyPatentNumber(patentNumber)) {
        setInvalidPatentNumbers([...invalidPatentNumbers, patentNumber]);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }
    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setError("No valid patent or application numbers input.");
      setIsLoading(false);
      return;
    }

    handleAdd(validPatentNumbers);
    setIsLoading(false);
  };

  const handlePatentNumbersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPatentNumbers(e.target.value);
    if (e.target.value !== "") {
      setError("");
    }
    setInvalidPatentNumbers([]);
  };

  return (
    <div className="space-y-4">
      {isLoading ? (
        <Loader message="Adding patents..." />
      ) : (
        <>
          <div className="space-y-2">
            <AutosizeTextarea
              value={patentNumbers}
              onChange={(e) => handlePatentNumbersChange(e)}
              placeholder="US10952930, EP1095293A1, US20220183193A1"
              className={error ? "border-red-500" : ""}
              maxHeight={300}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
          <Button
            onClick={handleAddClick}
            disabled={patentNumbers === ""}
            className="w-full"
          >
            Add to {isPortfolio ? "Portfolio" : "Project"}
          </Button>
        </>
      )}
    </div>
  );
};

export default PatentNumbersTextField;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useViz, useProject, usePortfolio } from "@/hooks";
import { useAppStateStore } from "@/store";
import { SidebarPage, Loader } from "@/components";
import { H4 } from "@/components/ui/typography";
import { Reference } from "@/types";
import FilesTable from "./components/FilesTable";
import { useAuthInfo } from "@propelauth/react";

/**
 * @description User files page for uploaded files associated with the user
 */
const UserFilesPage: React.FC = () => {
  const user = useAuthInfo();
  const { getUserFiles, removeUserFiles } = useViz();
  const { deleteReferenceFromPortfolio } = usePortfolio();
  const { deleteReferencesFromProject } = useProject();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  const [references, setReferences] = useState<Reference[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<Reference[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserFiles = async () => {
      setIsLoading(true);
      const response = await getUserFiles();
      if (response.success) {
        setReferences(response.data);
      }
      setIsLoading(false);
      setHasLoaded(true);
    };
    if (!references.length && !hasLoaded) {
      fetchUserFiles();
    }
  }, [user]);

  const handleRemoveUserFiles = async (referencesToRemove: Reference[]) => {
    try {
      const removePromises: Promise<any>[] = [];

      for (const reference of referencesToRemove) {
        // Remove from projects
        if (reference.projects) {
          const ids = Object.keys(reference.projects);
          for (const id of ids) {
            if (reference.projects[id].type === "project") {
              removePromises.push(deleteReferencesFromProject(id, [reference.id]));
            } else if (reference.projects[id].type === "portfolio") {
              removePromises.push(
                deleteReferenceFromPortfolio([
                  { referenceId: reference.id, projectId: id, isPortfolio: true },
                ]),
              );
            }
          }
        }
      }
      await Promise.all(removePromises);

      // Optionally, also remove the user files
      const response = await removeUserFiles(
        referencesToRemove.map((reference) => reference.id),
      );

      if (response.success) {
        setReferences((prevReferences) =>
          prevReferences.filter((reference) => !referencesToRemove.includes(reference)),
        );
        addSuccessMessage("Successfully removed files");
      } else {
        addErrorMessage(response.error);
      }
    } catch (error) {
      addErrorMessage(
        (error as Error).message || "An error occurred while removing files",
      );
    }
  };

  const handleDeleteSelected = () => {
    handleRemoveUserFiles(selectedReferences);
  };

  const handleRowSelection = (selectedRows: Reference[]) => {
    setSelectedReferences(selectedRows);
  };

  return (
    <SidebarPage pageName="My Files">
      {isLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader />
        </div>
      ) : references?.length > 0 ? (
        <div className="pt-2  mb-2">
          <FilesTable
            references={references}
            height="calc(100vh - 60px)"
            onRowSelection={handleRowSelection}
            onDelete={handleRemoveUserFiles}
            onDeleteSelected={handleDeleteSelected}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>You have no uploaded files.</H4>
          <p>Files you upload to projects or portfolios will appear here.</p>
        </div>
      )}
    </SidebarPage>
  );
};

export default UserFilesPage;

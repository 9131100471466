/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useNavigate } from "react-router-dom";
import { useAppStateStore, useProjectStore, useCreateProjectStore } from "@/store";
import { useVector, useLlm, useViz, useApi, useProject } from ".";
import { ApiResponse, ParentType, ProcessType } from "@/types";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useCreateProject = () => {
  const { postRequest, handleError } = useApi();
  const {
    generateFeatures,
    generatePatentFromDisclosure,
    generateFeaturesFromClaims,
    generatePatentContextFromId,
  } = useLlm();
  const { addErrorMessage } = useAppStateStore();
  const { uploadToVDB } = useVector();
  const { getProjectMetadata, addToDocumentsAdded } = useProject();
  const { getUserProjects } = useViz();
  const {
    updateCurrentProjectId,
    updateCurrentParent,
    updateCurrentPortfolio,
    updateCurrentPortfolioId,
  } = useProjectStore();

  const {
    subjectNumbers,
    projectName,
    clientNumber,
    features,
    claims,
    abstract,
    subjectMode,
    disclosure_files,
    updateAbstract,
    updateProjectError,
    updateFeatures,
    updateClaims,
    updateSpinnerText,
  } = useCreateProjectStore();
  const navigate = useNavigate();

  const processType = ProcessType.CREATE_PROJECT;
  /**
   * Fetches and navigates to a project at the end of creation
   * @param {string} projectId - The id of the project to fetch
   */
  const fetchAndNavigateToProject = async (projectId: string) => {
    updateCurrentParent(ParentType.PROJECT);
    updateCurrentPortfolio(null);
    updateCurrentPortfolioId(null);
    updateCurrentProjectId(projectId);
    const response = await getProjectMetadata(projectId);
    const finalIds = [response.data.subject.id];
    await addToDocumentsAdded(projectId, finalIds, false);
    if (!response.success) {
      addErrorMessage(
        "Error fetching project data. Please navigate to project from home.",
      );
      navigate("/home");
      return;
    }
    navigate(`/project/${projectId}/subject`);
  };

  /**
   * @description Creates a project from element language
   * @param {string} projectType - The type of the project
   */
  const createProjectFromLanguage = async (
    projectType: string,
  ): Promise<ApiResponse> => {
    let currentClaims = claims;
    let currentAbstract = abstract;
    try {
      updateSpinnerText("Creating project...");

      // Generate features if not provided
      let featuresToSend: { [key: string]: string }[] = [];
      if (subjectMode === "disclosure") {
        const disclosureResponse = await generatePatentFromDisclosure(disclosure_files);
        if (disclosureResponse.success) {
          const formattedClaims = disclosureResponse.data.claims.map(
            (claim: string, index: number) => {
              return { [String(index + 1)]: claim };
            },
          );
          updateClaims(formattedClaims);
          currentClaims = formattedClaims;
          updateAbstract(disclosureResponse.data.abstract);
          currentAbstract = disclosureResponse.data.abstract;
        }
      }

      if (
        (subjectMode === "claim" || subjectMode === "disclosure") &&
        currentClaims.length > 0
      ) {
        const featuresResponse = await generateFeaturesFromClaims(currentClaims);
        if (featuresResponse.success) {
          featuresToSend =
            (featuresResponse.data as unknown as { [key: string]: string }[]) || [];
        }
      } else {
        featuresToSend = [];
      }

      // Create project and update state
      const res = await postRequest("post_create_project_from_language", {
        name: projectName,
        claims: subjectMode === "feature" ? [] : currentClaims,
        features: featuresToSend,
        client_number: clientNumber,
        type: projectType,
        abstract: currentAbstract || "",
        // cpc_codes: cpcCodes,
      });

      const response = res.data;
      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;
      const rFeatures = featuresToSend;

      await getUserProjects();

      // Fetch and navigate to project
      await fetchAndNavigateToProject(rProjectId);

      return { success: true, data: { rSubjectId, rProjectId, rFeatures } };
    } catch (error) {
      return handleError(error, "Error creating project from language");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} project_type - The type of the project
   * @returns Result object with success status and data or error message.
   */
  const createProjectFromNumber = async (projectType: string): Promise<ApiResponse> => {
    try {
      const res = await postRequest("post_create_project", {
        subject_number: subjectNumbers[0],
        name: projectName,
        type: projectType,
        client_number: clientNumber,
        cpc_codes: [],
        features: features,
      });
      const response = res.data;
      // const featuresResponse = await generateFeatures(response.subject_id);
      // if (featuresResponse.success) {
      //   updateFeatures(
      //     (featuresResponse.data as unknown as { [key: string]: string }[]) ||
      //       []
      //   );
      // }

      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;

      const documents = [rSubjectId];
      const uploadResponse = await uploadToVDB(rProjectId, documents, false, false);

      if (!uploadResponse.success) {
        // updateCreateProjectError(true);
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while creating this project. Try again later.",
        );
        // return;
      }

      await generatePatentContextFromId(rProjectId, rSubjectId);

      await fetchAndNavigateToProject(rProjectId);

      await getUserProjects();

      return {
        success: true,
      };
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} subjectNumbers - The numbers of the subject to create the project for
   * @param {boolean} getFeatures - Whether to get features for the subject
   * @returns Result object with success status and data or error message.
   */
  const getSubjectDetailsOnCreate = async (
    subjectNumbers: string[],
    getFeatures: boolean,
  ): Promise<any> => {
    try {
      // Create project in db
      const res = await postRequest("post_subject_details_on_create", {
        subject_numbers: subjectNumbers,
      });
      const response = res.data;

      if (getFeatures) {
        const firstResponse = response[0];
        const featuresResponse = await generateFeatures(firstResponse.id);
        let features: { [key: string]: string }[] = [];
        if (featuresResponse.success) {
          features =
            (featuresResponse.data as unknown as { [key: string]: string }[]) || [];
        }
        updateFeatures(features);

        return {
          success: true,
          data: [
            {
              ...response[0],
              features: features,
            },
          ],
        };
      } else {
        return { success: true, data: response };
      }
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  return {
    fetchAndNavigateToProject,
    createProjectFromLanguage,
    createProjectFromNumber,
    getSubjectDetailsOnCreate,
  };
};

export default useCreateProject;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Dayjs } from "dayjs";
import { ChatSession, ProjectUser } from "./project";

export enum RoleType {
  ADMIN = "Admin",
  OWNER = "Owner",
  MEMBER = "Member",
}

export interface Organization {
  organizationId?: string;
  organizationName?: string | null;
  isTesting?: boolean | null;
  passthroughBilling?: boolean | null;
  clientNumbers?: string[] | null;
  invoiceType?: "MONTHLY" | "YEARLY" | "NONE" | null;
  contractStart?: Dayjs | null;
  contractEnd?: Dayjs | null;
  monthlyLicensePrice?: number | null;
  monthlyProjectPrice?: number | null;
  yearlyLicensePrice?: number | null;
  monthlySeatPrice?: number | null;
  yearlySeatPrice?: number | null;
  invProjectPrice?: number | null;
  ftoProjectPrice?: number | null;
  sepProjectPrice?: number | null;
  roaProjectPrice?: number | null;
  appProjectPrice?: number | null;
  pfaProjectPrice?: number | null;
  invIncludedProjects?: number | null;
  ftoIncludedProjects?: number | null;
  sepIncludedProjects?: number | null;
  roaIncludedProjects?: number | null;
  appIncludedProjects?: number | null;
  maxSeats?: number | null;
  seats?: number | null;
  totalPrice?: number | null;
  documentPrice?: number | null;
  documentsAddedPrice?: number | null;
  documentsExportedPrice?: number | null;
  uniqueDocumentsPricing?: boolean | null;
  projects?: number | null;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: string;
  status?: string;
  enabled?: boolean;
  confirmed?: boolean;
  createdAt?: string;
  lastActiveAt?: string;
  files?: { [key: string]: string };
  chatSessions?: ChatSession[];
  organizationId?: string;
  organization?: Organization;
  projects?: ProjectUser[];
}

export interface Invoice {
  id: string;
  description: string;
  amountDue: number;
  created: string;
  status: string;
  currency: string;
  customerName: string;
  clientMatter: string;
  hostedInvoiceUrl: string;
  lines: InvoiceLine[];
}

export interface InvoiceLine {
  amount: number;
  description: string;
  quantity: number;
  unitAmount: number;
}

export interface AdminProjectDetails {
  id: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  name: string;
  numDocumentsAdded: number;
  numDocumentsExported: number;
  numUsers: number;
  portfolioId?: string;
  children?: AdminProjectDetails[];
}

export interface CurrentBillingCycle {
  balance: number;
  periodEnd: string;
  periodStart: string;
}

export interface AdminOverview {
  totalUsers: number;
  totalProjects: number;
  totalPortfolios: number;
  totalDocumentsProcessed: number;
  totalDocumentsExported: number;
  totalBalance: number;
}

export interface SuperAdminOverview {
  totalOrganizations: number;
  totalRevenue: number;
  totalBalance: number;
  totalUsers: number;
  totalProjects: number;
  totalDocumentsProcessed: number;
  totalDocumentsExported: number;
}

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { DocumentStatus } from "@/types";
interface ProcessReferencesModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  documentNames?: string[];
  actions?: DocumentStatus[];
}

/**
 * @description Process references modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {function} onConfirm - Function to confirm the modal.
 */
export const ProcessReferencesModal: React.FC<ProcessReferencesModalProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
  documentNames,
  actions,
}) => {
  const reprocess = actions?.includes(DocumentStatus.REPROCESS);
  const rechart = actions?.includes(DocumentStatus.RECHART);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>
            {reprocess && rechart
              ? "Reprocess and rechart references"
              : reprocess
                ? "Reprocess references"
                : rechart
                  ? "Rechart references"
                  : "No references selected are eligible for reprocessing"}
          </DialogTitle>
          {rechart || reprocess ? (
            <DialogDescription>
              This action will{" "}
              {reprocess ? "reprocess and " : "regenerate feature and claim charts"}{" "}
              {documentNames?.length ? `for the following references` : ""} with respect
              to the context. Any citations you have added or edited will be
              overwritten.
            </DialogDescription>
          ) : (
            <DialogDescription>
              You must select references with the following statuses to process:
              Rechart, Reprocess
            </DialogDescription>
          )}
        </DialogHeader>
        {documentNames?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {documentNames.map((name) => (
              <Badge variant="outline" key={name}>
                {name}
              </Badge>
            ))}
          </div>
        )}
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>{rechart || reprocess ? "Confirm" : "OK"}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

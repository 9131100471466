/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  useProjectStore,
  useAppStateStore,
  useProcessStore,
  useUserStore,
} from "@/store";
import { useViz, useDataTable } from "@/hooks";
import { ProjectPage, Loader } from "@/components";

import {
  ReferenceSelect,
  SummaryTable,
  ExportChartsModal,
  InvalidityTable,
  ElementSelect,
  PruneInvalidityColorModal,
  PruneInvalidityScoreModal,
  ColorSelect,
  EvaluationResults,
} from "./components";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { ElementType, ChartColor } from "@/types";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { H4 } from "@/components/ui/typography";
import { ProcessType } from "@/types";
/**
 * @description Renders the invalidity table page
 */
const ElementChartsPage: React.FC = () => {
  const { getReferenceMetadata, getEvaluation, getRemainingParagraphs } = useViz();
  const { getSummaryChartData, getReferenceChartData } = useDataTable();

  // Global state from store
  const {
    currentProjectId,
    currentPortfolioId,
    updateChartData,
    selectedReferences,
    updateSelectedReferences,
    currentProject,
    updateSelectedElements,
    currentSubject,
    selectedColors,
    selectedElementType,
    updateSelectedColors,
    updateSelectedElementType,
    selectedElements,
  } = useProjectStore();
  const referenceIds = useProjectStore((state) => state.currentProject.referenceIds);
  const { addErrorMessage, isStoreEmpty } = useAppStateStore();
  const { areProcessesPending } = useProcessStore();
  const { organizationName } = useUserStore();

  // Local state setup
  const navigate = useNavigate();
  const [showRefError, setShowRefError] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showPruneColorModal, setShowPruneColorModal] = useState(false);
  const [showPruneScoreModal, setShowPruneScoreModal] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [showEvaluationResults, setShowEvaluationResults] = useState(false);
  const [evaluationData, setEvaluationData] = useState<any>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isFilling, setIsFilling] = useState(false);
  const [previousElementType, setPreviousElementType] = useState<ElementType | null>(
    null,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Set page title
  const defaultProjectName = "Charts";
  const projectName = currentProject.name || defaultProjectName;
  const pageName = `${projectName} - Charts`;

  const isActionDisabled =
    areProcessesPending({
      types: [
        ProcessType.DOWNLOAD_CHART,
        ProcessType.ADD_REFERENCE,
        ProcessType.RECHART,
        ProcessType.GENERATE_CONTEXT,
      ],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [
        ProcessType.ADD_REFERENCE,
        ProcessType.RECHART,
        ProcessType.GENERATE_CONTEXT,
      ],
      portfolioId: currentPortfolioId,
    });

  const isRechartingInProgress =
    areProcessesPending({
      types: [ProcessType.RECHART],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [ProcessType.RECHART, ProcessType.GENERATE_CONTEXT],
      portfolioId: currentPortfolioId,
    });

  useEffect(() => {
    if (isStoreEmpty) updateSelectedElementType(ElementType.CLAIM);
  }, [isStoreEmpty]);

  useEffect(() => {
    updateSelectedReferences([]);
  }, []);

  // on load clear the selected reference details
  useEffect(() => {
    updateSelectedElements([]);
    if (currentProjectId && selectedElementType) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [selectedElementType]);

  // Fetches the summary chart data
  const fetchSummaryChartData = async (type: string) => {
    const response = await getSummaryChartData(currentProjectId, type);
    if (!response.success) {
      setShowRefError(true);
      addErrorMessage(`Error fetching summary chart data`);
    }
  };

  // Fetches the reference chart data
  const fetchReferenceChartData = async (
    referenceIds: string[],
    type: string,
    subset: string[] = [],
    colors: string[] = [],
  ) => {
    const response = await getReferenceChartData(
      currentProjectId,
      referenceIds,
      type === ElementType.FEATURE,
      subset,
      colors,
    );

    if (!response.success) {
      setShowRefError(true);
      addErrorMessage(`Error fetching reference chart data`);
    }
  };

  // Refreshes the reference data
  const refreshReferenceData = async (
    referenceIds: string[],
    subset?: string[],
    colors?: string[],
  ) => {
    if (referenceIds === null || referenceIds.length === 0) {
      updateChartData([]);
      updateSelectedReferences([]);
    } else {
      await fetchReferenceChartData(referenceIds, selectedElementType, subset, colors);
      await getReferenceMetadata(referenceIds);
    }
  };

  const fetchElementChartPageData = useCallback(async () => {
    await fetchSummaryChartData(selectedElementType);
    if (selectedReferences && selectedReferences.length > 0) {
      const refIds = selectedReferences.map((ref) => ref.id);
      await fetchReferenceChartData(
        refIds,
        selectedElementType,
        selectedElements,
        selectedColors,
      );
    }
  }, [
    fetchSummaryChartData,
    fetchReferenceChartData,
    selectedElementType,
    selectedReferences,
    selectedElements,
    selectedColors,
  ]);

  const handleTypeSelect = useCallback(
    async (newType: string | null) => {
      if (newType !== selectedElementType && newType !== null && newType !== "") {
        updateSelectedElementType(
          newType === ElementType.CLAIM ? ElementType.CLAIM : ElementType.FEATURE,
        );
        let labels = [];
        if (newType === ElementType.CLAIM) {
          labels =
            currentSubject?.claims && currentSubject.claims.length > 0
              ? currentSubject.claims.map((claim) => Object.keys(claim)[0])
              : [];
        } else if (newType === ElementType.FEATURE) {
          labels =
            currentSubject?.features && currentSubject.features.length > 0
              ? currentSubject.features.map((feature) => Object.keys(feature)[0])
              : [];
        }
        updateSelectedElements(labels);
        await fetchSummaryChartData(newType);
        if (selectedReferences && selectedReferences.length > 0) {
          const refIds = selectedReferences.map((ref) => ref.id);
          await fetchReferenceChartData(refIds, newType, labels, selectedColors);
        }
      }
    },
    [
      selectedElementType,
      selectedReferences,
      fetchSummaryChartData,
      fetchReferenceChartData,
    ],
  );

  const handleReferenceSelect = useCallback(
    async (referenceIds: string[]) => {
      await refreshReferenceData(referenceIds, selectedElements, selectedColors);
    },
    [selectedElementType, selectedColors, selectedElements, refreshReferenceData],
  );

  const handleDataTableRefresh = async () => {
    const refIds = selectedReferences.map((ref) => ref.id);
    await refreshReferenceData(refIds, selectedElements, selectedColors);
  };

  const handleColorSelect = useCallback(
    async (colors: string[]) => {
      if (selectedReferences.length > 0) {
        updateSelectedColors(colors);
        const refIds = selectedReferences.map((ref) => ref.id);
        await refreshReferenceData(refIds, selectedElements, selectedColors);
      } else {
        updateSelectedColors(colors);
      }
    },
    [
      selectedElementType,
      selectedReferences,
      selectedElements,
      fetchReferenceChartData,
    ],
  );

  const handleClaimSelect = useCallback(
    async (claimIds: string[]) => {
      if (selectedReferences.length > 0) {
        const refIds = selectedReferences.map((ref) => ref.id);
        updateSelectedElements(claimIds);
        await refreshReferenceData(refIds, claimIds, selectedColors);
      } else {
        updateSelectedElements(claimIds);
      }
    },
    [selectedElementType, selectedReferences, selectedColors, refreshReferenceData],
  );

  // Selects the chart display
  const selectChartDisplay = useCallback(
    async (isExpanded: boolean, claimNumber: string | null, key: string) => {
      const refIds = [key];
      const elements = claimNumber ? [claimNumber] : [];

      if (isExpanded) {
        updateSelectedElements([elements]);
        await refreshReferenceData(refIds, elements);
      } else {
        updateSelectedElements([]);
        await refreshReferenceData(refIds, []);
      }
    },
    [selectedElementType, updateSelectedElements, refreshReferenceData],
  );

  const handleReferencesNavigate = () => {
    navigate(`/project/${currentProjectId}/references`);
  };

  // const handleClickAddNew = (event: MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePruneMenuClick = (pruneType: "color" | "score") => {
    handleCloseMenu();
    if (pruneType === "color") {
      setShowPruneColorModal(true);
    } else {
      setShowPruneScoreModal(true);
    }
  };

  const handleFillStart = useCallback(() => {
    setIsFilling(true);
  }, []);

  const handleFillEnd = useCallback(() => {
    setIsFilling(false);
  }, []);

  const handleEvaluate = useCallback(async () => {
    // console.log("project id", currentProjectId);
    // console.log("reference ids", referenceIds);
    try {
      setIsEvaluating(true);
      setPreviousElementType(selectedElementType);
      updateSelectedReferences([]);
      updateSelectedElementType(ElementType.EVALUATE);
      const patent_number = currentSubject.number;
      const response = await getEvaluation(
        currentProjectId,
        referenceIds,
        patent_number,
      );
      if (response.success) {
        setEvaluationData(response.data);
        setShowEvaluationResults(true);
      } else {
        throw new Error("Failed to fetch evaluation data");
      }
    } catch (error) {
      addErrorMessage("Failed to fetch evaluation data");
    } finally {
      setIsEvaluating(false);
    }
  }, [currentProjectId, getEvaluation, addErrorMessage, selectedElementType]);

  const handleCloseEvaluation = useCallback(() => {
    setShowEvaluationResults(false);
    setEvaluationData(null);
    if (previousElementType) {
      updateSelectedElementType(previousElementType);
      setPreviousElementType(null);
    }
    fetchSummaryChartData(previousElementType);
    if (selectedReferences.length > 0) {
      const refIds = selectedReferences.map((ref) => ref.id);
      fetchReferenceChartData(
        refIds,
        previousElementType,
        selectedElements,
        selectedColors,
      );
    } else {
      updateChartData([]); // Reset chart data if no references are selected
    }
  }, [
    previousElementType,
    selectedReferences,
    selectedElements,
    selectedColors,
    fetchSummaryChartData,
    fetchReferenceChartData,
    updateChartData,
  ]);

  //refresh charts after prune
  useEffect(() => {
    if (!showPruneColorModal) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [showPruneColorModal]);

  useEffect(() => {
    if (!showPruneScoreModal) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [showPruneScoreModal]);

  useEffect(() => {
    if (selectedColors.length > 0) {
      handleColorSelect(selectedColors);
    }
  }, [selectedColors]);

  return (
    <ProjectPage pageName={pageName}>
      {isRechartingInProgress && referenceIds.length > 0 ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Processing references..." />
        </div>
      ) : (
        <ScrollArea className="px-3 h-[calc(100vh-60px)]">
          {referenceIds && referenceIds.length > 0 ? (
            <div className="space-y-2">
              <div className="w-full">
                <SummaryTable
                  handleSelect={selectChartDisplay}
                  isExpanded={isSummaryExpanded}
                  onFillStart={handleFillStart}
                  onFillEnd={handleFillEnd}
                />
              </div>
              <Separator className="my-2" />
              <div className="flex flex-wrap gap-2 items-center">
                <div className="flex items-center gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                          className="h-9 w-9 flex-shrink-0"
                        >
                          {isSummaryExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {isSummaryExpanded ? (
                          <p>Collapse Summary</p>
                        ) : (
                          <p>Expand Summary</p>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <ToggleGroup
                    className="h-9"
                    type="single"
                    value={selectedElementType}
                    onValueChange={(value) => handleTypeSelect(value)}
                  >
                    <ToggleGroupItem
                      value={ElementType.CLAIM}
                      disabled={
                        !currentProject.claims || currentProject.claims.length === 0
                      }
                      className={cn(
                        "flex-1",
                        selectedElementType === ElementType.CLAIM
                          ? "bg-tertiary text-primary-foreground"
                          : "",
                      )}
                    >
                      Claims
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      value={ElementType.FEATURE}
                      disabled={
                        !currentProject.features || currentProject.features.length === 0
                      }
                      className={cn(
                        "flex-1",
                        selectedElementType === ElementType.FEATURE
                          ? "bg-tertiary text-primary-foreground"
                          : "",
                      )}
                    >
                      Features
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
                {organizationName && organizationName.includes("AndAI") && (
                  <Button onClick={handleEvaluate}>Evaluate</Button>
                )}
                <ReferenceSelect
                  handleReferenceSelect={handleReferenceSelect}
                  className="h-9"
                />
                <ElementSelect
                  onElementSelect={handleClaimSelect}
                  elementType={selectedElementType}
                  className="h-9"
                />
                <ColorSelect
                  colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
                  selectedColors={selectedColors}
                  setSelectedColors={updateSelectedColors}
                  className="h-9"
                />
                <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      className="h-9"
                      disabled={isActionDisabled}
                      onClick={() => setDropdownOpen(true)}
                    >
                      Prune
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onSelect={() => {
                        handlePruneMenuClick("color");
                        setDropdownOpen(false);
                      }}
                    >
                      Prune by Color
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() => {
                        handlePruneMenuClick("score");
                        setDropdownOpen(false);
                      }}
                    >
                      Prune by Score
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Button
                  onClick={() => setShowExportModal(true)}
                  disabled={isActionDisabled}
                  className="h-9"
                >
                  Download
                </Button>
              </div>
              <div className="chart-container">
                {selectedElementType === ElementType.EVALUATE ? (
                  isEvaluating ? (
                    <Loader message="Evaluating...This may take a few seconds" />
                  ) : (
                    <EvaluationResults
                      data={evaluationData}
                      onClose={handleCloseEvaluation}
                    />
                  )
                ) : isFilling ? (
                  <Loader message="Filling...This may take a few seconds" />
                ) : selectedReferences.length > 0 ? (
                  <div className="w-full overflow-x-auto">
                    <InvalidityTable fetchReferenceChartData={handleDataTableRefresh} />
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-12">
                    <p className="text-center">Select reference(s) to view chart</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col mt-12">
              <H4>This project has no references.</H4>

              <Button
                className="mt-3"
                variant="outline"
                onClick={handleReferencesNavigate}
              >
                Add references
              </Button>
            </div>
          )}
        </ScrollArea>
      )}

      <ExportChartsModal
        open={showExportModal}
        handleClose={() => setShowExportModal(false)}
      />
      <PruneInvalidityColorModal
        open={showPruneColorModal}
        onOpenChange={setShowPruneColorModal}
        fetchElementChartPageData={fetchElementChartPageData}
      />
      <PruneInvalidityScoreModal
        open={showPruneScoreModal}
        handleClose={() => setShowPruneScoreModal(false)}
        fetchElementChartPageData={fetchElementChartPageData}
      />
    </ProjectPage>
  );
};

export default ElementChartsPage;

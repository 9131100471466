import { ChartColor } from "@/types";

/**
 * @param {string} subjectId - the subjectId to be formatted
 * @description Formats the subjectId based on its length
 */
export const splitId = (subjectId: string) => {
  // if the subjectId is "No Reference Documents" return "No Reference Documents"
  if (!subjectId || subjectId === "No Reference Documents") {
    return subjectId;
  }

  // If the subjectId is less than 3 characters, return the subjectId as is
  if (subjectId.length < 3) {
    return subjectId;
  }

  // if the subject id ends with a file extension, return it as is
  if (subjectId.endsWith(".pdf")) {
    return subjectId;
  }

  // Check if the first two characters are letters
  let prefix = "";
  if (/^[A-Za-z]{2}/.test(subjectId)) {
    prefix = subjectId.slice(0, 2); // Extract first two letters as prefix
    subjectId = subjectId.slice(2); // Remove the extracted prefix from the original subjectId
  }

  let withoutPrefix = subjectId;

  // Check and remove the suffix 'A1' or 'B2' if present
  let suffix = "";
  if (withoutPrefix.endsWith("A1") || withoutPrefix.endsWith("B2")) {
    suffix = withoutPrefix.slice(-2);
    withoutPrefix = withoutPrefix.slice(0, -2);
  }

  // Formatting the number part
  let formattedNumber;
  if (withoutPrefix.length <= 8) {
    // Insert commas for shorter format
    formattedNumber = withoutPrefix.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    // Insert a slash after the fourth character for longer format
    formattedNumber = withoutPrefix.slice(0, 4) + "/" + withoutPrefix.slice(4);
  }

  // Reassemble the full formatted ID with prefix and suffix if they were removed
  return `${prefix} ${formattedNumber} ${suffix}`;
};

/**
 * @param {array} data - The data to get the column headers from
 * @returns {array} - The column headers
 */
export const getColumnHeaders = (data: any[]) => {
  const headers: Set<string> = new Set();
  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key !== "claim_number") {
        headers.add(key);
      }
    });
  });
  return Array.from(headers);
};

/**
 * @param {string} id - The id to get the shortened number from
 * @returns {string} - The shortened number
 */
export const getShortenedNum = (id: string) => {
  if (!id) {
    return null;
  }

  // if id ends in pdf, remove pdf and return the first 5 characters + "...pdf"
  if (id.endsWith(".pdf")) {
    return id.length < 8 ? id : id.slice(0, 5) + "...pdf";
  }

  // Match the last 3 digits before any trailing letters and optional letter-number combination
  const match = id.match(/\d{3}(?=[A-Z]*(?:[A-Z]\d)?$)/);
  return match ? `'${match[0]}` : null;
};

/**
 * @param {string} bgColor - The background color to get the text color from
 * @returns {string} - The text color
 */
export const getColorForText = (bgColor: string) => {
  switch (bgColor) {
    case ChartColor.GREEN:
      return "#1A3300";
    case ChartColor.YELLOW:
      return "#4B3700";
    case ChartColor.RED:
      return "#4D0000";
    default:
      return "black";
  }
};

export const colorRank: { [key: string]: number } = {
  [ChartColor.GREEN]: 4,
  [ChartColor.YELLOW]: 3,
  [ChartColor.RED]: 2,
  [ChartColor.GRAY]: 1,
};

/**
 * @param {array} colors - The colors to get the highest rank from
 * @returns {string} - The highest rank
 */
export const getHighestRank = (colors: string[]) => {
  const num = Math.max(...colors.map((color) => colorRank[color] || 0));
  return Object.keys(colorRank).find((key) => colorRank[key] === num);
};

/**
 * @param {number} width - The width of the image
 * @param {number} height - The height of the image
 * @param {number} rotationAngle - The rotation angle
 * @returns {object} - The new width and height
 */
export const calculateRotatedImageSize = (
  width: number,
  height: number,
  rotationAngle: number,
) => {
  const radians = (rotationAngle * Math.PI) / 180;
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);

  // Calculate the new bounding box width and height
  const newWidth = Math.abs(width * cos) + Math.abs(height * sin);
  const newHeight = Math.abs(width * sin) + Math.abs(height * cos);

  return { width: newWidth, height: newHeight };
};

/**
 * Chunks an array into smaller arrays, helper function for processReferences
 * @param {string[]} array - The array to chunk
 * @param {number} chunkSize - The size of the chunk to process
 * @returns An array of chunks
 */
export const chunkProjects = (projects: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < projects.length; i += chunkSize) {
    chunks.push(projects.slice(i, i + chunkSize));
  }
  return chunks;
};

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, Dispatch, SetStateAction } from "react";
import { ElementEditStep, CreateStepGroup } from ".";
import { useCreateProjectStore } from "@/store";
import { ElementType } from "@/types";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import UploadFileModal from "@/components/files/UploadFileModal";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks";

/**
 * @description Subject step in create application project
 * @param {boolean} startExpanded - Whether the step is expanded.
 * @param {Dispatch<SetStateAction<File | null>>} onFileUpload - Function to handle file upload.
 */
const ApplicationSubjectStep: React.FC<{
  startExpanded: boolean;
  onFileUpload: Dispatch<SetStateAction<File | null>>;
}> = ({ startExpanded, onFileUpload }) => {
  const {
    updateSubjectMode,
    subjectMode,
    claims,
    features,
    updateClaims,
    updateFeatures,
    addFile,
    disclosure_files,
    updateDisclosureFiles,
    files,
  } = useCreateProjectStore();

  const infoPopoverContent =
    "If claims are provided, features will be generated automatically. If features are provided, claims can be added manually after project creation.";

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleContextAddClick = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newDisclosureFiles = [...disclosure_files, ...selectedFiles];
      updateDisclosureFiles(newDisclosureFiles);
      selectedFiles.forEach((file) => {
        addFile(file);
        onFileUpload(file);
      });
    }
  };

  // Compute isCompleted based on the current subjectMode and state
  const isStepCompleted = () => {
    if (subjectMode === "disclosure") {
      return disclosure_files.length > 0;
    } else if (subjectMode === "claim") {
      return (
        claims.length > 0 && JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })
      );
    } else if (subjectMode === "feature") {
      return (
        features.length > 0 &&
        JSON.stringify(features[0]) !== JSON.stringify({ "1": "" })
      );
    } else {
      return false;
    }
  };

  return (
    <CreateStepGroup
      stepName="Application Language"
      isCompleted={isStepCompleted()}
      startExpanded={startExpanded}
      infoPopoverContent={infoPopoverContent}
    >
      <div className="space-y-4">
        <RadioGroup
          defaultValue={subjectMode}
          onValueChange={(value) =>
            updateSubjectMode(value as "claim" | "feature" | "disclosure")
          }
          className="flex flex-wrap gap-4"
        >
          <RadioGroupWithText
            value="disclosure"
            id="disclosure"
            label="Extract from invention disclosure"
          />
          <RadioGroupWithText value="claim" id="claim" label="Enter claim language" />
          <RadioGroupWithText
            value="feature"
            id="feature"
            label="Enter feature language"
          />
        </RadioGroup>

        {subjectMode === "disclosure" && (
          <div className="space-y-4">
            <div>
              <Label htmlFor="disclosure">Invention Disclosure</Label>
            </div>
            <div>
              <Button onClick={handleOpenUploadModal}>Upload Files</Button>
              <UploadFileModal
                open={isUploadModalOpen}
                handleClose={handleCloseUploadModal}
                inContext={true}
                handleContextAddClick={handleContextAddClick}
              />
            </div>
            {disclosure_files.length > 0 && (
              <div>
                <Label>Uploaded Files:</Label>
                <ul>
                  {disclosure_files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {subjectMode === "feature" && (
          <ElementEditStep
            itemType={ElementType.FEATURE}
            setItems={updateFeatures}
            startInEditMode={true}
            initialItems={features}
            isUpdateMode={false}
            showCollapse={false}
          />
        )}

        {subjectMode === "claim" && (
          <ElementEditStep
            itemType={ElementType.CLAIM}
            setItems={updateClaims}
            startInEditMode={true}
            initialItems={claims}
            isUpdateMode={false}
            showCollapse={false}
          />
        )}
      </div>
    </CreateStepGroup>
  );
};

export default ApplicationSubjectStep;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useProjectStore } from "@/store";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
} from "@/components/ui/navigation-menu";
import { ParentType } from "@/types";

/**
 * @description Component for navigation tabs in invalidity project
 */
const PortfolioNavigationTabs: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Global state from store
  const { clearChartData, currentPortfolioId, currentParent } = useProjectStore();

  const getTabValue = (pathname: string): string => {
    if (pathname.includes("/subjects")) {
      return "subjects";
    } else if (pathname.includes("/references")) {
      return "references";
    }
  };

  const currentTabValue = getTabValue(location.pathname);

  // Handle nav item selection
  const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
    clearChartData();
    let path = "";
    if (currentParent === ParentType.PORTFOLIO) {
      path = `/portfolio/${currentPortfolioId}`;
    }
    switch (newValue) {
      case "subjects":
        navigate(`${path}/subjects`);
        break;
      case "references":
        navigate(`${path}/references`);
        break;
      default:
        break;
    }
  };

  // Render tab label
  const renderNavItem = (text: string, value: string) => (
    <NavigationMenuItem
      className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
        currentTabValue === value
          ? "bg-accent text-accent-foreground rounded-full"
          : "text-muted-foreground"
      }`}
      onClick={(event) => {
        if (currentPortfolioId) {
          handleTabChange(event, value);
        }
      }}
    >
      {text}
    </NavigationMenuItem>
  );

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {renderNavItem("Subjects", "subjects")}
        {renderNavItem("References", "references")}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default memo(PortfolioNavigationTabs);

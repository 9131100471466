/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore, useAppStateStore, useProcessStore } from "@/store";
import { useApi, useViz } from "@/hooks";
import { InvalidityCitation, ElementType, ChartColor, ApiResponse } from "@/types";
import { getHighestRank, getColumnHeaders } from "@/utils/projectUtils";
import { toCamelCase } from "@/utils/dataUtils";
import { nanoid } from "nanoid";
import { ProcessType } from "@/types";

export const useDataTable = () => {
  const { postRequest, getRequest, handleError } = useApi();
  const { uploadImageToS3 } = useViz();
  const {
    currentProjectId,
    currentPortfolioId,
    currentProject,
    chartData,
    summaryChartData,
    selectedElementType,
    updateChartData,
    updateSummaryChartData,
    updateSummaryChartHeaders,
    updateSummaryChartRowHeightCollapsed,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { addProcess, removeProcess } = useProcessStore();

  /**
   * @description Fetches the summary chart data
   * @param {string} projectId - The id of the project to fetch the summary chart data for
   * @param {string} type - The type of the summary chart data to fetch
   */
  const getSummaryChartData = async (
    projectId: string,
    type: string,
  ): Promise<ApiResponse> => {
    try {
      if (!projectId) {
        return { success: false, data: [], status: 400 };
      }
      let response;
      if (type === "claim") {
        response = await getRequest("get_summary_chart_data", {
          project_id: projectId,
        });
      } else {
        response = await getRequest("get_feature_summary_data", {
          project_id: projectId,
        });
      }
      const summaryData = response.data;
      const filteredSummaryData = summaryData.filter(
        (row) => row.claim_number !== "Preamble",
      );
      updateSummaryChartHeaders(getColumnHeaders(filteredSummaryData));
      const totalContainerHeight = 250 - 60;
      const numRows = filteredSummaryData.length;
      const rowHeightCollapsed = totalContainerHeight / numRows;
      updateSummaryChartRowHeightCollapsed(rowHeightCollapsed);
      updateSummaryChartData(filteredSummaryData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching summary chart data");
    }
  };

  /**
   * @description Fetches the reference chart data
   * @param {string} projectId - The id of the project to fetch the reference chart data for
   * @param {array} referenceIds - The ids of the references to fetch the chart data for
   * @param {string} type - The type of the reference chart data to fetch
   * @param {array} subset - The subset of the reference chart data to fetch
   */
  const getReferenceChartData = async (
    projectId: string,
    referenceIds: string[],
    is_feature: boolean,
    subset: string[],
    colors: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_reference_chart_data", {
        project_id: projectId,
        reference_ids: referenceIds,
        is_feature: is_feature,
        subset: subset,
        colors: colors,
      });
      const tempChartData = response.data;
      const filteredChartData = tempChartData.filter(
        (row) => row.claim_number !== "Preamble",
      );
      updateChartData(filteredChartData);
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference chart data");
    }
  };

  /**
   * @description Updates the language of a reference citation
   * @param {string} citationId - The id of the citation to update the language for
   * @param {object} options - Additional options to pass to the request
   */
  const updateReferenceCitation = async (
    citationId: string,
    options: { [key: string]: any } = {},
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      citation_id: citationId,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_reference_citation", payload);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating citation language");
    }
  };

  /**
   * @description Adds a reference citation to the project
   * @param {string} invalidityId - The id of the invalidity to add the citation to
   * @param {string} organizationId - The id of the organization to add the citation to
   * @param {string} color - The color of the citation to add
   * @param {string} text - The text of the citation to add
   * @param {string} paragraph - The paragraph of the citation to add
   * @param {array} figureRefs - The references of the figures to add to the citation
   * @param {array} figureUrls - The URLs of the figures to add to the citation
   */
  const addReferenceCitation = async (
    invalidityId: string,
    referenceId: string,
    color: string,
    text: string,
    paragraph: string,
    projectId: string,
    isFeature: boolean,
    figureUrls: string[],
    figureRefs: string[],
    claimNumber: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_reference_citation", {
        invalidity_id: invalidityId,
        color: color,
        text: text,
        paragraph: paragraph,
        figure_urls: figureUrls,
        figure_refs: figureRefs,
        score: null,
        project_id: projectId,
        is_feature: isFeature,
        document_id: referenceId,
        claim_number: claimNumber,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding reference citation");
    }
  };

  /**
   * @description Deletes a reference citation from a project
   * @param {string} invalidityCitationId - The id of the invalidity citation to delete
   */
  const deleteReferenceCitation = async (
    invalidityCitationId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_reference_citation", {
        invalidity_citation_id: invalidityCitationId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting reference citation");
    }
  };

  /**
   * @description Adds a new citation to the chart data
   * @param {number} index - the index of the row to add the citation to
   * @param {string} referenceId - the id of the reference to add the citation to
   */
  const handleAddCitation = (
    index: number,
    referenceId: string,
    setIsEditing: (isEditing: boolean) => void,
    setAddingCitationKey: (key: string) => void,
  ) => {
    setIsEditing(true);
    const invalidityId = chartData[index][referenceId].invalidityId;
    const newCitation = {
      paragraph: "",
      text: "",
      color: ChartColor.GREEN,
      figureUrls: [],
      invalidityId: invalidityId,
    };
    const newChartData = [...chartData];
    const refCitationArray = [...newChartData[index][referenceId].citations];
    const nextIndex = refCitationArray.length;
    refCitationArray.push(newCitation);
    newChartData[index][referenceId].citations = refCitationArray;
    const newCitationKey = `${index}-${referenceId}-${nextIndex}`;
    setAddingCitationKey(newCitationKey);
    updateChartData(newChartData);
  };

  /**
   * @description Adds an image to a citation
   * @param {number} index - the index of the row to add the image to
   * @param {string} referenceId - the id of the reference to add the image to
   * @param {number} citationIndex - the index of the citation to add the image to
   * @param {File} file - the file to add to the citation
   * @param {InvalidityCitation} citation - the citation to add the image to
   */
  const handleAddImageToCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    file: File,
    citation: InvalidityCitation,
  ) => {
    const response = await uploadImageToS3(file);
    const imageUrl = response.data.url;

    const attachResponse = await handleAttachReferenceFiguresToCitation(
      index,
      referenceId,
      citationIndex,
      [imageUrl],
      citation,
    );
    if (!attachResponse) {
      addErrorMessage(
        `Error adding image to citation ${citationIndex} from project ${currentProject.name} `,
      );
    }
  };

  const getElementChartExport = async (
    projectId: string,
    referenceIds: string[],
    includeFeatures: boolean,
    includeClaims: boolean,
    includeSummary: boolean,
    introductionLanguage: string,
    colors: string[],
    features: string[],
    claims: string[],
    exportType: string,
  ): Promise<ApiResponse> => {
    const key = nanoid();
    try {
      addProcess({
        id: key,
        type: ProcessType.DOWNLOAD_CHART,
        projectId: projectId,
        portfolioId: currentPortfolioId,
      });
      const response = await getRequest("get_project_export", {
        project_id: projectId,
        reference_ids: referenceIds,
        include_features: includeFeatures,
        include_claims: includeClaims,
        include_summary: includeSummary,
        introduction_language: introductionLanguage,
        color_subset: colors,
        feature_subset: features,
        claim_subset: claims,
        export_type: exportType,
      });

      const downloadFile = async (url: string, fileType: string) => {
        const fileResponse = await fetch(url);
        if (!fileResponse.ok)
          throw new Error(`Failed to download the ${fileType} file`);
        const fileBlob = await fileResponse.blob();
        const localUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = localUrl;
        const fileName = currentProject.name.replace(/[\s']/g, "");
        const extension = fileType === "excel" ? "xlsx" : "zip";
        link.setAttribute("download", `${fileName}_${fileType}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl);
      };

      if (response.data.urls) {
        // Handle multiple URLs
        if (response.data.urls.word) {
          await downloadFile(response.data.urls.word, "word");
        }
        if (response.data.urls.excel) {
          await downloadFile(response.data.urls.excel, "excel");
        }
      } else if (response.data.url) {
        // Handle single URL
        await downloadFile(response.data.url, exportType);
      } else {
        if (process.env.NODE_ENV !== "production") {
          console.error("No download URL found");
        }
        addErrorMessage("Error downloading file. Try again later.");
        return;
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting element chart export for word");
    } finally {
      removeProcess(key);
    }
  };

  /**
   * @description Updates the citation URLs for a reference
   * @param {string} projectId - The id of the project to update the citation URLs for
   * @param {string} citationId - The id of the citation to update the citation URLs for
   * @param {object} options - Additional options to pass to the request
   */
  const updateCitationUrls = async (
    citationId: string,
    options: { [key: string]: any } = {},
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        citation_id: citationId,
      };
      // Append additional options to payload if they exist
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          payload[key] = options[key];
        }
      });
      const response = await postRequest("post_update_citation_urls", payload);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating citation URLs");
    }
  };

  /**
   * @description Attaches an image to a citation
   * @param {number} index - the index of the row to add the image to
   * @param {string} referenceId - the id of the reference to add the image to
   * @param {number} citationIndex - the index of the citation to add the image to
   * @param {Array} figureUrls - the URLs of the figures to add to the citation
   */
  const handleAttachReferenceFiguresToCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrls: string[],
    citation: InvalidityCitation,
  ) => {
    const newChartData = [...chartData];
    const newCitation = newChartData[index][referenceId]["citations"][citationIndex];

    // Filter out URLs that are already in the citation's figure_urls
    // // Combine existing figure_urls with the new, filtered ones
    let newFigureUrls = figureUrls;
    if (!newCitation.figureUrls) {
      newCitation.figureUrls = [...newFigureUrls];
    } else {
      newFigureUrls = figureUrls.filter((url) => !newCitation.figureUrls.includes(url));
      newCitation.figureUrls = [...newCitation.figureUrls, ...newFigureUrls];
    }
    updateChartData(newChartData);
    const response = await updateReferenceCitation(citation.id, {
      new_figure_urls: newFigureUrls,
    });
    if (!response.success) {
      addErrorMessage(
        `Error adding figure to citation ${citationIndex} from project ${currentProject.name} `,
      );
      return false;
    }
    return true;
  };

  /**
   * @description Removes an image from a citation
   * @param {number} index - the index of the row to remove the image from
   * @param {string} referenceId - the id of the reference to remove the image from
   * @param {number} citationIndex - the index of the citation to remove the image from
   * @param {string} figureUrl - the URL of the figure to remove from the citation
   */
  const removeImageFromCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrl: string,
    citation: InvalidityCitation,
  ) => {
    const newChartData = [...chartData];
    const newCitation = newChartData[index][referenceId]["citations"][citationIndex];

    // Filter out the specific URL from the citation's figure_urls
    newCitation.figureUrls = newCitation.figureUrls.filter(
      (url: string) => url !== figureUrl,
    );
    updateChartData(newChartData);

    const response = await updateReferenceCitation(citation.id, {
      old_figure_urls: [figureUrl],
    });
    if (!response.success) {
      addErrorMessage(
        `Error removing figure from citation ${citationIndex} from project ${currentProject.name} `,
      );
    }
  };

  /**
   * @description Saves a citation after adding/editing
   * @param {number} index - the index of the row to save the citation to
   * @param {string} referenceId - the id of the reference to save the citation to
   * @param {number} citationIndex - the index of the citation to save
   * @param {string} citationTextEdit - the text of the citation to save
   * @param {string} citationParagraphEdit - the paragraph of the citation to save
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   * @param {Array} figureUrls - the URLs of the figures to save to the citation
   */
  const handleSaveCitation = async (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationParagraphEdit: string,
    isAddingCitation: boolean,
    figureUrls: string[],
    citation: InvalidityCitation,
    invalidityId: string,
    figureRefs: string[],
    setIsEditing: (isEditing: boolean) => void,
    setAddingCitationKey: (key: string) => void,
  ) => {
    setIsEditing(false);
    let newCitation: InvalidityCitation;
    let newChartData = [...chartData];
    let color: string;
    if (isAddingCitation) {
      color = chartData[index][referenceId]["citations"][citationIndex].color;
      const updatedCitations = [...chartData[index][referenceId]["citations"]];
      updatedCitations.splice(citationIndex, 1); // remove empty citation

      // Determine whether to update the color in the summary table and update if so
      const otherColors = extractOtherColors(index, citationIndex, referenceId);
      const isColorUpdate = isSummaryColorUpdateRequired("#D3D3D3", color, otherColors);
      if (isColorUpdate) {
        const newSummaryChartData = [...summaryChartData];
        newSummaryChartData[index][referenceId] = color;
        updateSummaryChartData(newSummaryChartData);
      }

      const addResponse = await addReferenceCitation(
        invalidityId,
        referenceId,
        color,
        citationTextEdit,
        citationParagraphEdit,
        currentProjectId,
        selectedElementType === ElementType.FEATURE,
        figureUrls,
        figureRefs,
        chartData[index].claim_number,
      );

      if (!addResponse.success) {
        addErrorMessage(
          `Error adding citation ${citationParagraphEdit} from project ${currentProject.name} `,
        );
      } else {
        newCitation = addResponse.data;
        updatedCitations.push(toCamelCase(newCitation));
        const newChartData = chartData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                [referenceId]: {
                  ...item[referenceId],
                  citations: updatedCitations,
                },
              }
            : { ...item },
        );
        updateChartData(newChartData);
      }

      setAddingCitationKey("");
    } else {
      if (citationParagraphEdit !== citation.paragraph) {
        newChartData[index][referenceId]["citations"][citationIndex].paragraph =
          citationParagraphEdit;
      }
      if (citationTextEdit !== citation.text) {
        newChartData[index][referenceId]["citations"][citationIndex].text =
          citationTextEdit;
      }
      updateChartData(newChartData);

      const response = await updateReferenceCitation(citation.id, {
        new_paragraph: citation.paragraph,
        new_text: citation.text,
      });
      if (!response.success) {
        addErrorMessage(
          `Error updating citation ${citationParagraphEdit} from project ${currentProject.name} `,
        );
      }
    }
  };

  /**
   * @description Deletes a citation
   * @param {number} itemIndex - the index of the row to delete the citation from
   * @param {number} citationIndex - the index of the citation to delete
   * @param {string} referenceId - the id of the reference to delete the citation from
   * @param {InvalidityCitation} citation - the citation to delete
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceDelete = async (
    itemIndex: number,
    referenceId: string,
    citationId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean,
  ) => {
    const newChartData = [...chartData];
    const updatedCitations = [...newChartData[itemIndex][referenceId]["citations"]];

    // Find the index of the citation by id
    const citationIndex = updatedCitations.findIndex((c) => c.id === citationId);

    if (citationIndex !== -1) {
      // Mark the citation as removed without splicing the array
      updatedCitations[citationIndex] = {
        ...updatedCitations[citationIndex],
        removed: true,
        updatedAt: new Date().toISOString(),
      };

      // Update the chart data
      newChartData[itemIndex][referenceId]["citations"] = updatedCitations;
      updateChartData(newChartData);

      // Update summary table if needed
      if (updatedCitations.every((c) => c.removed)) {
        summaryTableDeleteUpdate(itemIndex, referenceId);
      } else {
        // Recalculate the highest color after deletion
        const newHighestColor = getHighestRank(
          updatedCitations.filter((c) => !c.removed).map((c) => c.color),
        );

        const currentSummaryColor = summaryChartData[itemIndex][referenceId];
        if (newHighestColor !== currentSummaryColor) {
          const newSummaryChartData = [...summaryChartData];
          newSummaryChartData[itemIndex][referenceId] = newHighestColor;
          updateSummaryChartData(newSummaryChartData);
        }
      }

      if (!isAddingCitation) {
        const response = await deleteReferenceCitation(citation.id);
        if (!response.success) {
          addErrorMessage(
            `Error deleting citation ${citation.paragraph} from project ${currentProject.name}`,
          );
        }
      }
    } else {
      console.error("Citation not found in chartData.");
    }
  };

  /**
   * @description Retrieves the invalidity for a row
   * @param {number} rowIndex - the index of the row to retrieve the invalidity from
   * @param {string} referenceId - the id of the reference to retrieve the invalidity from
   * @param {string} newInvalidity - the new invalidity to set
   */
  const retrieveInvalidityForItem = async (
    rowIndex: number,
    referenceId: string,
    newInvalidity: InvalidityCitation[],
  ) => {
    // const newChartData = [...chartData];
    // newChartData[rowIndex][referenceId]["citations"] = newInvalidity;

    // updateChartData(newChartData);
    // fetchReferenceChartData();

    summaryTableNewInvalidityUpdate(rowIndex, referenceId, newInvalidity);
  };

  const summaryTableNewInvalidityUpdate = (
    claimIndex: number,
    reference_id: string,
    newInvalidity: InvalidityCitation[],
  ) => {
    const colors: string[] = [];
    // const invalidityArray = JSON.parse(newInvalidity);
    newInvalidity.forEach((citation: InvalidityCitation) => {
      if (citation.color) {
        colors.push(citation.color);
      }
    });

    const color = getHighestRank(colors);
    const newSummaryChartData = [...summaryChartData];

    // Ensure the claimIndex is within bounds
    if (!newSummaryChartData[claimIndex]) {
      newSummaryChartData[claimIndex] = {};
    }

    newSummaryChartData[claimIndex][reference_id] = color;
    updateSummaryChartData(newSummaryChartData);
  };

  const summaryTableDeleteUpdate = (claimIndex: number, referenceId: string) => {
    const newSummaryChartData = [...summaryChartData];
    newSummaryChartData[claimIndex][referenceId] = ChartColor.GRAY;
    updateSummaryChartData(newSummaryChartData);
  };

  /**
   * @description Updates the color of a citation
   * @param {number} index - the index of the row to update the color of
   * @param {number} citation - the index of the citation to update the color of
   * @param {string} currentColor - the current color of the citation
   * @param {string} color - the new color to set
   * @param {string} referenceId - the id of the reference to update the color of
   */
  const summaryTableColorUpdate = async (
    index: number,
    citation: number,
    currentColor: string,
    color: string,
    referenceId: string,
  ) => {
    const otherColors = extractOtherColors(index, citation, referenceId);

    const isUpdateRequired = isSummaryColorUpdateRequired(
      currentColor,
      color,
      otherColors,
    );

    if (!isUpdateRequired) {
      return;
    }

    const newSummaryChartData = [...summaryChartData];
    newSummaryChartData[index][referenceId] = color;
    updateSummaryChartData(newSummaryChartData);
  };

  /**
   * @description Extracts the colors of the other citations in a row
   * @param {number} index - the index of the row to extract the colors from
   * @param {number} citation - the index of the citation to exclude
   * @param {string} referenceId - the id of the reference to extract the colors from
   */
  const extractOtherColors = (index: number, citation: number, referenceId: string) => {
    const colors: string[] = [];
    const row = chartData[index][referenceId]["citations"];
    if (Array.isArray(row)) {
      row.forEach((item, itemIndex) => {
        // Check if the current citation index is not the one to be excluded and has a color property
        if (itemIndex !== citation && item.color) {
          colors.push(item.color);
        }
      });
    }
    return colors;
  };

  /**
   * @description Checks if a color update is required in the summary table
   * @param {string} currentColor - the current color of the citation
   * @param {string} newColor - the new color to set
   * @param {Array} otherColors - the colors of the other citations in the row
   */
  const isSummaryColorUpdateRequired = (
    currentColor: string,
    newColor: string,
    otherColors: string[],
  ) => {
    // Calculate the highest rank in the original set and the new set
    const originalHighestRank = getHighestRank([currentColor, ...otherColors]);
    const newHighestRank = getHighestRank([newColor, ...otherColors]);

    // Update required if the highest rank in the new set is different from the original set
    return newHighestRank !== originalHighestRank;
  };

  /**
   * @description Updates the color of a citation in the chart data
   * @param {number} index - the index of the row to update the color of
   * @param {number} citationIndex - the index of the citation to update the color of
   * @param {string} color - the new color to set
   * @param {string} referenceId - the id of the reference to update the color of
   * @param {string} citationParagraph - the paragraph of the citation to update
   * @param {boolean} isAddingCitation - whether the citation is being added or edited
   */
  const handleSourceColorUpdate = async (
    index: number,
    citationIndex: number,
    color: string,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean,
  ) => {
    // Get current color to determine whether FE ummary table update is needed
    const currentColor =
      chartData[index][referenceId]["citations"][citationIndex].color;

    // Change the color on the frontend
    const newChartData = [...chartData];
    newChartData[index][referenceId]["citations"][citationIndex].color = color;
    updateChartData(newChartData);

    if (!isAddingCitation) {
      // Update summary table color if needed
      summaryTableColorUpdate(index, citationIndex, currentColor, color, referenceId);

      const updateResponse = await updateReferenceCitation(citation.id, {
        new_color: color,
      });
      if (!updateResponse.success) {
        addErrorMessage(
          `Error updating citation color for ${citationIndex} from project ${currentProject.name} `,
        );
      }
    }
  };

  return {
    handleAddCitation,
    handleSourceDelete,
    handleSourceColorUpdate,
    handleSaveCitation,
    retrieveInvalidityForItem,
    handleAddImageToCitation,
    removeImageFromCitation,
    handleAttachReferenceFiguresToCitation,
    getSummaryChartData,
    updateCitationUrls,
    getReferenceChartData,
    getElementChartExport,
  };
};

export default useDataTable;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { usePortfolio } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { parseCommaSeparatedString, isLikelyPatentNumber } from "@/utils/dataUtils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { CheckboxWithText } from "@/components/ui/checkbox";

interface AddPatentsToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Modal for adding patents to a portfolio
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const AddPatentsToPortfolioModal: React.FC<AddPatentsToPortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { addPatentsToPortfolio } = usePortfolio();

  // Modal state
  const [patentNumbers, setPatentNumbers] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [invalidPatentNumbers, setInvalidPatentNumbers] = useState<string[]>([]);
  const [addAllReferences, setAddAllReferences] = useState<boolean>(false);

  // Global store state
  const { currentPortfolio, currentPortfolioId } = useProjectStore();
  const { addWarningMessage } = useAppStateStore();
  // Reset the modal state on close
  const handleCloseClick = () => {
    setPatentNumbers("");
    setError("");
    setInvalidPatentNumbers([]);
    handleClose();
  };

  // Handle the add to project button click
  const handleAddClick = async () => {
    handleClose();

    const patentNumbersArray = parseCommaSeparatedString(patentNumbers);

    // Make sure the portfolio doesn't exceed the maximum number of patents
    if (currentPortfolio.projects.length + patentNumbersArray.length > 100) {
      setError(
        "The maximum number of patents or applications that can be in a portfolio is 100.",
      );
      return;
    }

    // Ensure duplicate patent numbers are not added
    const existingPortfolioSubjectNumbers = currentPortfolio.projects.map(
      (project) => project.subject.number,
    );

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    for (const patentNumber of patentNumbersArray) {
      if (
        !isLikelyPatentNumber(patentNumber) ||
        existingPortfolioSubjectNumbers.includes(patentNumber)
      ) {
        setInvalidPatentNumbers([...invalidPatentNumbers, patentNumber]);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }

    if (invalidPatentNumbers.length > 0) {
      // Add a warning message for invalid patent numbers
      addWarningMessage(
        `The following patent or application numbers already exist in the portfolio or are not valid: ${invalidPatentNumbers.join(
          ", ",
        )}.`,
      );
    }

    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setError("No valid patent or application numbers input.");
      return;
    }

    if (validPatentNumbers.length > 0) {
      await addPatentsToPortfolio(
        currentPortfolioId,
        validPatentNumbers,
        // addAllReferences
      );
    }
    // await getPortfolioMetadata(currentProjectId);

    handleCloseClick();
  };

  const handlePatentNumbersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setPatentNumbers(newValue);
    if (newValue !== "") {
      setError("");
    }
    setInvalidPatentNumbers([]);
  };

  return (
    <Dialog open={open} onOpenChange={handleCloseClick}>
      <DialogContent
        className="w-[50vw] min-w-[300px] max-w-[800px]"
        aria-describedby="Upload files"
      >
        <DialogHeader>
          <DialogTitle>Add subjects to portfolio</DialogTitle>
          <DialogDescription>
            Enter a comma-separated list of published patents and applications numbers.
            A country prefix is required.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="space-y-2">
            <AutosizeTextarea
              id="patent-numbers"
              placeholder="US10952930, EP1095293A1, US20220183193A1"
              value={patentNumbers}
              onChange={(e) => handlePatentNumbersChange(e)}
              className={error ? "border-red-500" : ""}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAddClick} disabled={patentNumbers === ""}>
            Add to Portfolio
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddPatentsToPortfolioModal;

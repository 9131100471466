/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore, useAppStateStore } from "../store";
import { useApi } from "./";
import { ApiResponse, ProjectStatus, ParentType } from "@/types";
import { toCamelCase } from "@/utils/dataUtils";

/**
 * @description Hook for handling visualization operations relating to claim chart projects
 * @returns {object} - The visualization operations
 */
const useViz = () => {
  const { postRequest, getRequest, postRequestFile, handleError } = useApi();
  const {
    currentSubject,
    currentProject,
    currentPortfolioId,
    updateChartData,
    updateCurrentProject,
    updateCurrentSubject,
    updateSelectedReferences,
    updateProjectsList,
    updateArchivedProjectsList,
    updateCurrentProjectId,
    updateCurrentParent,
    updateCurrentPortfolioId,
    updateCurrentPortfolio,
  } = useProjectStore();
  const { updateSearchChatProjectId } = useAppStateStore();

  /**
   * @description Fetches the reference metadata
   * @param {array} referenceIds - The ids of the references to fetch the metadata for
   */
  const getReferenceMetadata = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_reference_metadata", {
        reference_ids: referenceIds,
      });
      const referenceMetadata = response.data;

      updateSelectedReferences(toCamelCase(referenceMetadata, true));
      return {
        success: true,
        data: referenceMetadata,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference metadata");
    }
  };

  /**
   * @description Uploads an image to S3
   * @param {File} file - The file to upload to S3
   */
  const uploadImageToS3 = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequestFile("post_upload_image_to_s3", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading image to S3");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} note - The new note for the reference
   */
  const updateProjectReferenceNote = async (
    projectId: string,
    referenceId: string,
    note: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_project_reference_note", {
        project_id: projectId,
        reference_id: referenceId,
        note: note,
      });

      if (currentProject && currentProject.references) {
        const newReferences = [...currentProject.references];
        newReferences.map((reference) => {
          if (reference.id === referenceId) {
            reference.note = note;
          }
        });
        updateCurrentProject({
          ...currentProject,
          references: newReferences,
        });
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} newName - The new note for the reference
   */
  const updateProjectReferenceNickname = async (
    projectId: string,
    referenceId: string,
    newName: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_project_reference_nickname", {
        project_id: projectId,
        reference_id: referenceId,
        name: newName,
      });
      if (currentProject) {
        const nicknames = { ...currentProject.documentsToNicknames };
        nicknames[referenceId] = newName;
        updateCurrentProject({
          ...currentProject,
          documentsToNicknames: nicknames,
        });
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Gets the search chat project id
   */
  const getSearchChatProjectId = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_search_chat_project_id");
      updateSearchChatProjectId(response.data.id);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting search chat project id");
    }
  };

  /**
   * @description Gets the full document for a reference
   * @param {string} projectId - The id of the project to get the full document for
   * @param {string} referenceId - The id of the reference to get the full document for
   */
  const getFullDocument = async (referenceId: string): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        reference_id: referenceId,
      };

      const response = await getRequest("get_full_document", payload);
      return {
        success: true,
        data: toCamelCase(response.data, false),
      };
    } catch (error) {
      return handleError(error, "Error getting full document");
    }
  };

  /**
   * @description Gets the user files for a user
   * @param {string} userEmail - The email of the user to get the files for
   */
  const getUserFiles = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_user_files");
      return { success: true, data: toCamelCase(response.data, true) };
    } catch (error) {
      return handleError(error, "Error getting user files");
    }
  };

  /**
   * @description Updates the document details
   * @param {string} documentId - The id of the document to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateDocumentDetails = async (
    documentId: string,
    options: any,
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        document_id: documentId,
      };
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          payload[key] = options[key];
        }
      });
      const response = await postRequest("post_update_document_details", payload);
      updateCurrentSubject({ ...currentSubject, abstract: payload.abstract });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating document details");
    }
  };

  /**
   * @description Uploads a file to S3
   * @param {File} file - The file to upload to S3
   */
  const uploadFile = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequest("post_upload_file", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading file");
    }
  };

  /**
   * @description Fetches all projects for a user
   */
  const getUserProjects = async (): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_list");
      const archivedProjectsList = response.data.filter(
        (project: any) => project.status === ProjectStatus.ARCHIVED,
      );
      const activeProjectsList = response.data.filter(
        (project: any) => project.status !== ProjectStatus.ARCHIVED,
      );
      updateProjectsList(activeProjectsList);
      updateArchivedProjectsList(archivedProjectsList);
      return { success: true, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching user projects");
    }
  };

  /**
   * @description Fetches portfolio metadata and updates store
   * @param {string} portfolioId - The id of the portfolio to fetch metadata for
   */
  const getPortfolioMetadata = async (portfolioId: string): Promise<ApiResponse> => {
    try {
      if (!portfolioId) {
        return { success: false, message: "Portfolio ID is required" };
      }
      const response = await getRequest("get_portfolio_metadata", {
        portfolio_id: portfolioId,
      });
      const portfolioMetadata = response.data;

      updateCurrentPortfolio({
        id: portfolioMetadata.id,
        name: portfolioMetadata.name,
        projects: portfolioMetadata.projects.map((project: any) => ({
          ...toCamelCase(project, false),
          subject: project.subject ? toCamelCase(project.subject, false) : null,
        })),
        type: portfolioMetadata.type,
        owner: portfolioMetadata.created_by,
        references: toCamelCase(portfolioMetadata.references, true),
      });

      if (portfolioMetadata.id !== currentPortfolioId) {
        updateCurrentProject({
          id: "",
          name: "",
        });
        updateCurrentProjectId("");
        updateCurrentParent(ParentType.PORTFOLIO);
        updateCurrentPortfolioId(portfolioMetadata.id);
      }

      return { success: true, data: portfolioMetadata };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    }
  };

  /**
   * @description Returns model evaluation for a given project and references
   * @param {string} projectId - The id of the project to evaluate
   * @param {array} referenceIds - The ids of the references associated with the project
   */
  const getEvaluation = async (
    projectId: string,
    referenceIds: string[],
    patentNumber: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_evaluation", {
        project_id: projectId,
        reference_ids: referenceIds,
        patent_number: patentNumber,
      });
      updateChartData(response.data);
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching evaluation data");
    }
  };

  const getRemainingParagraphs = async (
    projectId: string,
    referenceId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_remaining_paragraphs", {
        project_id: projectId,
        reference_id: referenceId,
      });
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching remaining paragraphs");
    }
  };

  const removeUserFiles = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_remove_user_files", {
        reference_ids: referenceIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error removing user files");
    }
  };

  const addToUserFiles = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_to_user_files", {
        reference_ids: referenceIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding to user files");
    }
  };

  const addReferencesToPortfolio = async (
    projectIds: string[],
    files: File[],
  ): Promise<ApiResponse> => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    formData.append("project_ids", JSON.stringify(projectIds));
    try {
      const response = await postRequestFile(
        "post_add_references_to_portfolio",
        formData,
      );
      // if (response.data) {
      //   await addToDocumentsAdded(
      //     currentParent === ParentType.PORTFOLIO
      //       ? currentPortfolioId
      //       : currentProjectId,
      //     [response.data.reference_id],
      //     currentParent === ParentType.PORTFOLIO,
      //   );
      // }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Failed to add reference to project");
    }
  };

  const reprocessPatents = async (patentIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_reprocess_patents", {
        patent_ids: patentIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error reprocessing patents");
    }
  };

  const reprocessReferences = async (referenceIds: string[]): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_reprocess_references", {
        reference_ids: referenceIds,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error reprocessing references");
    }
  };

  return {
    uploadImageToS3,
    updateProjectReferenceNote,
    getSearchChatProjectId,
    getFullDocument,
    getUserFiles,
    updateDocumentDetails,
    updateProjectReferenceNickname,
    uploadFile,
    getUserProjects,
    getPortfolioMetadata,
    getEvaluation,
    getRemainingParagraphs,
    removeUserFiles,
    addToUserFiles,
    addReferencesToPortfolio,
    reprocessPatents,
    reprocessReferences,
    getReferenceMetadata,
  };
};

export default useViz;

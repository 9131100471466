/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, {
  useState,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
} from "react";
import { useViz, useDataTable } from "@/hooks";
import { useProjectStore } from "@/store";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { ThumbsUpIcon, ThumbsDownIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Cross2Icon, Pencil1Icon, FileIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { ReferenceImage, PatentViewer, ImageContainer } from "@/components";
import { InvalidityCitation, ChartColor } from "@/types";
import { ImageUploadModal, ReferenceFigureSelectModal, AddImageMenu } from "./index";
import { CitationTextarea } from "@/components/ui/citation-textarea";
import { LoadingButton } from "@/components/ui/loading-button";
import { useFeedbackChartDataStore } from "@/hooks/useFeedbackChartDataStore";
import { cn } from "@/lib/utils";
interface ReferenceTableCitationProps {
  citationKey: string;
  citation: InvalidityCitation;
  index: number;
  refId: string;
  claimNumber: string;
  citationIndex: number;
  invalidityId: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  addingCitationKey: string;
  setAddingCitationKey: (key: string) => void;
}

/**
 * @description Reference table citation to display a citation in the table
 * @param {string} citationKey - The key of the citation.
 * @param {InvalidityCitation} citation - The citation to display.
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} claimNumber - The claim number of the citation.
 * @param {number} citationIndex - The index of the citation.
 * @param {string} invalidityId - The id of the invalidity.
 * @param {boolean} isEditing - Whether the citation is being edited.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 */
const ReferenceTableCitation: React.FC<ReferenceTableCitationProps> = ({
  citationKey,
  citation,
  index,
  refId,
  claimNumber,
  citationIndex,
  invalidityId,
  isEditing,
  setIsEditing,
  addingCitationKey,
  setAddingCitationKey,
}) => {
  const { getFullDocument, uploadImageToS3 } = useViz();
  const { currentProjectId, selectedReferences } = useProjectStore();
  const { addFeedbackCitation, removeFeedbackCitation, feedbackChartData } =
    useFeedbackChartDataStore();

  console.log(citation);
  const {
    handleSaveCitation,
    handleAddImageToCitation,
    handleSourceDelete,
    handleAttachReferenceFiguresToCitation,
    removeImageFromCitation,
    handleSourceColorUpdate,
  } = useDataTable();

  const [citationTextEdit, setCitationTextEdit] = useState<string>(citation.text);
  const [citationTextEditError, setCitationTextEditError] = useState<boolean>(false);
  const [citationParagraphEdit, setCitationParagraphEdit] = useState<string>(
    citation.paragraph,
  );
  const [citationParagraphEditError, setCitationParagraphEditError] =
    useState<boolean>(false);
  const [isEditingCitation, setIsEditingCitation] = useState<boolean>(false);
  const [isChangingColor, setIsChangingColor] = useState<boolean>(false);
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState<boolean>(false);
  const [figureSelectModalOpen, setFigureSelectModalOpen] = useState<boolean>(false);
  const [imageMenuState, setImageMenuState] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({
    mouseX: null,
    mouseY: null,
  });
  const [referenceFiguresToAttach, setReferenceFiguresToAttach] = useState<string[]>(
    [],
  );
  const [showPatentViewer, setShowPatentViewer] = useState<boolean>(false);
  const [patentViewerPatent, setPatentViewerPatent] = useState<any>(null);
  const [patentViewerCitationText, setPatentViewerCitationText] = useState<
    string | null
  >(null);
  const [isCitationSaving, setIsCitationSaving] = useState<boolean>(false);

  const getCitationFeedback = () => {
    return feedbackChartData[currentProjectId]?.[refId]?.[claimNumber]?.find(
      (c) => c.id === citation.id,
    )?.feedback;
  };

  const [citationFeedback, setCitationFeedback] = useState<string | null>(
    getCitationFeedback(),
  );

  const handleAttachImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setImageMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseAddImageMenu = () => {
    setImageMenuState({ mouseX: null, mouseY: null });
  };

  const [contextMenuPosition, setContextMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleRightClick = useCallback((event: ReactMouseEvent) => {
    event.preventDefault();
    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const menuW = 100; // Approximate width of your context menu
    const menuH = 100; // Approximate height of your context menu

    const left = clickX + menuW > screenW ? clickX - menuW : clickX;
    const top = clickY + menuH > screenH ? clickY - menuH : clickY;

    setContextMenuPosition({ left, top });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenuPosition && !(event.target as Element).closest(".context-menu")) {
        handleCloseContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenuPosition, handleCloseContextMenu]);

  const handleSaveCitationClick = () => {
    setIsCitationSaving(true);
    const trimmedCitationText = citationTextEdit.trim();
    const trimmedCitationParagraph = citationParagraphEdit.trim();

    if (trimmedCitationText === "" && trimmedCitationParagraph === "") {
      setCitationTextEditError(true);
      setCitationParagraphEditError(true);
      return;
    } else if (trimmedCitationText === "") {
      setCitationTextEditError(true);
      setCitationParagraphEditError(false);
      return;
    } else if (trimmedCitationParagraph === "") {
      setCitationParagraphEditError(true);
      setCitationTextEditError(false);
      return;
    } else {
      setCitationTextEditError(false);
      setCitationParagraphEditError(false);
      setIsEditingCitation(false);
    }

    handleSaveCitation(
      index,
      refId,
      citationIndex,
      trimmedCitationText,
      trimmedCitationParagraph,
      addingCitationKey === citationKey,
      referenceFiguresToAttach,
      citation,
      citation.invalidityId,
      [],
      setIsEditing,
      setAddingCitationKey,
    );
    setIsEditingCitation(false);
    setIsEditing(false);
    setIsCitationSaving(false);
  };

  const handleEditCitation = () => {
    handleCloseContextMenu();
    setIsEditing(true);
    setIsEditingCitation(true);
  };

  const handleAttachImageToCitation = async (file: File | null) => {
    if (file) {
      if (addingCitationKey !== citationKey) {
        await handleAddImageToCitation(index, refId, citationIndex, file, citation);
      } else {
        const response = await uploadImageToS3(file);
        const imageUrl = response.data.url;
        setReferenceFiguresToAttach([...referenceFiguresToAttach, imageUrl]);
      }
    }
  };

  const handleAttachImagesToCitation = async (files: File[]) => {
    for (const file of files) {
      await handleAttachImageToCitation(file);
    }
  };

  const handleAttachFigures = (figureUrls: string[]) => {
    handleAttachReferenceFiguresToCitation(
      index,
      refId,
      citationIndex,
      figureUrls,
      citation,
    );
  };

  const handleRemoveImageClick = (figureUrl: string) => {
    removeImageFromCitation(index, refId, citationIndex, figureUrl, citation);
  };

  const removeFromReferenceFiguresToAttach = (imageUrl: string) => {
    setReferenceFiguresToAttach(
      referenceFiguresToAttach.filter((url) => url !== imageUrl),
    );
  };

  const handleDeleteClick = () => {
    setIsEditingCitation(false);
    setIsEditing(false);
    const isAddingCitation = addingCitationKey === citationKey;
    handleSourceDelete(
      index,
      refId.toString(),
      citation.id,
      citation,
      isAddingCitation,
    );
  };

  const handleFeedbackClick = (type: "positive" | "negative") => {
    setIsEditingCitation(false);
    setIsEditing(false);
    if (citationFeedback === type) {
      removeFeedbackCitation(currentProjectId, refId, claimNumber, citation.id);
      setCitationFeedback(null);
    } else {
      addFeedbackCitation(currentProjectId, refId, claimNumber, {
        ...citation,
        feedback: type,
      });
      setCitationFeedback(type);
    }
  };

  const handleViewInPatent = async () => {
    const patentResponse = await getFullDocument(refId);
    const patent = patentResponse.data;
    setPatentViewerPatent(patent);
    if (citation.text.startsWith('"') && citation.text.endsWith('"')) {
      const strippedText = citation.text.substring(1, citation.text.length - 1);
      setPatentViewerCitationText(strippedText);
    } else {
      setPatentViewerCitationText(citation.text);
    }
    handleCloseContextMenu();
    setShowPatentViewer(true);
  };

  const handleClosePreview = () => {
    setPatentViewerPatent(null);
    setPatentViewerCitationText(null);
    setShowPatentViewer(false);
  };

  const handleColorChange = (color: string) => {
    handleSourceColorUpdate(
      index,
      citationIndex,
      color,
      refId,
      citation,
      addingCitationKey === citationKey,
    );
    setIsChangingColor(false);
  };

  const disableFeedbackButtons =
    isEditing && !isEditingCitation && addingCitationKey !== citationKey;
  return (
    <>
      <TooltipProvider>
        <div className="flex items-start pb-2 " onContextMenu={handleRightClick}>
          <div className="flex flex-col items-center gap-2 pr-3">
            <Popover open={isChangingColor} onOpenChange={setIsChangingColor}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  style={{
                    backgroundColor: citation.color || "defaultBackgroundColor",
                  }}
                  onClick={() => setIsChangingColor(true)}
                />
              </PopoverTrigger>
              <PopoverContent className="w-auto p-1">
                <div className="flex flex-row">
                  {[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED].map(
                    (color) =>
                      citation.color !== color && (
                        <div
                          className="w-9 h-9 m-1 cursor-pointer rounded-md"
                          style={{ backgroundColor: color }}
                          onClick={() => handleColorChange(color)}
                        />
                      ),
                  )}
                </div>
              </PopoverContent>
            </Popover>
          </div>

          <div className="flex-1 mr-4">
            {isEditingCitation || addingCitationKey === citationKey ? (
              <div className="space-y-2">
                <Input
                  value={citationParagraphEdit}
                  placeholder="Citation"
                  onChange={(e) => setCitationParagraphEdit(e.target.value)}
                  className={citationParagraphEditError ? "border-red-500" : ""}
                />
                {citationParagraphEditError && (
                  <p className="text-red-500 text-sm">This field cannot be empty</p>
                )}
                <CitationTextarea
                  value={citationTextEdit}
                  placeholder="Text"
                  onChange={(e) => setCitationTextEdit(e.target.value)}
                  className={citationTextEditError ? "border-red-500 " : ""}
                />

                {citationTextEditError && (
                  <p className="text-red-500 text-sm">This field cannot be empty</p>
                )}
                <div className="flex flex-wrap items-center gap-2">
                  {citation.figureUrls &&
                    citation.figureUrls.map((imageUrl) => (
                      <ImageContainer key={imageUrl}>
                        <ReferenceImage
                          imageUrl={imageUrl}
                          imageName={imageUrl.split("/").pop() || ""}
                        />
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleRemoveImageClick(imageUrl)}
                            >
                              <Cross2Icon className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Remove Image</TooltipContent>
                        </Tooltip>
                      </ImageContainer>
                    ))}
                  {referenceFiguresToAttach &&
                    referenceFiguresToAttach.map((imageUrl) => (
                      <ImageContainer key={imageUrl}>
                        <ReferenceImage
                          imageUrl={imageUrl}
                          imageName={imageUrl.split("/").pop() || ""}
                        />
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="destructive"
                              size="icon"
                              onClick={() =>
                                removeFromReferenceFiguresToAttach(imageUrl)
                              }
                            >
                              <Cross2Icon className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Remove Image</TooltipContent>
                        </Tooltip>
                      </ImageContainer>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                <p className="font-bold mb-1">{citation.paragraph}</p>
                <p className="mb-1">{citation.text}</p>
                <div className="flex flex-wrap items-center gap-2 mt-2">
                  {citation.figureUrls &&
                    citation.figureUrls.map((imageUrl) => (
                      <ImageContainer key={imageUrl}>
                        <ReferenceImage
                          imageUrl={imageUrl}
                          imageName={imageUrl.split("/").pop() || ""}
                        />
                      </ImageContainer>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={citation.feedback === "positive" ? "default" : "ghost"}
                  size="icon"
                  onClick={() => handleFeedbackClick("positive")}
                  disabled={disableFeedbackButtons}
                >
                  <ThumbsUpIcon
                    className={cn(
                      "h-4 w-4",
                      citationFeedback === "positive" && "text-blue-500",
                    )}
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Positive feedback</TooltipContent>
            </Tooltip>
          </div>
          <div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleFeedbackClick("negative")}
                  disabled={disableFeedbackButtons}
                >
                  <ThumbsDownIcon
                    className={cn(
                      "h-4 w-4",
                      citationFeedback === "negative" && "text-red-500",
                    )}
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Negative feedback</TooltipContent>
            </Tooltip>
          </div>
          <div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={handleDeleteClick}
                  disabled={disableFeedbackButtons}
                >
                  <Cross2Icon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Remove citation</TooltipContent>
            </Tooltip>
          </div>
        </div>

        {(isEditingCitation || addingCitationKey === citationKey) && (
          <div className="flex items-center justify-center gap-2 mb-3">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="icon" onClick={handleAttachImageClick}>
                  <FileIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Attach Images</TooltipContent>
            </Tooltip>
            <LoadingButton loading={isCitationSaving} onClick={handleSaveCitationClick}>
              Save Citation
            </LoadingButton>
          </div>
        )}

        {contextMenuPosition && (
          <div
            style={{
              position: "fixed",
              top: contextMenuPosition.top,
              left: contextMenuPosition.left,
              zIndex: 1000,
            }}
            className="flex flex-col bg-background shadow-md rounded-md p-2 context-menu"
          >
            <Button
              variant="ghost"
              className="justify-start"
              onClick={() => {
                handleEditCitation();
                handleCloseContextMenu();
              }}
              disabled={isEditingCitation || isEditing}
            >
              <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Citation
            </Button>
            <Button
              variant="ghost"
              className="justify-start"
              onClick={() => {
                handleViewInPatent();
                handleCloseContextMenu();
              }}
            >
              <EyeOpenIcon className="mr-2 h-4 w-4" /> View in Reference
            </Button>
          </div>
        )}

        <ImageUploadModal
          open={imageUploadModalOpen}
          handleClose={() => setImageUploadModalOpen(false)}
          handleSubmitFiles={handleAttachImagesToCitation}
        />

        <ReferenceFigureSelectModal
          open={figureSelectModalOpen}
          handleClose={() => setFigureSelectModalOpen(false)}
          handleAttachFigures={handleAttachFigures}
          refId={refId}
          existingFigureUrls={citation.figureUrls || []}
          isAddingCitation={addingCitationKey === citationKey}
          setReferenceFiguresToAttach={setReferenceFiguresToAttach}
        />

        <AddImageMenu
          mouseX={imageMenuState.mouseX}
          mouseY={imageMenuState.mouseY}
          handleClose={handleCloseAddImageMenu}
          onUploadClick={() => {
            setImageUploadModalOpen(true);
            handleCloseAddImageMenu();
          }}
          onBrowseClick={() => {
            setFigureSelectModalOpen(true);
            handleCloseAddImageMenu();
          }}
          displayBrowse={
            selectedReferences.find((ref) => ref.id === refId)?.figureUrls?.length >
              0 &&
            selectedReferences.find((ref) => ref.id === refId)?.figureUrls?.length !==
              citation.figureUrls?.length
          }
        />

        <PatentViewer
          open={showPatentViewer}
          handleClose={handleClosePreview}
          patent={patentViewerPatent}
          citationText={patentViewerCitationText || ""}
        />
      </TooltipProvider>
    </>
  );
};

export default ReferenceTableCitation;

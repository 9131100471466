/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import useApi from "./useApi";
import { useAdminStore, useUserStore } from "@/store";
import { Organization, User, ApiResponse } from "@/types";
import { toCamelCase } from "@/utils/dataUtils";

/**
 * @description Hook for handling organization and user operations
 * @returns {object} - The organization operations
 */
const useAdminManagement = () => {
  const { postRequest, getRequest, handleError } = useApi();
  const { organizationId } = useUserStore();
  const {
    users,
    updateProjects,
    updateInvoices,
    updateCurrentBillingCycle,
    updateOverview,
    updatePlan,
    updateUsers,
    updateSuperAdminInvoices,
    updateSuperAdminOrganizations,
    updateSuperAdminOverview,
  } = useAdminStore();

  /**
   * @description Creates a user in postgres
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const createUserPostgres = async (): Promise<ApiResponse> => {
    try {
      await postRequest("post_create_user_postgres");
      return { success: true };
    } catch (error) {
      return handleError(error, "Error creating user in postgres");
    }
  };

  /**
   * @description Fetches the user hash
   * @returns {Promise<ApiResponse>} - Result object with success status and data or error message.
   */
  const getUserHash = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_user_hash");
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching user hash");
    }
  };

  /**
   * @description Creates an organization in all services
   * @param {Organization} org - The payload to send to the API
   */
  const createOrganization = async (org: Organization): Promise<ApiResponse> => {
    try {
      const payload: any = {};

      if (org.organizationName !== null) payload.name = org.organizationName;
      payload.invoice_type = "MONTHLY";
      if (org.contractStart !== null) payload.contract_start = org.contractStart;
      if (org.contractEnd !== null) payload.contract_end = org.contractEnd;
      if (org.documentPrice !== null) payload.document_price = org.documentPrice;
      if (org.monthlyLicensePrice !== null)
        payload.monthly_license_price = org.monthlyLicensePrice;
      if (org.yearlyLicensePrice !== null)
        payload.yearly_license_price = org.yearlyLicensePrice;
      if (org.monthlySeatPrice !== null)
        payload.monthly_seat_price = org.monthlySeatPrice;
      if (org.yearlySeatPrice !== null) payload.yearly_seat_price = org.yearlySeatPrice;
      if (org.invProjectPrice !== null) payload.inv_project_price = org.invProjectPrice;
      if (org.pfaProjectPrice !== null) payload.pfa_project_price = org.pfaProjectPrice;
      if (org.appProjectPrice !== null) payload.app_project_price = org.appProjectPrice;
      if (org.maxSeats !== null) payload.max_seats = org.maxSeats;
      if (org.documentsAddedPrice !== null)
        payload.documents_added_price = org.documentsAddedPrice;
      if (org.documentsExportedPrice !== null)
        payload.documents_exported_price = org.documentsExportedPrice;
      if (org.uniqueDocumentsPricing !== null)
        payload.unique_documents_pricing = org.uniqueDocumentsPricing;
      await postRequest("post_create_organization", payload);
      await getSuperAdminDetails();
      return { success: true };
    } catch (error) {
      return handleError(error, "Error adding organization: " + org.organizationName);
    }
  };

  /**
   * @description Deletes an organization in all services
   * @param {string} organizationName - The name of the organization to delete
   * @param {string} organizationIdToDelete - Whether to confirm the deletion
   */
  const deleteOrganization = async (
    organizationName: string,
    organizationId: string,
  ): Promise<ApiResponse> => {
    try {
      await postRequest("post_delete_organization", {
        organization_name: organizationName,
        organization_id: organizationId,
      });
      await getSuperAdminDetails();
      return { success: true };
    } catch (error) {
      return handleError(error, "Error deleting organization: " + organizationName);
    }
  };

  /**
   * @description Updates an organization in all services
   * @param {Organization} old_org - The old organization
   * @param {Organization} new_org - The new organization
   */
  const updateOrganization = async (
    old_org: Organization,
    new_org: Organization,
  ): Promise<ApiResponse> => {
    try {
      const payload: any = {
        id: old_org.organizationId,
      };

      // Optional fields
      if (new_org.organizationName != old_org.organizationName) {
        payload.name = new_org.organizationName;
      }
      if (new_org.isTesting != old_org.isTesting) {
        payload.is_testing = new_org.isTesting;
      }
      if (new_org.passthroughBilling != old_org.passthroughBilling) {
        payload.passthrough_billing = new_org.passthroughBilling;
      }
      if (new_org.clientNumbers != old_org.clientNumbers) {
        payload.client_numbers = new_org.clientNumbers;
      }
      if (new_org.invoiceType != old_org.invoiceType) {
        payload.invoice_type = new_org.invoiceType;
      }
      if (new_org.uniqueDocumentsPricing != old_org.uniqueDocumentsPricing) {
        payload.unique_documents_pricing = new_org.uniqueDocumentsPricing;
      }
      if (new_org.documentPrice != old_org.documentPrice) {
        payload.document_price = new_org.documentPrice;
      }
      if (new_org.monthlyLicensePrice != old_org.monthlyLicensePrice) {
        payload.monthly_license_price = new_org.monthlyLicensePrice;
      }
      if (new_org.yearlyLicensePrice != old_org.yearlyLicensePrice) {
        payload.yearly_license_price = new_org.yearlyLicensePrice;
      }
      if (new_org.monthlySeatPrice != old_org.monthlySeatPrice) {
        payload.monthly_seat_price = new_org.monthlySeatPrice;
      }
      if (new_org.yearlySeatPrice != old_org.yearlySeatPrice) {
        payload.yearly_seat_price = new_org.yearlySeatPrice;
      }
      if (new_org.invProjectPrice != old_org.invProjectPrice) {
        payload.inv_project_price = new_org.invProjectPrice;
      }
      if (new_org.ftoProjectPrice != old_org.ftoProjectPrice) {
        payload.fto_project_price = new_org.ftoProjectPrice;
      }
      if (new_org.pfaProjectPrice != old_org.pfaProjectPrice) {
        payload.pfa_project_price = new_org.pfaProjectPrice;
      }
      if (new_org.maxSeats != old_org.maxSeats) {
        payload.max_seats = new_org.maxSeats;
      }
      if (new_org.documentsAddedPrice != old_org.documentsAddedPrice) {
        payload.documents_added_price = new_org.documentsAddedPrice;
      }
      if (new_org.documentsExportedPrice != old_org.documentsExportedPrice) {
        payload.documents_exported_price = new_org.documentsExportedPrice;
      }

      await postRequest("post_update_organization", payload);

      return { success: true };
    } catch (error) {
      return handleError(
        error,
        "Error updating organization: " + old_org.organizationName,
      );
    }
  };

  /**
   * @description Fetches all organizations
   * @returns {Promise<ApiResponse>} - Result object with success status and data or error message.
   */
  const getOrganizations = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_all_organizations");
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching organizations");
    }
  };

  /**
   * @description Fetches all users in the specified organization
   * @param {string} orgId - The name of the organization to fetch users for.
   * @returns {Promise<ApiResponse>} - Result object with success status and data or error message.
   */
  const getUsers = async (orgId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_users", {
        organization_id: orgId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching users.");
    }
  };

  /**
   * @description Adds a new user to the specified organization
   * @param {User} newUser - The new user to add
   * @param {string} orgId - The id of the organization to add the user to
   */
  const addNewUser = async (newUser: User, orgId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_user_to_organization", {
        email: newUser.email,
        first_name: newUser.firstName,
        last_name: newUser.lastName,
        role: newUser.role,
        organization_id: orgId,
      });
      const updatedUsers = await getUsers(orgId);
      updateUsers(toCamelCase(updatedUsers.data, true));
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error adding user to organization");
    }
  };

  /**
   * @description Deletes a user from the specified organization
   * @param {string} userId - The id of the user to delete
   * @param {string} orgId - The id of the organization to delete the user from
   */
  const deleteUser = async (userId: string, orgId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_user", {
        user_id: userId,
        organization_id: orgId,
      });
      updateUsers(users.filter((user) => user.id !== userId));
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error deleting user from organization");
    }
  };

  /**
   * @description Updates user role (User, Admin, SuperAdmin)
   * @param {string} user_id - The id of the user to update the role for.
   * @param {string} new_role - The new role for the user.
   * @param {string} orgId - The id of the organization to update the user role for.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const updateUserRole = async (
    user_id: string,
    new_role: string,
    orgId: string,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_user_role", {
        user_id: user_id,
        role: new_role,
        organization_id: orgId,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(
        error,
        `Error updating user role for ${user_id} to ${new_role}`,
      );
    }
  };

  /**
   * @description Deactivates the specified user
   * @param {string} user_id - The id of the user to deactivate.
   * @param {string} email - The email of the user to deactivate.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const disableUser = async (user_id: string, email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_disable_user", {
        user_id: user_id,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error deactivating user: ${email}`);
    }
  };

  /**
   * @description Activates the specified user
   * @param {string} user_id - The id of the user to activate.
   * @param {string} email - The email of the user to activate.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const enableUser = async (user_id: string, email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_enable_user", {
        user_id,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error activating user: ${email}`);
    }
  };

  /**
   * @description Fetches admin details for the specified organization
   * @param {string} orgId - The id of the organization to fetch admin details for.
   * @returns {Promise<ApiResponse>} - Result object with success status and data or error message.
   */
  const getAdminDetailsForOrg = async (orgId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_admin_details_for_org", {
        organization_id: orgId,
      });
      const camelizedResponse = toCamelCase(response.data, true);
      updateProjects(camelizedResponse.projects);
      updateInvoices(camelizedResponse.invoices);
      updateUsers(camelizedResponse.users);
      updateCurrentBillingCycle(camelizedResponse.currentBillingCycle);
      updateOverview(camelizedResponse.overview);
      updatePlan(camelizedResponse.plan);
      return { success: true, data: camelizedResponse };
    } catch (error) {
      return handleError(error, "Error fetching usage log");
    }
  };

  /**
   * @description Fetches super admin details for the specified organization
   * @returns {Promise<ApiResponse>} - Result object with success status and data or error message.
   */
  const getSuperAdminDetails = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_superadmin_details");
      const camelizedResponse = toCamelCase(response.data, true);
      updateSuperAdminInvoices(camelizedResponse.invoices);
      updateSuperAdminOrganizations(camelizedResponse.organizations);
      updateSuperAdminOverview(camelizedResponse.overview);

      return { success: true, data: camelizedResponse };
    } catch (error) {
      return handleError(error, "Error fetching usage log");
    }
  };

  return {
    createUserPostgres,
    createOrganization,
    deleteOrganization,
    updateOrganization,
    getOrganizations,
    getUsers,
    addNewUser,
    getUserHash,
    deleteUser,
    updateUserRole,
    disableUser,
    enableUser,
    getAdminDetailsForOrg,
    getSuperAdminDetails,
  };
};

export default useAdminManagement;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useProcessReferences } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { ParentType } from "@/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { CheckboxWithText } from "@/components/ui/checkbox";
import PatentNumbersTextField from "@/components/patents/PatentNumbersTextField";

interface AddReferenceByNumberModalProps {
  open: boolean;
  handleClose: () => void;
  general: boolean;
}

/**
 * @description Modal for adding references by number
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 * @param {boolean} general - Whether the modal is for general references
 */
const AddReferenceByNumberModal: React.FC<AddReferenceByNumberModalProps> = ({
  open,
  handleClose,
  general,
}) => {
  const { addAndProcessReferences } = useProcessReferences();
  const { currentProject, currentProjectId, currentParent, currentPortfolioId } =
    useProjectStore();
  const { addWarningMessage } = useAppStateStore();

  // Modal state
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);

  // Reset the modal state on close
  const handleResetModal = () => {
    setIsCheckboxChecked(true);
    handleClose();
  };

  const handleAddClick = async (refNums: string[]) => {
    handleClose();
    if (refNums.length > 0) {
      let filteredReferenceNumbers = refNums;
      let existingReferences: string[] = [];
      if (currentProject.references.length > 0) {
        const existingReferenceNumbers = currentProject.references.map(
          (ref) => ref.number || ref.reference_number,
        );
        // Filter out the reference numbers that are already in the project
        filteredReferenceNumbers = refNums.filter((number) => {
          if (existingReferenceNumbers.includes(number)) {
            existingReferences.push(number);
            return false;
          }
          return true;
        });

        if (existingReferences.length > 0) {
          // Show warning messages for existing references
          addWarningMessage(
            `The following references already exist in the project: ${existingReferences.join(
              ", ",
            )}`,
          );
        }
        // If there are no new reference numbers to add, return
        if (filteredReferenceNumbers.length === 0) {
          return;
        }
      }

      await addAndProcessReferences(
        currentProjectId,
        currentProject.name,
        filteredReferenceNumbers,
        isCheckboxChecked,
        true, // displayLoadingMessages
        currentParent === ParentType.PORTFOLIO ? currentPortfolioId : undefined,
      );
    }

    setIsCheckboxChecked(true);
  };

  return (
    <Dialog open={open} onOpenChange={handleResetModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add by number</DialogTitle>
          <DialogDescription>
            Add references by entering a comma-separated list of published patents and
            applications numbers. A country prefix is required.
          </DialogDescription>
        </DialogHeader>

        {!general && (
          <CheckboxWithText
            id="complete-charts"
            checked={isCheckboxChecked}
            onCheckedChange={(checked) => setIsCheckboxChecked(checked as boolean)}
            label="Complete claim and feature chart(s)"
          />
        )}

        <PatentNumbersTextField
          isPortfolio={currentParent === ParentType.PORTFOLIO}
          handleAdd={handleAddClick}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddReferenceByNumberModal;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { Patent } from "@/types";
import { SourceNumberButton } from "@/components";

interface AssigneePortfolioTableProps {
  patents: Patent[];
  setSelectedReferences?: (references: string[]) => void;
  selectedReferences?: string[];
}

/**
 * @description Assignee portfolio table displayed in create portfolio subject step
 * @param {Patent[]} patents - The list of patents to display.
 * @param {function} setSelectedReferences - Function to set the selected references.
 * @param {string[]} selectedReferences - The list of selected references.
 */
const AssigneePortfolioTable: React.FC<AssigneePortfolioTableProps> = ({
  patents,
  setSelectedReferences,
  selectedReferences,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, ref: Patent) => {
    let newItem;

    newItem = ref.number;
    if (event.target.checked) {
      const newSelected = [...(selectedReferences || []), newItem];
      setSelectedReferences?.(newSelected);
    } else {
      const newSelected = (selectedReferences || []).filter((item) => item !== newItem);
      setSelectedReferences?.(newSelected);
    }
  };

  useEffect(() => {
    if (selectAll) {
      const allReferences = patents.map((patent) => patent.number);
      setSelectedReferences?.(allReferences);
    } else if (selectedReferences?.length === patents.length) {
      setSelectedReferences?.([]);
    }
  }, [selectAll]);

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
  };

  return (
    <ScrollArea className="h-[calc(80vh-64px)]">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Number</TableHead>
            <TableHead>Title</TableHead>
            <TableHead className="whitespace-nowrap">Priority Date</TableHead>
            <TableHead>Inventor</TableHead>
            <TableHead>Assignee</TableHead>
            <TableHead>Classifications</TableHead>
            <TableHead className="text-right">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Checkbox
                      checked={selectAll}
                      onCheckedChange={(checked) => {
                        setSelectAll(checked as boolean);
                        handleSelectAll({
                          target: { checked },
                        } as ChangeEvent<HTMLInputElement>);
                      }}
                    />
                  </TooltipTrigger>
                  <TooltipContent>Select All</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {patents &&
            patents
              .filter((reference) => reference.number)
              .map((reference) => {
                const isSelected = selectedReferences?.includes(reference.number);
                return (
                  <TableRow
                    key={reference.number}
                    className={isSelected ? "bg-muted/50" : ""}
                  >
                    <TableCellCondensed>
                      {reference.number && reference.number.length < 20 ? (
                        <SourceNumberButton
                          sourceNumber={reference.number}
                          prefix={reference.prefix || ""}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCellCondensed>
                    <TableCellCondensed>{reference.title || "-"}</TableCellCondensed>
                    <TableCellCondensed>
                      {reference.filingDate || reference.priorityDate
                        ? new Date(
                            reference.filingDate || reference.priorityDate,
                          ).toLocaleDateString()
                        : "—"}
                    </TableCellCondensed>
                    <TableCellCondensed>
                      {reference.inventors
                        ? reference.inventors.replace(/,\s*$/, "")
                        : "-"}
                    </TableCellCondensed>
                    <TableCellCondensed>{reference.assignee || "-"}</TableCellCondensed>
                    <TableCellCondensed>
                      {reference.cpcCodes
                        ? reference.cpcCodes.map((code, index) => (
                            <Badge key={index} variant="outline" className="mr-1 mb-1">
                              {code.trim()}
                            </Badge>
                          ))
                        : "-"}
                    </TableCellCondensed>
                    <TableCellCondensed className="text-right">
                      <Checkbox
                        checked={(selectedReferences || []).includes(reference.number)}
                        onCheckedChange={(checked) => {
                          handleSelect(
                            {
                              target: { checked },
                            } as ChangeEvent<HTMLInputElement>,
                            reference,
                          );
                        }}
                      />
                    </TableCellCondensed>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </ScrollArea>
  );
};

export default AssigneePortfolioTable;

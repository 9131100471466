/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useCreateProjectStore } from "@/store";
import { useApi, useCreateProject, useLlm } from "@/hooks";
import {
  ProjectDetailsStep,
  ApplicationSubjectStep,
  CreateProjectPageLayout,
} from "@/features/project/create/components";
import { ProjectType } from "@/types";

/**
 * @description Renders the create application project page
 */
const CreateApplicationProjectPage: React.FC = () => {
  const { createProjectFromLanguage } = useCreateProject();

  // Global state from store
  const {
    updateIsProjectCreationInProgress,
    isProjectCreationInProgress,
    resetCreateProjectStore,
    projectName,
    claims,
    updateClaims, // Make sure this function is available in your store
    features,
    subjectMode,
    disclosure_files,
  } = useCreateProjectStore();

  const { generatePatent } = useLlm();
  // Local state setup
  const [patentNotFound, setPatentNotFound] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetCreateProjectStore();
    }
  }, []);

  useEffect(() => {
    if (
      (subjectMode === "claim" &&
        claims.length > 0 &&
        JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "feature" &&
        features.length > 0 &&
        JSON.stringify(features[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "disclosure" && disclosure_files.length > 0) // Updated condition
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [claims, features, disclosure_files, subjectMode]);

  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // useEffect(() => {
  //   const handleFileUpload = async () => {
  //     if (uploadedFile && subjectMode === "file") {
  //       try {
  //         const response = await generatePatent(uploadedFile);
  //         if ("data" in response) {
  //           updateClaims(response.data.claims);
  //           // If you have a function to update abstract in your store, uncomment the next line
  //           // updateAbstract(response.abstract);
  //           setIsSubjectCompleted(true);
  //         } else {
  //           console.error("Unexpected response format from generatePatent");
  //           setIsSubjectCompleted(false);
  //         }
  //       } catch (error) {
  //         console.error("Error generating patent from file:", error);
  //         setIsSubjectCompleted(false);
  //       }
  //     }
  //   };

  //   handleFileUpload();
  // }, [uploadedFile, subjectMode, generatePatent, updateClaims]);

  // Handle confirm action (remains unchanged)
  const handleConfirm = async () => {
    if (!projectName) {
      return;
    }
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    await createProjectFromLanguage(ProjectType.APP);
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetCreateProjectStore}
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handleConfirm}
      isPortfolio={false}
      title="New Patent Application Project"
    >
      {/* Project details */}
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />

      {/* Application subject */}
      <ApplicationSubjectStep
        startExpanded={false}
        onFileUpload={setUploadedFile} // Add this prop
      />
    </CreateProjectPageLayout>
  );
};

export default CreateApplicationProjectPage;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { ElementType } from "@/types";
import { ElementEditor } from "@/features/project/components";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { cn } from "@/lib/utils";

/**
 * @description Feature/claim editor step displayed in create project flow subject step
 * @param {function} setItems - Function to set the items.
 * @param {boolean} isUpdateMode - Whether the mode is update.
 * @param {boolean} startInEditMode - Whether the mode is edit.
 * @param {object[]} initialItems - The initial items.
 * @param {ElementType} itemType - The type of item.
 * @param {boolean} showCollapse - Whether to show the collapse.
 */
const ElementEditStep: React.FC<{
  setItems: (items: { [key: string]: string }[]) => void;
  isUpdateMode: boolean;
  startInEditMode: boolean;
  initialItems: { [key: string]: string }[];
  itemType: ElementType;
  showCollapse?: boolean;
}> = ({
  setItems,
  isUpdateMode,
  startInEditMode,
  initialItems,
  itemType,
  showCollapse = false,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className="w-full border rounded-lg mt-3">
      {showCollapse ? (
        <Collapsible open={isExpanded} onOpenChange={setIsExpanded} className="w-full">
          <CollapsibleTrigger asChild>
            <Button
              variant="ghost"
              className={cn(
                "flex items-center justify-between w-full p-4 text-left",
                "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                { "rounded-b-none": isExpanded },
              )}
            >
              <span className="text-base font-medium text-gray-900">
                {itemType === ElementType.FEATURE ? "Features" : "Claims"}
              </span>
              {isExpanded ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent className="rounded-b-lg">
            <ElementEditor
              setItems={setItems}
              isUpdateMode={isUpdateMode}
              startInEditMode={startInEditMode}
              initialItems={initialItems}
              itemType={itemType}
              useCurrentSubject={false}
            />
          </CollapsibleContent>
        </Collapsible>
      ) : (
        <ElementEditor
          setItems={setItems}
          isUpdateMode={isUpdateMode}
          startInEditMode={startInEditMode}
          initialItems={initialItems}
          itemType={itemType}
          useCurrentSubject={false}
        />
      )}
    </div>
  );
};

export default ElementEditStep;

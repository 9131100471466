/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect, useMemo } from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import { useVector, useDataTable } from "@/hooks";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DataTableCell } from "@/components/ui/table";
import { MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";
import { ReferenceTableCitation } from "./index";
import { InvalidityCitation, ElementType } from "@/types";
import ReferenceTableCollapsibleFeedbackModal from "./ReferenceTableCollapsibleFeedbackModal";
import { useFeedbackData } from "@/hooks/useFeedbackData";

interface ReferenceTableCitationCellProps {
  index: number;
  refId: string;
  referenceColumnWidth: string;
  claimNumber: string;
  claimText: string;
  invalidityId: string;
  citations: InvalidityCitation[];
  fetchReferenceChartData: () => void;
  addingCitationKey: string;
  setAddingCitationKey: (key: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  // onAddDeletedCitation;
}

/**
 * @description Reference table citation cell
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} referenceColumnWidth - The width of the reference column.
 * @param {string} claimNumber - The claim number.
 * @param {string} claimText - The claim text.
 * @param {string} invalidityId - The id of the invalidity.
 * @param {InvalidityCitation[]} citations - The citations.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {boolean} isEditing - Whether the citation is being edited.
 */
const ReferenceTableCitationCell: React.FC<ReferenceTableCitationCellProps> = ({
  index,
  refId,
  referenceColumnWidth,
  claimNumber,
  claimText,
  invalidityId,
  citations = [],
  fetchReferenceChartData,
  addingCitationKey,
  setAddingCitationKey,
  setIsEditing,
  isEditing,
  // onAddDeletedCitation
}) => {
  const { retrieveInvalidityForClaim } = useVector();
  const { currentProjectId, elementEditIndex, selectedElementType } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { retrieveInvalidityForItem, handleAddCitation } = useDataTable();
  const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
    currentProjectId,
    refId,
    claimNumber,
  );

  const [isInvalidityLoading, setIsInvalidityLoading] = useState(false);
  const [showNoMoreCitations, setShowNoMoreCitations] = useState(false);

  const positiveFeedbackIds = new Set(positiveFeedbackData.map((c) => c.id));
  const negativeFeedbackIds = new Set(negativeFeedbackData.map((c) => c.id));

  const shownCitations = citations.filter(
    (citation) => !citation.removed,
    // &&
    //   !positiveFeedbackIds.has(citation.id) &&
    //   !negativeFeedbackIds.has(citation.id)
  );

  const removedCitations = citations.filter((citation) => citation.removed === true);

  const retrieveInvalidity = async (index: number, refId: string) => {
    setIsInvalidityLoading(true);
    const response = await retrieveInvalidityForClaim(
      currentProjectId,
      claimNumber,
      refId,
      claimText,
      selectedElementType === ElementType.FEATURE,
      positiveFeedbackData.map((citation) => citation.text),
      negativeFeedbackData.map((citation) => citation.text),
    );
    if (!response.success && response.status === 404) {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
      return;
    } else if (!response.success) {
      addErrorMessage(`Error retrieving invalidity for claim ${claimNumber}. `);
      setIsInvalidityLoading(false);
      setIsInvalidityLoading(false);
      return;
    }
    let itemResponse = response.data[refId];
    if (itemResponse && itemResponse.length > 0) {
      retrieveInvalidityForItem(index, refId, itemResponse);
    } else {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
    }
    fetchReferenceChartData();
    setIsInvalidityLoading(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showNoMoreCitations) {
      timer = setTimeout(() => {
        setShowNoMoreCitations(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showNoMoreCitations]);

  return (
    <DataTableCell style={{ width: `${referenceColumnWidth}px` }} className="gap-2">
      {shownCitations.map((citation, citationIndex) => (
        <ReferenceTableCitation
          key={`${index}-${refId}-${citationIndex}`}
          citationKey={`${index}-${refId}-${citationIndex}`}
          citation={citation}
          index={index}
          refId={refId}
          claimNumber={claimNumber}
          citationIndex={citationIndex}
          invalidityId={invalidityId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          addingCitationKey={addingCitationKey}
          setAddingCitationKey={setAddingCitationKey}
        />
      ))}
      {showNoMoreCitations && (
        <div className="mb-5 text-center">
          <p className="text-sm text-muted-foreground">No more citations found</p>
        </div>
      )}
      {(isInvalidityLoading || elementEditIndex === index) && (
        <div className="mb-2">
          <Loader message="Retrieving invalidity..." />
        </div>
      )}
      <ReferenceTableCollapsibleFeedbackModal
        positiveFeedbackData={positiveFeedbackData}
        negativeFeedbackData={negativeFeedbackData}
        removedCitations={removedCitations}
        refId={refId}
        claimNumber={claimNumber}
        currentProjectId={currentProjectId}
        // onAddDeletedCitation={}
      />
      <div className="flex items-center justify-center space-x-2 ">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={isEditing || isInvalidityLoading}
                onClick={() => retrieveInvalidity(index, refId)}
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
                <span className="sr-only">Find more citations</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Find more</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={isEditing || isInvalidityLoading}
                onClick={() =>
                  handleAddCitation(index, refId, setIsEditing, setAddingCitationKey)
                }
              >
                <PlusIcon className="h-4 w-4" />
                <span className="sr-only">Add citation</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add citation</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </DataTableCell>
  );
};

export default ReferenceTableCitationCell;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect, useMemo } from "react";
import { useUserStore, useAdminStore } from "@/store";
import { useAdminManagement } from "@/hooks";
import { Loader } from "@/components";
import {
  AdminPageLayout,
  InvoicesTable,
  PlanDetailsCard,
  CurrentBillingDetailsCard,
  NoAccessMessage,
} from "@/features/admin/components";
import { RoleType } from "@/types";
/**
 * @description Admin page component
 */
function AdminBillingPage() {
  const { getAdminDetailsForOrg } = useAdminManagement();
  const { organizationId, role } = useUserStore();
  const { invoices } = useAdminStore();

  // Local state setup
  const [error, setError] = useState(false);

  // Fetch organization usage statistics
  useEffect(() => {
    if (!invoices) {
      getAdminDetailsForOrg(organizationId).catch(() => setError(true));
    }
  }, [organizationId, invoices, getAdminDetailsForOrg]);

  const isAdmin = useMemo(
    () => role === RoleType.ADMIN || role === RoleType.OWNER,
    [role],
  );

  return (
    <AdminPageLayout isSuperAdmin={false} pageName="Admin - Billing">
      {isAdmin ? (
        <div className="mt-3 space-y-8">
          {invoices === null ? (
            <div className="mt-10">
              <Loader />
            </div>
          ) : error ? (
            <p className="text-destructive">Error fetching organization details.</p>
          ) : (
            <div className="space-y-4">
              <div className="flex gap-3">
                <PlanDetailsCard />
                <CurrentBillingDetailsCard />
              </div>
              {invoices.length > 0 && <InvoicesTable invoices={invoices} />}
            </div>
          )}
        </div>
      ) : (
        <NoAccessMessage />
      )}
    </AdminPageLayout>
  );
}

export default AdminBillingPage;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SourceNumberButton } from "@/components";
import { Patent } from "@/types";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

interface PatentDetailsTableProps {
  details: Patent[];
  inUploadFilesModal?: boolean;
  handleProcessAsPDF?: (number: string) => void;
  height?: string;
}

/**
 * @description Document metadata table displayed in create project flow
 * @param {Patent[]} details - The list of patents to display.
 * @param {boolean} inUploadFilesModal - Whether the documents are being displayed in the upload files modal.
 * @param {function} handleProcessAsPDF - Function to process a patent as a PDF.
 */
const PatentDetailsTable: React.FC<PatentDetailsTableProps> = ({
  details,
  inUploadFilesModal,
  handleProcessAsPDF,
  height,
}) => {
  const formatInventorName = (name: string) => {
    if (Array.isArray(name)) {
      return name.map((n) => formatInventorName(n)).join(", ");
    }
    if (name && name.includes(",") && name === name.toUpperCase()) {
      const [lastName, firstName] = name.split(",").map((part) => part.trim());
      return `${firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()} ${
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()
      }`;
    }
    return name;
  };

  const renderName = (name: string) => {
    if (inUploadFilesModal && name.length > 50) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger style={{ textAlign: "left" }}>
              {name.substring(0, 50) + "..."}
            </TooltipTrigger>
            <TooltipContent>{name}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
    return name;
  };

  return (
    <div
      className={cn(
        height,
        "border border-border rounded-lg overflow-y-auto overflow-x-auto",
      )}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Number</TableHead>
            <TableHead>Title</TableHead>

            <TableHead>Publication</TableHead>

            <TableHead>Priority</TableHead>
            <TableHead>Inventors</TableHead>
            <TableHead>Assignee</TableHead>
            {inUploadFilesModal && <TableHead />}
          </TableRow>
        </TableHeader>
        <TableBody>
          {details.map((detail) => (
            <TableRow key={detail.id}>
              <TableCellCondensed className="whitespace-nowrap">
                <SourceNumberButton
                  sourceNumber={detail.number}
                  prefix={detail.prefix || ""}
                />
              </TableCellCondensed>
              <TableCellCondensed>{renderName(detail.name)}</TableCellCondensed>
              <TableCellCondensed className="whitespace-nowrap">
                {new Date(detail.publicationDate).toLocaleDateString() || "—"}
              </TableCellCondensed>
              <TableCellCondensed className="whitespace-nowrap">
                {new Date(detail.filingDate).toLocaleDateString() || "—"}
              </TableCellCondensed>
              <TableCellCondensed>
                {inUploadFilesModal
                  ? detail.inventors
                    ? formatInventorName(detail.inventors[0])
                    : "—"
                  : detail.inventors
                    ? formatInventorName(detail.inventors)
                    : "—"}
              </TableCellCondensed>
              <TableCellCondensed>{detail.assignee || "—"}</TableCellCondensed>
              {inUploadFilesModal && (
                <TableCellCondensed>
                  <Button
                    onClick={() => handleProcessAsPDF(detail.number)}
                    variant="ghost"
                  >
                    Process as PDF
                  </Button>
                </TableCellCondensed>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PatentDetailsTable;

import { create } from "zustand";
import {
  AlertMessage,
  LoadingMessage,
  LoadingGroup,
  LoadingGroupItem,
  StatusType,
} from "@/types";

interface AppState {
  isLoading: boolean;
  loadingGroups: LoadingGroup[];
  statusMessages: AlertMessage[];
  loadingMessages: LoadingMessage[];

  isStoreEmpty: boolean;
  searchChatProjectId: string;
  updateIsLoading: (isLoading: boolean) => void;
  updateIsStoreEmpty: (isStoreEmpty: boolean) => void;
  updateSearchChatProjectId: (searchChatProjectId: string) => void;
  addErrorMessage: (message: string) => void;
  addLoadingGroupItem: (
    groupKey: string,
    processType: string,
    itemName: string,
    status?: StatusType,
    detail?: string,
  ) => void;
  removeLoadingGroup: (groupKey: string, processType: string) => void;
  updateLoadingGroupItem: (
    groupKey: string,
    processType: string,
    itemName: string,
    status: StatusType,
    detail?: string,
  ) => void;
  clearLoadingGroups: () => void;
  addSuccessMessage: (message: string) => void;
  removeStatusMessage: (key: number) => void;
  addLoadingMessage: (message: string, key: string) => void;
  addWarningMessage: (message: string) => void;
  removeLoadingMessage: (key: string) => void;

  addLoadingGroup: (
    key: string,
    name: string,
    processType: string,
    messages?: LoadingGroupItem[],
  ) => void;
}

const initialState: Omit<
  AppState,
  | "updateIsLoading"
  | "updateOrgUsers"
  | "updateIsStoreEmpty"
  | "updateSearchChatProjectId"
  | "addErrorMessage"
  | "addSuccessMessage"
  | "removeStatusMessage"
  | "updateCreateProjectSpinnerText"
  | "updateCreateProjectError"
  | "addLoadingMessage"
  | "removeLoadingMessage"
  | "addLoadingGroupItem"
  | "removeLoadingGroup"
  | "addLoadingGroup"
  | "updateLoadingGroupItem"
  | "clearLoadingGroups"
  | "addWarningMessage"
> = {
  isLoading: false,
  loadingMessages: [],
  statusMessages: [],
  isStoreEmpty: true,
  searchChatProjectId: "",
  loadingGroups: [],
};

const useAppStateStore = create<AppState>((set) => ({
  ...initialState,

  updateIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  updateIsStoreEmpty: (isStoreEmpty: boolean) => set(() => ({ isStoreEmpty })),

  updateSearchChatProjectId: (searchChatProjectId: string) =>
    set(() => ({ searchChatProjectId })),

  addErrorMessage: (message: string) =>
    set((state) => ({
      statusMessages: [
        ...state.statusMessages,
        { message, key: new Date().getTime(), severity: StatusType.ERROR },
      ],
    })),

  addSuccessMessage: (message: string) =>
    set((state) => ({
      statusMessages: [
        ...state.statusMessages,
        { message, key: new Date().getTime(), severity: StatusType.SUCCESS },
      ],
    })),

  removeStatusMessage: (key: number) =>
    set((state) => ({
      statusMessages: state.statusMessages.filter((msg) => msg.key !== key),
    })),

  addLoadingMessage: (message: string, key: string) =>
    set((state) => ({
      loadingMessages: [...state.loadingMessages, { message, key }],
    })),

  removeLoadingMessage: (key: string) =>
    set((state) => ({
      loadingMessages: state.loadingMessages.filter((msg) => msg.key !== key),
    })),

  addLoadingGroup: (
    key: string,
    name: string,
    processType: string,
    messages?: LoadingGroupItem[],
  ) =>
    set((state) => {
      const existingGroup = state.loadingGroups.find(
        (group) => group.key === key && group.processType === processType,
      );

      if (existingGroup) {
        // If the group already exists, don't add a duplicate
        return state;
      }

      return {
        loadingGroups: [
          ...state.loadingGroups,
          { key, name, processType, messages: messages || [] },
        ],
      };
    }),

  removeLoadingGroup: (groupKey: string, processType: string) =>
    set((state) => ({
      loadingGroups: state.loadingGroups.filter(
        (group) => !(group.key === groupKey && group.processType === processType),
      ),
    })),

  addLoadingGroupItem: (
    groupKey: string,
    processType: string,
    itemName: string,
    status?: StatusType,
    detail?: string,
  ) =>
    set((state) => ({
      loadingGroups: state.loadingGroups.map((group) =>
        group.key === groupKey && group.processType === processType
          ? {
              ...group,
              messages: [...group.messages, { name: itemName, status, detail }],
            }
          : group,
      ),
    })),

  updateLoadingGroupItem: (
    groupKey: string,
    processType: string,
    itemName: string,
    status: StatusType,
    detail?: string,
  ) =>
    set((state) => ({
      loadingGroups: state.loadingGroups.map((group) =>
        group.key === groupKey && group.processType === processType
          ? {
              ...group,
              messages: group.messages.map((msg) =>
                msg.name === itemName ? { ...msg, status, detail } : msg,
              ),
            }
          : group,
      ),
    })),

  clearLoadingGroups: () => set(() => ({ loadingGroups: [] })),

  addWarningMessage: (message: string) =>
    set((state) => ({
      statusMessages: [
        ...state.statusMessages,
        { message, key: new Date().getTime(), severity: StatusType.WARNING },
      ],
    })),
}));

export default useAppStateStore;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DateTime } from "luxon";
import { User, Organization } from "./admin";

export const PATENT_TYPES = ["Application", "Issued Asset", "International"];
export const PATENT_STATUSES = ["Active", "Inactive"];

export interface Project {
  id: string;
  project_id?: string;
  name: string;
  subjectId?: string;
  referenceIds?: string[];
  references?: any[];
  documentIds?: string[];
  keywords?: string[];
  features?: any[];
  context?: PatentContext;
  fullBody?: any[];
  claims?: any[];
  inventors?: any[];
  primaryExaminer?: any[];
  assignee?: any[];
  publicationDate?: any[];
  filingDate?: any;
  notes?: any[];
  number?: string;
  type?: string;
  owner?: string;
  createdBy?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  clientNumber?: string;
  documentsToNumbers?: { [key: string]: string };
  documentsToNicknames?: { [key: string]: string };
  isPortfolio?: boolean;
  projects?: any[];
  introductionLanguage?: string;
  prefix?: string;
  priorityDate?: DateTime;

  subject?: {
    name: string;
    id: string;
    number: string;
    nickname: string;
    abstract: string;
    publicationDate: string;
    filingDate: string;
    inventors: string;
    assignee: string;
    primaryExaminer: string;
    prefix?: string;
  };
}

export interface PatentContext {
  expertKnowledge: string;
  termsAndDefinitions: Record<string, string>;
  importantFacts: string[];
  priorArtSearchInstructions: string;
  priorArtSearchFilterInstructions: string;
  relevantDocumentIds: string[];
}

export interface SearchResult {
  referenceId: string;
  paragraph: string;
  text: string;
  color?: string;
  figureUrls?: string[];
  prefix?: string;
}

export interface Message {
  text?: string;
  sender: string;
  loading?: boolean;
}

export interface InvalidityCitation {
  id: string;
  text: string;
  paragraph: string;
  color?: string;
  figureUrls?: string[];
  score?: number;
  figureRefs?: string[];
  invalidityId?: string;
  organizationId?: string;
  removed: boolean;
  updatedById?: string;
  updatedAt: string;
  feedback?: "positive" | "negative";
}

export interface Element {
  color: string;
  label: string;
  language: string;
}

export interface Patent {
  id: string;
  name?: string;
  number?: string;
  nickname?: string;
  abstract?: string;
  claims?: any[];
  features?: any[];
  body?: { text: string }[];
  fullBody?: any[];
  publicationDate?: string;
  filingDate?: string;
  inventors?: string;
  assignee?: string;
  primaryExaminer?: string;
  note?: string;
  notes?: string;
  figureUrls?: string[];
  referenceId?: string;
  isPatent?: boolean;
  referenceNumber?: string;
  referenceName?: string;
  referenceNickname?: string;
  priorityDate?: string;
  title?: string;
  cpcCodes?: string[];
  cpcMain?: string[];
  cpcAdditional?: string[];
  prefix?: string;
  subjects?: { projectId: string; projectName: string }[];
  ids?: { projectId: string; referenceId: string }[];
  url?: string;
  type?: DocumentType;
  tags?: string[];
  status?: DocumentStatus;
  projectId?: string;
}

export interface Reference {
  id: string;
  name: string;
  publicationDate: string;
  authors: string[];
  figureUrls: string[];
  uploadedAt?: string;
  projects?: {
    [projectId: string]: {
      id: string;
      name: string;
      type: string;
    };
  };
}

export interface PatentInfo {
  abstract: string;
  claims: any[];
  description: string;
}

export interface Invalidity {
  id: string;
  documentId: string;
  claimNumber: string;
  isFeature: boolean;
  projectId: string;
  project: string;
  organizationId: string;
  organization: string;
  citations: InvalidityCitation[];
}

export interface ChatSession {
  id: string;
  userId: string;
  user: User;
  messages: Message[];
  createdAt: DateTime;
  projectId: string;
  project: Project;
  organizationId: string;
  organization: Organization;
}

export interface ProjectUser {
  userId: string;
  user: User;
  projectId: string;
  project: Project;
}

export interface SummaryChartData {
  claimNumber: string;
  [key: string]: any;
}

export enum ParentType {
  PROJECT = "PROJECT",
  PORTFOLIO = "PORTFOLIO",
}

export enum ProjectType {
  PFA = "PFA",
  PFP = "PFP",
  PFC = "PFC",
  INV = "INV",
  FTO = "FTO",
  SEP = "SEP",
  ROA = "ROA",
  APP = "APP",
}

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum ElementType {
  CLAIM = "claim",
  FEATURE = "feature",
  EVALUATE = "evaluate",
}

export enum ChartColor {
  GREEN = "#C1E1A6",
  YELLOW = "#FFDE7D",
  RED = "#F5A19B",
  GRAY = "#D3D3D3",
  BLUE = "#A6C6E0",
}

export enum DocumentType {
  PATENT = "PATENT",
  REFERENCE = "REFERENCE",
}

export enum DocumentStatus {
  PROCESSED = "PROCESSED",
  REPROCESS = "REPROCESS",
  REUPLOAD = "REUPLOAD",
  RECHART = "RECHART",
  PENDING = "PENDING",
}

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "@/store";
import { useApi, useViz } from "@/hooks";
import { ApiResponse, ElementType, ParentType } from "@/types";
import { toCamelCase } from "@/utils/dataUtils";
import { useClearFeedbackDataByID } from "@/hooks/useFeedbackData";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProject = () => {
  const { postRequest, getRequest, handleError, postRequestFile } = useApi();
  const {
    updateCurrentProject,
    selectedReferences,
    removeSelectedReferences,
    updateCurrentSubject,
    updateChartData,
    updateSummaryChartData,
    updateSelectedReferences,
    updateSelectedElements,
    updateSelectedColors,
    updateSelectedElementType,
    updateElementEditIndex,
    currentParent,
    currentProject,
    currentProjectId,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const { getPortfolioMetadata } = useViz();
  const clearFeedbackChartDataForReferences = useClearFeedbackDataByID();

  /**
   * @description Adds a user to a project
   * @param {string} projectId - The id of the project to add the user to
   * @param {string} userEmail - The email of the user to add to the project
   */
  const addUserToProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Error adding users to the project`);
    }
  };

  /**
   * @description Removes a user from a project
   * @param {string} projectId - The id of the project to remove the user from
   * @param {string} userEmail - The email of the user to remove from the project
   */
  const removeUserFromProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Failed to remove users from the project`);
    }
  };

  /**
   * @description Fetches all users for a project
   * @param {string} projectId - The id of the project to fetch users for
   */
  const getUsersForProject = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_project", {
        project_id: projectId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Checks if a user has access to a project
   * @param {string} projectId - The id of the project to check access for
   */
  const getUserProjectAccessCheck = async (
    projectId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_user_project_access_check", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching user project access check");
    }
  };

  /**
   * @description Fetches the owner of a project
   * @param {string} projectId - The id of the project to fetch the owner for
   */
  const getProjectOwnerData = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_owner_data", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching project owner data");
    }
  };

  /**
   * @description Requests access to a project
   * @param {string} projectId - The id of the project to request access to
   * @param {string} userEmail - The email of the user to request access to
   * @param {string} projectNickname - The nickname of the project
   */
  const requestProjectAccess = async (
    projectId: string,
    userEmail: string,
    projectNickname: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_request_project_access_email", {
        project_nickname: projectNickname,
        owner_email: userEmail,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error requesting access to project");
    }
  };

  /**
   * @description Sends an email to grant access to a project
   * @param {string} email - The email of the user to grant access to
   * @param {string} projectName - The name of the project
   * @param {string} projectId - The id of the project
   */
  const sendProjectAccessGrantedEmail = async (
    email: string,
    projectName: string,
    projectId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_granted_project_access_email", {
        granted_email: email,
        project_nickname: projectName,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting granted project access email");
    }
  };

  /**
   * @description Attaches reference ids to a project
   * @param {string} project_id - The id of the project to attach the reference ids to
   * @param {array} reference_ids - The ids of the references to attach to the project
   */
  const attachReferenceIdsToProject = async (
    project_id: string,
    reference_ids: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_attach_reference_ids_to_project", {
        project_id: project_id,
        reference_ids: reference_ids,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error attaching reference ids to project");
    }
  };

  /**
   * @description Deletes a reference from a project
   * @param {string} projectId - The id of the project to delete the references from
   * @param {string[]} referenceIds - The ids of the references to delete
   */
  const deleteReferencesFromProject = async (
    projectId: string,
    referenceIds: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("delete_references_from_project", {
        project_id: projectId,
        reference_ids: referenceIds,
      });
      clearFeedbackChartDataForReferences(projectId, referenceIds);

      // Update current project if it is the current project
      if (currentProjectId === projectId) {
        const newReferenceIds = currentProject.referenceIds.filter(
          (id) => !referenceIds.includes(id),
        );
        const newreferences = currentProject.references.filter(
          (ref) => !referenceIds.includes(ref.id),
        );
        updateCurrentProject({
          ...currentProject,
          referenceIds: newReferenceIds,
          references: newreferences,
        });
      }

      // Remove reference from selected references if it is selected
      if (selectedReferences !== null) {
        removeSelectedReferences(referenceIds);
      }

      // Update portfolio if the project is in the current portfolio
      if (
        currentParent === ParentType.PORTFOLIO &&
        currentPortfolio.projects?.map((p) => p.id).includes(currentProjectId)
      ) {
        await getPortfolioMetadata(currentPortfolioId);
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      console.error("Error details:", error);
      return handleError(error, "Error deleting reference from project");
    }
  };

  /**
   * @description Fetches the project metadata
   * @param {string} projectId - The id of the project to fetch the metadata for
   */
  const getProjectMetadata = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_metadata", {
        project_id: projectId,
      });
      const projectMetadata = response.data;

      // Update project details in the store
      updateCurrentProject({
        id: projectMetadata.id,
        name: projectMetadata.name,
        subjectId: projectMetadata.subject.id,
        referenceIds: projectMetadata.documents.map((reference: any) => reference.id),
        references: toCamelCase(projectMetadata.documents, true),
        documentIds: [
          projectMetadata.subject.id,
          ...projectMetadata.documents.map((reference: any) => reference.id),
        ],
        priorityDate: projectMetadata.priority_date,
        keywords: projectMetadata.keywords,
        context: projectMetadata.context,
        type: projectMetadata.type,
        owner: projectMetadata.created_by,
        documentsToNumbers: projectMetadata.documents_to_numbers,
        documentsToNicknames: projectMetadata.documents_to_nicknames,
        introductionLanguage: projectMetadata.introduction_language,
        features: projectMetadata.features,
        claims: projectMetadata.claims,
      });
      const subjectDetails = toCamelCase(projectMetadata.subject);
      updateCurrentSubject({
        ...subjectDetails,
      });

      updateSelectedElements([]);
      updateSelectedColors([]);
      updateSelectedElementType(ElementType.CLAIM);
      updateSelectedReferences([]);
      updateElementEditIndex(-1);
      return { success: true, data: projectMetadata };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    }
  };

  /**
   * @description Get project metadata and summary chart data
   * @param {string} projectId - The id of the project to fetch the data for
   */
  const fetchProjectData = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getProjectMetadata(projectId);
      // await getDocumentsToNumbers(projectId);
      updateSummaryChartData([]);
      updateChartData([]);

      if ("data" in response) {
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      return handleError(error, "Error fetching project data");
    }
  };

  /**
   * @description Adds a PDF to a project
   * @param {string} projectId - The id of the project to add the PDF to
   * @param {File} file - The file to add to the project
   * @param {object} options - Additional options to pass to the request
   */
  const addPDFToProject = async (
    projectId: string,
    file: File,
    is_portfolio_creation: boolean,
  ): Promise<ApiResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", projectId);

    try {
      const response = await postRequestFile("post_sync_reducto_job", formData);
      if (!is_portfolio_creation && response.data) {
        await addToDocumentsAdded(
          currentParent === ParentType.PORTFOLIO
            ? currentPortfolioId
            : currentProjectId,
          [response.data.reference_id],
          currentParent === ParentType.PORTFOLIO,
        );
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Failed to add reference to project");
    }
  };

  /**
   * @description Adds multiple references to a project
   * @param {string} projectId - The id of the project to add the references to
   * @param {array} reference_numbers - The numbers of the references to add to the project
   * @param {string} is_citation - Whether the references are citations or not
   */
  const addReferencesToProject = async (
    projectId: string,
    referenceNumbers: string[],
    isCitation: boolean = false,
    isPortfolioCreation: boolean = false,
    isCurrentParentPortfolio: boolean = false,
    portfolioId: string = "",
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_references_to_project", {
        project_id: projectId,
        reference_numbers: referenceNumbers,
        is_citation: isCitation,
        is_portfolio_creation: isPortfolioCreation,
      });

      const referenceIds = response.data.map((doc: any) => doc.reference_id);
      await addToDocumentsAdded(
        isCurrentParentPortfolio ? portfolioId : projectId,
        referenceIds,
        isCurrentParentPortfolio,
      );

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(
        error,
        `Failed to add references ${referenceNumbers} to the project`,
      );
    }
  };

  const deleteProject = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_project", {
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting project");
    }
  };

  /**
   * @description Updates documents to added documents for billing
   * @param {string} id - The id of the document to update the details for
   * @param {array} referenceIds - The ids of the references to update the details for
   * @param {boolean} is_portfolio - Whether the documents are in a portfolio
   */
  const addToDocumentsAdded = async (
    id: string,
    referenceIds: string[],
    is_portfolio: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_to_documents_added", {
        id: id,
        reference_ids: referenceIds.filter((id) => id && id.trim() !== ""),
        is_portfolio: is_portfolio,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding to documents added");
    }
  };

  /**
   * @description Updates the project details
   * @param {string} project_id - The id of the project to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateProjectDetails = async (
    project_id: string,
    options: any,
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      project_id: project_id,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_project_details", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating project details");
    }
  };

  return {
    addUserToProject,
    removeUserFromProject,
    getUsersForProject,
    getUserProjectAccessCheck,
    getProjectOwnerData,
    requestProjectAccess,
    sendProjectAccessGrantedEmail,
    attachReferenceIdsToProject,
    getProjectMetadata,
    addPDFToProject,
    addReferencesToProject,
    deleteProject,
    deleteReferencesFromProject,
    addToDocumentsAdded,
    fetchProjectData,
    updateProjectDetails,
  };
};

export default useProject;

import { create } from "zustand";
import { Patent, Project, ElementType } from "@/types";

interface ProjectStore {
  projectsList: any[];
  currentProjectId: string;
  currentProject: Project | null;
  currentPortfolioId: string;
  currentPortfolio: Project | null;
  currentParent: string;
  currentSubject: Patent | null;

  summaryChartData: any[];
  summaryChartHeaders: string[];
  summaryChartRowNames: string[];
  summaryChartRowHeightCollapsed: number;
  chartData: any[];
  selectedReferences: any[];
  selectedElements: any[];
  selectedColors: string[];
  selectedElementType: ElementType;
  elementEditIndex: number;
  archivedProjectsList: any[];

  updateProjectsList: (projectsList: any[]) => void;
  addProjectToProjectsList: (project: any) => void;
  updateCurrentProjectId: (currentProject: string) => void;
  updateCurrentProject: (details: Project) => void;
  updateCurrentPortfolioId: (currentPortfolioId: string) => void;
  updateCurrentPortfolio: (details: Project) => void;
  updateSummaryChartData: (data: any[]) => void;
  updateChartData: (data: any[]) => void;
  clearChartData: () => void;
  updateCurrentSubject: (details: Patent) => void;
  updateSelectedReferences: (details: any[]) => void;
  addSelectedReferences: (refDetails: any) => void;
  removeSelectedReferences: (refIds: string[]) => void;
  updateSelectedElements: (selectedElements: any[]) => void;
  updateSummaryChartHeaders: (headers: string[]) => void;
  updateSummaryChartRowNames: (rowNames: string[]) => void;
  updateSummaryChartRowHeightCollapsed: (rowHeightCollapsed: number) => void;
  updateArchivedProjectsList: (projectsList: any[]) => void;
  updateCurrentParent: (parent: string) => void;
  updateSelectedColors: (colors: string[]) => void;
  updateSelectedElementType: (elementType: ElementType) => void;
  updateElementEditIndex: (index: number) => void;
}

const initialState: Omit<
  ProjectStore,
  | "updateProjectsList"
  | "addProjectToProjectsList"
  | "updateCurrentProjectId"
  | "updateCurrentProject"
  | "updateCurrentParent"
  | "updateSummaryChartData"
  | "updateChartData"
  | "updateSelectedReferences"
  | "addSelectedReferences"
  | "removeSelectedReferences"
  | "updateSelectedDocumentIds"
  | "updateSelectedElements"
  | "clearChartData"
  | "updateSummaryChartHeaders"
  | "updateSummaryChartRowNames"
  | "updateSummaryChartRowHeightCollapsed"
  | "updateArchivedProjectsList"
  | "updateCurrentPortfolioId"
  | "updateCurrentPortfolio"
  | "updateCurrentParent"
  | "updateSelectedColors"
  | "updateSelectedElementType"
  | "updateElementEditIndex"
  | "updateCurrentSubject"
> = {
  projectsList: [],
  archivedProjectsList: [],
  currentProjectId: "",
  currentProject: null,
  currentPortfolioId: "",
  currentPortfolio: null,
  currentParent: "",
  summaryChartData: [],
  summaryChartHeaders: [],
  summaryChartRowNames: [],
  summaryChartRowHeightCollapsed: 0,
  chartData: [],
  currentSubject: null,
  selectedReferences: [],
  selectedElements: [],
  selectedColors: [],
  selectedElementType: ElementType.CLAIM,
  elementEditIndex: -1,
};

const useProjectStore = create<ProjectStore>((set) => ({
  ...initialState,

  updateCurrentParent: (parent) => set(() => ({ currentParent: parent })),

  updateProjectsList: (projectsList) => set(() => ({ projectsList })),

  updateArchivedProjectsList: (projectsList) =>
    set(() => ({ archivedProjectsList: projectsList })),

  addProjectToProjectsList: (project) =>
    set((state) => ({
      projectsList: [...state.projectsList, project],
    })),

  updateCurrentProjectId: (currentProjectId) => {
    localStorage.setItem("currentProject", currentProjectId);
    set({ currentProjectId });
  },

  updateCurrentProject: (details) => set(() => ({ currentProject: details })),

  updateCurrentPortfolioId: (currentPortfolioId) => set(() => ({ currentPortfolioId })),

  updateCurrentPortfolio: (details) => set(() => ({ currentPortfolio: details })),

  updateSummaryChartData: (data) => set(() => ({ summaryChartData: data })),

  updateChartData: (data) => set(() => ({ chartData: data })),

  clearChartData: () =>
    set(() => ({
      chartData: [],
      summaryChartData: [],
    })),

  updateCurrentSubject: (details) => set(() => ({ currentSubject: details })),

  updateSelectedReferences: (details) => set(() => ({ selectedReferences: details })),

  addSelectedReferences: (refDetails) =>
    set((state) => ({
      selectedReferences: [...state.selectedReferences, refDetails],
    })),

  removeSelectedReferences: (refIds) =>
    set((state) => ({
      selectedReferences: state.selectedReferences.filter((details) =>
        refIds.includes(details.id),
      ),
    })),

  updateSelectedElements: (selectedElements) => set(() => ({ selectedElements })),

  updateSummaryChartHeaders: (headers) => set(() => ({ summaryChartHeaders: headers })),

  updateSummaryChartRowNames: (rowNames) =>
    set(() => ({ summaryChartRowNames: rowNames })),

  updateSummaryChartRowHeightCollapsed: (rowHeightCollapsed) =>
    set(() => ({ summaryChartRowHeightCollapsed: rowHeightCollapsed })),

  updateSelectedColors: (colors) => set(() => ({ selectedColors: colors })),

  updateSelectedElementType: (elementType) =>
    set(() => ({ selectedElementType: elementType })),

  updateElementEditIndex: (index) => set(() => ({ elementEditIndex: index })),
}));

export default useProjectStore;

import camelcaseKeys from "camelcase-keys";
/**
 * Utility function to convert snake_case keys to camelCase
 * @param {object} obj - The object to convert
 * @returns {object} - The object with camelCase keys
 */
export const toCamelCase = (obj: any, deep: boolean = true): any => {
  return camelcaseKeys(obj, { deep });
};
/**
 * @param {string} string - The string to capitalize
 * @returns {string} - The capitalized string
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @param {string} string - The comma-separated string to parse
 * @returns {array} - An array of strings
 * Separates by commas or periods and trims whitespace
 */
export const parseCommaSeparatedString = (string: string) => {
  return string.split(/[,.]/).map((s) => s.trim());
};

/**
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is likely a patent number
 */
export const isLikelyPatentNumber = (string: string) => {
  const length = string.length;
  const hasCountryCode = /^[A-Z]{2,3}/.test(string);
  const isValidLength =
    (length >= 7 && /^[A-Z]{2,3}[0-9]+$/.test(string)) ||
    (length >= 9 && length <= 17 && /^[A-Z]{2,3}[A-Z0-9]+$/.test(string));
  return hasCountryCode && isValidLength;
};

export const formatCurrency = (
  value: string | number | null,
  inCents: boolean = false,
): string => {
  if (value === null) return;
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numValue)) return "N/A";

  const dollars = inCents ? numValue / 100 : numValue;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

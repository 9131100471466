/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm } from "@/hooks";
import ColorSelect from "./ColorSelect";
import MultiDocumentSelect from "@/components/files/MultiDocumentSelect";
import { ChartColor, ElementType } from "@/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";

interface PruneInvalidityColorModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  fetchElementChartPageData: () => void;
}

/**
 * @description Prune invalidity charts by color
 * @param {boolean} open - Whether the modal is open.
 * @param {function} onOpenChange - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const PruneInvalidityColorModal: React.FC<PruneInvalidityColorModalProps> = ({
  open,
  onOpenChange,
  fetchElementChartPageData,
}) => {
  const { pruneInvalidityColor } = useLlm();

  const { currentProjectId, selectedElementType } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [selectedColors, setSelectedColors] = useState<string[]>([
    ChartColor.YELLOW,
    ChartColor.RED,
  ]);
  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);
  const [isPruneLoading, setIsPruneLoading] = useState<boolean>(false);

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setSelectedColors([ChartColor.YELLOW, ChartColor.RED]);
    setSelectedReferences([]);
  };

  const handleConfirmPrune = async () => {
    setIsPruneLoading(true);

    const pruneResponse = await pruneInvalidityColor(
      currentProjectId,
      selectedReferences,
      selectedColors,
      selectedElementType == ElementType.FEATURE,
    );
    if (!pruneResponse.success) {
      addErrorMessage("Failed to prune citations by color.");
      return;
    }

    // await getProjectMetadata(currentProjectId);
    fetchElementChartPageData();
    setIsPruneLoading(false);
    onClose();
  };

  const onClose = () => {
    resetModal();
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Prune citations by color</DialogTitle>
          <DialogDescription>
            Remove all citations of the selected color from the selected reference(s).
            This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="flex space-x-2">
          <ColorSelect
            colors={[ChartColor.YELLOW, ChartColor.RED]}
            selectedColors={selectedColors}
            setSelectedColors={setSelectedColors}
            disabled={isPruneLoading}
          />
          <MultiDocumentSelect
            handleChange={setSelectedReferences}
            general={true}
            disabled={isPruneLoading}
          />
        </div>

        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
              disabled={isPruneLoading}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="destructive"
              disabled={!selectedColors.length || isPruneLoading}
              onClick={handleConfirmPrune}
              loading={isPruneLoading}
            >
              {isPruneLoading ? "Pruning..." : "Prune"}
            </LoadingButton>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneInvalidityColorModal;

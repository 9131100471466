/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useCallback, useEffect } from "react";
import { useProject } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { SidebarPage, UploadFileModal } from "@/components";
import {
  AddReferenceByNumberModal,
  DocumentsTable,
} from "@/features/project/references/components";
import ExploreHeader from "./components/ExploreHeader";
import { Patent } from "@/types";
import { Button } from "@/components/ui/button";
import { H4 } from "@/components/ui/typography";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

/**
 * @description Explore documents page with search and chat tabs
 */
const ExplorePage: React.FC = () => {
  const { fetchProjectData, deleteReferencesFromProject } = useProject();

  // Global state from store
  const { currentProject, currentProjectId, updateCurrentProject } = useProjectStore();
  const { addErrorMessage, searchChatProjectId } = useAppStateStore();
  const references = useProjectStore((state) =>
    state.currentProject.references.filter(
      (ref) =>
        !ref?.s3_key?.includes("AndAISearchChat") &&
        !ref?.name?.includes("AndAISearchChat"),
    ),
  );

  // Local state
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isReferenceLoading, setIsReferenceLoading] = useState<string[]>([]);

  const handleAddPublishedPatents = () => {
    // Logic to add published patents or applications
    setShowAddModal(true);
  };

  const handleAddOtherDocuments = () => {
    setShowUploadModal(true);
  };

  useEffect(() => {
    setIsReferenceLoading([]);
  }, [currentProjectId]);

  //  Removes a reference from the explore project
  const handleRemoveReferenceFromProject = useCallback(
    async (reference: Patent) => {
      const refId = reference.id || reference.referenceId;
      if (refId && searchChatProjectId) {
        const response = await deleteReferencesFromProject(searchChatProjectId, [
          refId,
        ]);
        if (!response.success) {
          addErrorMessage("Error deleting reference");
        }
      }
    },
    [currentProjectId, references, isReferenceLoading],
  );

  // Fetch project data on mount
  useEffect(() => {
    fetchProjectData(searchChatProjectId);
  }, [searchChatProjectId]);

  // Reset the explore state and clear references
  const handleReset = async () => {
    const refIds = currentProject.referenceIds;

    for (const refId of refIds) {
      const response = await deleteReferencesFromProject(searchChatProjectId, [refId]);
      if (!response.success) {
        addErrorMessage("Error deleting references from the project");
        return;
      }
    }
    updateCurrentProject({
      id: searchChatProjectId,
      name: "",

      subjectId: "",
      referenceIds: [],
      references: [],
      documentIds: [],
      keywords: [],
    });
  };

  return (
    <SidebarPage pageName="Explore">
      <ExploreHeader />
      <div>
        {references?.length > 0 || isReferenceLoading?.length > 0 ? (
          <DocumentsTable
            references={references}
            onDeleteRow={handleRemoveReferenceFromProject}
          >
            <Button variant="outline" onClick={handleReset}>
              Clear
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button>Add</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={handleAddPublishedPatents}>
                  Add published patents/applications
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleAddOtherDocuments}>
                  Upload other documents
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </DocumentsTable>
        ) : (
          <div className="flex justify-center h-[calc(100vh-200px)] mt-10">
            <div className="text-center">
              <H4>Add sources to explore.</H4>
              <div className="flex gap-2 justify-center mt-3">
                <Button variant="outline" onClick={() => setShowAddModal(true)}>
                  Add by Number
                </Button>
                <Button variant="outline" onClick={() => setShowUploadModal(true)}>
                  Upload Files
                </Button>
              </div>
            </div>
          </div>
        )}

        <AddReferenceByNumberModal
          open={showAddModal}
          handleClose={() => setShowAddModal(false)}
          general={true}
        />
        <UploadFileModal
          open={showUploadModal}
          handleClose={() => setShowUploadModal(false)}
        />
      </div>
    </SidebarPage>
  );
};

export default ExplorePage;

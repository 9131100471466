/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useRef } from "react";
import { CondensedParagraph } from "@/components/ui/typography";
import { Label } from "@/components/ui/label";

interface PatentBodyProps {
  body: string[];
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
}

const PatentBody: React.FC<PatentBodyProps> = ({
  body,
  citationText,
  highlightedRef,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (citationText && bodyRef.current) {
      const paragraphs = bodyRef.current.querySelectorAll("div");
      for (let i = 0; i < paragraphs.length; i++) {
        if (paragraphs[i].textContent?.slice(0, 75) === citationText?.slice(0, 75)) {
          paragraphs[i].scrollIntoView({ block: "center" });

          if (bodyRef.current.parentElement) {
            bodyRef.current.parentElement.scrollTop =
              bodyRef.current.parentElement.scrollTop - 100;
          }
          break;
        }
      }
    }
  }, [citationText]);

  if (!body || body.length === 0) return null;

  const renderBody = () => {
    return body
      .map((section: string, index: number) => {
        const isHighlighted = citationText
          ? section?.slice(0, 75) === citationText?.slice(0, 75)
          : false;
        return (
          <div key={index} ref={isHighlighted ? highlightedRef : null}>
            <CondensedParagraph
              className={`w-full ${isHighlighted ? "bg-blue-100" : ""}`}
            >
              <div dangerouslySetInnerHTML={{ __html: section }} />
            </CondensedParagraph>
          </div>
        );
      })
      .reduce((acc, curr, index) => {
        return acc.concat(
          curr,
          <div key={`spacer-${index}`} className="h-4" />, // Add a spacer div
        );
      }, [] as React.ReactNode[]);
  };

  return (
    <div className="mb-4" ref={bodyRef}>
      <Label className="mb-2">Body</Label>
      {renderBody()}
    </div>
  );
};

export default PatentBody;

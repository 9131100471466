/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import SubjectAbstractImages from "./SubjectAbstractImages";
import { Patent, ElementType } from "@/types";
import PatentBody from "./PatentBody";
import { ElementList, DocumentMetadata } from "@/components";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";

interface ViewerPatentLayoutProps {
  document: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>;
}

const ViewerPatentLayout: React.FC<ViewerPatentLayoutProps> = ({
  document,
  citationText,
  highlightedRef,
}) => {
  const [leftPanelSize, setLeftPanelSize] = useState(50);
  const [showPdfViewer, setShowPdfViewer] = useState(true);

  useEffect(() => {
    const checkPdfAccess = async () => {
      if (document?.url) {
        try {
          const response = await fetch(document.url);
          if (!response.ok) {
            setShowPdfViewer(false);
            setLeftPanelSize(100);
          }
        } catch (error) {
          console.error("Error checking PDF access:", error);
          setShowPdfViewer(false);
          setLeftPanelSize(100);
        }
      }
    };

    checkPdfAccess();
  }, [document?.url]);

  if (!document) return null;

  const isPatent = document.isPatent;

  // Check if any claim is highlighted (existing code)
  const isClaimHighlighted = document.claims?.some((claim) => {
    const claimText = Object.values(claim)[0];
    return (
      typeof claimText === "string" &&
      claimText.slice(0, 75) === citationText?.slice(0, 75)
    );
  });

  return (
    <div className="flex h-full">
      {isPatent ? (
        <>
          <div
            className="w-[50%] overflow-y-auto overflow-x-hidden hide-scrollbar p-3"
            style={{ height: "calc(100vh - 120px)" }}
          >
            <SubjectAbstractImages details={document} />
            <PatentBody
              body={document.body?.map((item) => (item as any).html ?? item.text)}
              citationText={citationText}
              highlightedRef={highlightedRef}
            />
          </div>

          <Separator orientation="vertical" className="h-auto" />

          <div
            className="w-[50%] overflow-y-auto overflow-x-hidden hide-scrollbar p-3"
            style={{ height: "calc(100vh - 120px)" }}
          >
            <DocumentMetadata
              details={document}
              showHead={true}
              subjectPage={true}
              enableEditPriorityDate={false}
            />

            {document.claims?.length > 0 && (
              <ElementList
                list={document.claims}
                itemType={ElementType.CLAIM}
                startCollapsed={false}
                editingBool={false}
                citationText={isClaimHighlighted ? citationText : undefined}
                highlightedRef={
                  isClaimHighlighted
                    ? (highlightedRef as React.RefObject<HTMLTableRowElement>)
                    : undefined
                }
              />
            )}
          </div>
        </>
      ) : (
        <>
          <ResizablePanelGroup direction="horizontal">
            {/* Left Pane: Current Information */}
            <ResizablePanel defaultSize={leftPanelSize} onResize={setLeftPanelSize}>
              <div
                className="overflow-y-auto overflow-x-hidden p-3 "
                style={{ height: "calc(100vh - 120px)" }}
              >
                <div className="max-w-full">
                  <SubjectAbstractImages details={document} />
                </div>
                <PatentBody
                  body={document.body?.map((item) => item.text)}
                  citationText={citationText}
                  highlightedRef={highlightedRef}
                />
              </div>
              {/* <Separator orientation="vertical" className="h-auto" /> */}
            </ResizablePanel>

            {showPdfViewer && (
              <>
                <ResizableHandle withHandle={true} />

                {/* Right Pane: PDF Viewer (iframe) */}
                <ResizablePanel defaultSize={100 - leftPanelSize}>
                  <div style={{ height: "calc(100vh - 120px)" }}>
                    {document.url ? (
                      <iframe
                        src={document.url}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                      />
                    ) : (
                      <p className="p-4">No PDF available.</p>
                    )}
                  </div>
                </ResizablePanel>
              </>
            )}
          </ResizablePanelGroup>
        </>
      )}
    </div>
  );
};

export default ViewerPatentLayout;

/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore, useAppStateStore } from "@/store";
import { useApi, useVector } from "@/hooks";
import { ApiResponse, SummaryChartData } from "@/types";
import { getColumnHeaders } from "@/utils/projectUtils";

const useElementOperations = () => {
  const { postRequest, handleError } = useApi();
  const { retrieveInvalidityForClaimForAllReferences } = useVector();
  const {
    currentProject,
    chartData,
    updateChartData,
    summaryChartData,
    updateSummaryChartData,
    updateSummaryChartHeaders,
    updateCurrentProject,
    updateSummaryChartRowNames,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  // Helper function to handle invalidity response
  const handleInvalidityResponse = async (
    projectId: string,
    claimNumber: string,
    claimText: string,
    is_feature: boolean,
  ) => {
    const invalidityResponse = await retrieveInvalidityForClaimForAllReferences(
      projectId,
      claimNumber,
      claimText,
      is_feature,
    );
    if (!invalidityResponse.success) {
      addErrorMessage(`Error finding invalidity for claim ${claimNumber}`);
    }
    return invalidityResponse;
  };

  /**
   * @description Adds a claim or feature to the project
   * @param {string} projectId - The id of the project to add the claim or feature to
   * @param {string} claimNumber - The claim number of the claim or feature to add
   * @param {string} claimText - The text of the claim or feature to add
   * @param {number} prevClaimIndex - The index of the previous claim or feature to add
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const addClaimOrFeatureToProject = async (
    projectId: string,
    claimNumber: string,
    claimText: string,
    prevClaimIndex: number,
    is_feature: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        claim_text: claimText,
        prev_claim_index: prevClaimIndex.toString(),
        is_feature: is_feature,
      });

      const invalidityResponse = await handleInvalidityResponse(
        projectId,
        claimNumber,
        claimText,
        is_feature,
      );

      // Update the project details
      if (currentProject) {
        if (!is_feature && currentProject.claims) {
          const newClaim = {
            [claimNumber]: claimText,
          };
          const insertIndex = prevClaimIndex + 1;
          updateCurrentProject({
            ...currentProject,
            claims: [
              ...currentProject.claims.slice(0, insertIndex),
              newClaim,
              ...currentProject.claims.slice(insertIndex),
            ],
          });
        } else if (is_feature && currentProject.features) {
          const newFeature = {
            [claimNumber]: claimText,
          };
          const insertIndex = prevClaimIndex + 1;
          updateCurrentProject({
            ...currentProject,
            features: [
              ...currentProject.features.slice(0, insertIndex),
              newFeature,
              ...currentProject.features.slice(insertIndex),
            ],
          });
        }
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error adding claim or feature to project");
    }
  };

  /**
   * @description Splits a claim or feature into multiple claims or features
   * @param {string} projectId - The id of the project to split the claim or feature in
   * @param {string} claimNumber - The claim number of the claim or feature to split
   * @param {array} newNumbers - The numbers of the new claims or features to create
   * @param {array} newTexts - The texts of the new claims or features to create
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const splitClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    newNumbers: string[],
    newTexts: string[],
    is_feature: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_split_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        new_claim_numbers: newNumbers,
        new_claim_texts: newTexts,
        is_feature: is_feature,
      });

      const invalidityResponse = await handleInvalidityResponse(
        projectId,
        newNumbers[0],
        newTexts[0],
        is_feature,
      );

      const invalidityResponseSplit = await handleInvalidityResponse(
        projectId,
        newNumbers[1],
        newTexts[1],
        is_feature,
      );

      // Update the project details
      if (currentProject) {
        if (!is_feature && currentProject.claims) {
          const claimIndex = currentProject.claims.findIndex(
            (claim) => Object.keys(claim)[0] === claimNumber,
          );
          if (claimIndex !== -1) {
            const newClaims = [
              ...currentProject.claims.slice(0, claimIndex),
              ...newNumbers.map((number, index) => ({
                [number]: newTexts[index],
              })),
              ...currentProject.claims.slice(claimIndex + 1),
            ];
            updateCurrentProject({
              ...currentProject,
              claims: newClaims,
            });
          }
        } else if (is_feature && currentProject.features) {
          const featureIndex = currentProject.features.findIndex(
            (feature) => Object.keys(feature)[0] === claimNumber,
          );
          if (featureIndex !== -1) {
            const newFeatures = [
              ...currentProject.features.slice(0, featureIndex),
              ...newNumbers.map((number, index) => ({
                [number]: newTexts[index],
              })),
              ...currentProject.features.slice(featureIndex + 1),
            ];
            updateCurrentProject({
              ...currentProject,
              features: newFeatures,
            });
          }
        }
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error splitting claim or feature");
    }
  };

  /**
   * @description Edits a claim or feature in the project
   * @param {string} projectId - The id of the project to edit the claim or feature in
   * @param {string} claimNumber - The claim number of the claim or feature to edit
   * @param {string} newNumber - The new claim number of the claim or feature to edit
   * @param {string} newText - The new text of the claim or feature to edit
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const editClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    newNumber: string,
    newText: string,
    is_feature: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_edit_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        new_claim_number: newNumber,
        new_claim_text: newText,
        is_feature: is_feature,
      });

      const invalidityResponse = await handleInvalidityResponse(
        projectId,
        newNumber,
        newText,
        is_feature,
      );

      // Update the project details
      if (currentProject) {
        if (!is_feature && currentProject.claims) {
          const updatedClaims = currentProject.claims.map((claim) => {
            if (Object.keys(claim)[0] === claimNumber) {
              return { [newNumber]: newText };
            }
            return claim;
          });
          updateCurrentProject({
            ...currentProject,
            claims: updatedClaims,
          });
        } else if (is_feature && currentProject.features) {
          const updatedFeatures = currentProject.features.map((feature) => {
            if (Object.keys(feature)[0] === claimNumber) {
              return { [newNumber]: newText };
            }
            return feature;
          });
          updateCurrentProject({
            ...currentProject,
            features: updatedFeatures,
          });
        }
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error editing claim or feature");
    }
  };

  /**
   * @description Deletes a claim or feature from the project
   * @param {string} projectId - The id of the project to delete the claim or feature from
   * @param {string} claimNumber - The claim number of the claim or feature to delete
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const deleteClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    is_feature: boolean,
    claimIndex: number,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        is_feature: is_feature,
      });

      //Filter out the claim or feature from the project details
      if (currentProject) {
        if (!is_feature && currentProject.claims) {
          updateCurrentProject({
            ...currentProject,
            claims: currentProject.claims.filter((_, index) => index !== claimIndex),
          });
        } else if (is_feature && currentProject.features) {
          updateCurrentProject({
            ...currentProject,
            features: currentProject.features.filter(
              (_, index) => index !== claimIndex,
            ),
          });
        }
      }

      // Update the summary chart data and invalidity table data if it's set
      if (chartData && summaryChartData) {
        const newChartData = chartData.filter((_, index) => index !== claimIndex);
        const newSummaryChartData = summaryChartData.filter(
          (_, index) => index !== claimIndex,
        );
        updateChartData(newChartData);
        const newSummaryChartHeaders = getColumnHeaders(newSummaryChartData);
        updateSummaryChartRowNames(
          newSummaryChartData.map((row: SummaryChartData) => row.claimNumber),
        );
        updateSummaryChartHeaders(newSummaryChartHeaders);
        updateSummaryChartData(newSummaryChartData);
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage(
        `Error deleting claim ${claimNumber} from project ${currentProject.name} `,
      );
      return handleError(error, "Error deleting claim or feature");
    }
  };

  return {
    addClaimOrFeatureToProject,
    splitClaimOrFeature,
    editClaimOrFeature,
    deleteClaimOrFeature,
  };
};

export default useElementOperations;

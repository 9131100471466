/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import { useProjectStore } from "@/store";
import { useVector } from "@/hooks";
import { ProjectPage, Loader, ErrorMessage } from "@/components";
import SearchResultsTable from "@/features/explore/search/components/SearchResultsTable";
import SemanticSearchHeader from "@/features/explore/search/components/SearchHeader";

/**
 * @description Renders the project search page
 */
const ProjectSearchPage = () => {
  const { semanticSearchDocuments } = useVector();

  // Page title
  const currentProject = useProjectStore((state) => state.currentProject);
  const pageName = currentProject.name ? `${currentProject.name} - Search` : "Search";

  // Global state from store
  const projectId = useProjectStore((state) => state.currentProjectId);
  const subjectId = useProjectStore((state) => state.currentProject.subjectId);
  const referenceIds = useProjectStore((state) => state.currentProject.referenceIds);
  const projectIdArray = [subjectId, ...referenceIds];

  // Local state setup
  const [showError, setShowError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [searchMode, setSearchMode] = useState("semantic");

  const handleDocumentChange = (selectedIds: string[]) => {
    setSelectedDocumentIds(selectedIds);
  };

  // Handles the semantic search
  const handleSemanticSearch = async () => {
    setSearchResults(null);
    setShowError(false);

    if (selectedDocumentIds.length === 0) {
      setSelectedDocumentIds(projectIdArray as string[]);
    }

    const trimmedSearchQuery = searchQuery.trim();
    setSentSearchQuery(trimmedSearchQuery);

    if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
      setShowSpinner(true);
      const response = await semanticSearchDocuments(
        projectId,
        trimmedSearchQuery,
        selectedDocumentIds,
        searchMode,
      );
      if (response.success) {
        setShowSpinner(false);
        setShowError(false);
        setSelectedDocumentIds([]);
        setSearchResults(response.data.sources[0]);
      } else {
        setShowError(true);
        setShowSpinner(false);
      }
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      <div className="sticky top-0 bg-background z-10">
        <SemanticSearchHeader
          handleSemanticSearch={handleSemanticSearch}
          searchMode={searchMode}
          setSearchMode={setSearchMode}
          handleDocumentChange={handleDocumentChange}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          general={false}
          disabled={false}
        />
        {showSpinner && <Loader message={"Searching the documents..."} />}
        {showError && <ErrorMessage />}
      </div>
      {searchResults && (
        <SearchResultsTable
          searchResults={searchResults}
          sentSearchQuery={sentSearchQuery}
          general={false}
          searchChatProjectId={projectId}
          height="h-[calc(100vh-125px)]"
        />
      )}
    </ProjectPage>
  );
};
export default ProjectSearchPage;
